import { FC } from 'react';
import classNames from 'classnames';
import { FilterSelectOptionWithAdditionalData } from 'ui/Filters/types';
import { LoadMoreStatus } from 'commonComponents/LoadMoreStatus';
import { useInfiniteScroll } from 'utils/hooks/useInfiniteScroll';
import { Checkbox, CheckboxSize } from 'ui/Checkbox';
import styles from './MultiSelectList.module.scss';

interface Props {
  options: Array<FilterSelectOptionWithAdditionalData>;
  emptyListText: string;
  loading?: boolean;
  fetchMoreConfig?: {
    fetchMore?: () => void;
    hasNextPage?: boolean;
  };
  onSearchInList?: (value: string) => void;
}

export const MultiSelectList: FC<Props> = ({
  options,
  loading,
  emptyListText,
  fetchMoreConfig,
  onSearchInList,
}) => {
  const loadTrigger = useInfiniteScroll(fetchMoreConfig?.fetchMore);

  const isListWithFetchLoading = fetchMoreConfig && loading;
  const isOptionsListEmpty = !options || options.length === 0;
  const isEmptyListTextVisible = !loading && isOptionsListEmpty;

  return (
    <div
      className={classNames(
        styles.list,
        onSearchInList && styles.hiddenListPadding,
      )}
    >
      {options.map(({ id, label, additionalData }) => (
        <div key={id} className={styles.option}>
          <Checkbox
            checkboxSize={CheckboxSize.Small}
            className={classNames(
              styles.checkbox,
              additionalData && styles.withAdditionalData,
            )}
            name={id}
            label={label}
          />
          {additionalData && (
            <div className={styles.additionalData}>{additionalData}</div>
          )}
        </div>
      ))}

      {loadTrigger}
      {fetchMoreConfig && isListWithFetchLoading && (
        <LoadMoreStatus
          className={styles.dropdownLoader}
          fetchMore={fetchMoreConfig.fetchMore}
          isLoading={loading}
        />
      )}
      {isEmptyListTextVisible && (
        <div className={styles.searchWarningText}>{emptyListText}</div>
      )}
    </div>
  );
};
