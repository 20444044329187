import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { PlayerInfoData } from './PlayerInfoData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPlayerForPreviewFilterVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPlayerForPreviewFilter = { __typename: 'Query', player: { __typename: 'Player', email?: string | null, id: string, name: string, phone?: string | null } };


export const GetPlayerForPreviewFilterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPlayerForPreviewFilter"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PlayerInfoData"}}]}}]}},...PlayerInfoData.definitions]} as unknown as DocumentNode;

/**
 * __useGetPlayerForPreviewFilter__
 *
 * To run a query within a React component, call `useGetPlayerForPreviewFilter` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerForPreviewFilter` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerForPreviewFilter({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlayerForPreviewFilter(baseOptions: ApolloReactHooks.QueryHookOptions<GetPlayerForPreviewFilter, GetPlayerForPreviewFilterVariables> & ({ variables: GetPlayerForPreviewFilterVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPlayerForPreviewFilter, GetPlayerForPreviewFilterVariables>(GetPlayerForPreviewFilterDocument, options);
      }
export function useGetPlayerForPreviewFilterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlayerForPreviewFilter, GetPlayerForPreviewFilterVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPlayerForPreviewFilter, GetPlayerForPreviewFilterVariables>(GetPlayerForPreviewFilterDocument, options);
        }
export function useGetPlayerForPreviewFilterSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetPlayerForPreviewFilter, GetPlayerForPreviewFilterVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetPlayerForPreviewFilter, GetPlayerForPreviewFilterVariables>(GetPlayerForPreviewFilterDocument, options);
        }
export type GetPlayerForPreviewFilterHookResult = ReturnType<typeof useGetPlayerForPreviewFilter>;
export type GetPlayerForPreviewFilterLazyQueryHookResult = ReturnType<typeof useGetPlayerForPreviewFilterLazyQuery>;
export type GetPlayerForPreviewFilterSuspenseQueryHookResult = ReturnType<typeof useGetPlayerForPreviewFilterSuspenseQuery>;
export type GetPlayerForPreviewFilterQueryResult = Apollo.QueryResult<GetPlayerForPreviewFilter, GetPlayerForPreviewFilterVariables>;