import React from 'react';
import { PatternFormat } from 'react-number-format';
import styles from './InputTime.module.scss';

interface Props {
  value: string;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onChange: (value: string) => void;
}

export const InputTime: React.FC<Props> = ({
  value,
  onChange,
  disabled,
  placeholder,
  autoFocus,
}) => {
  const handleChange = ({ value: inputValue }: { value: string }) => {
    if (inputValue.length === 4) {
      let hh = parseInt(inputValue.slice(0, 2), 10);
      let mm = parseInt(inputValue.slice(2, 4), 10);

      hh = hh > 23 ? 23 : hh;

      mm = mm > 59 ? 59 : mm;

      onChange(
        `${hh.toString().padStart(2, '0')}:${mm.toString().padStart(2, '0')}`,
      );
    } else {
      onChange(inputValue);
    }
  };

  return (
    <PatternFormat
      className={styles.inputTime}
      format="##:##"
      mask="_"
      placeholder={placeholder}
      autoFocus={autoFocus}
      value={value}
      disabled={disabled}
      onValueChange={handleChange}
    />
  );
};
