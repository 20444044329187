import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { DateFormat, formatDate } from 'utils/formatDate';
import Error from 'ui/Error';
import { SidebarLoader } from 'ui/SidebarLoader';
import { useGetSportsBetEventHistory } from '../../../../queries/generated/GetSportsBetEventHistory';

interface Props {
  id: string;
}

export const EventHistory: FC<Props> = ({ id }) => {
  const { data, loading, error } = useGetSportsBetEventHistory({
    variables: {
      id,
    },
  });

  if (error) {
    return <Error error={error} />;
  }

  const eventHistory = data?.sportsBet.eventHistory;

  return (
    <SidebarLoader loading={loading}>
      {eventHistory?.map(
        ({
          name,
          coefficient,
          outcome,
          championship,
          date,
          externalId,
          sport,
          category,
        }) => (
          <DataCard
            key={externalId}
            header={{
              leftTitle: name,
              leftSubTitle: `${externalId} ${category}`,
              rightTitle: sport,
            }}
            rows={[
              { title: 'Чемпионат', value: championship },
              {
                title: 'Исход',
                value: outcome,
              },
              {
                title: 'Коэффициент',
                value: coefficient,
              },
              {
                title: 'Дата проведения',
                value: formatDate(date, DateFormat.FullDateWithSeconds),
              },
            ]}
            withBorder
          />
        ),
      )}
    </SidebarLoader>
  );
};
