import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { useGetSportsBetsColumns } from './hooks/useGetSportsBetsColumns';
import { SportsBetData } from '../../queries/generated/SportsBetData';
import { useOpenSportsBetModal } from '../../hooks/useOpenSportsBetModal';

interface Props {
  sportsBets?: Array<SportsBetData>;
}

export const SportsBetsTable: FC<Props> = ({ sportsBets }) => {
  const { columns } = useGetSportsBetsColumns();

  const { openModal } = useOpenSportsBetModal();

  return (
    <BlockTable
      onClickOnRow={openModal}
      columns={columns}
      data={sportsBets}
      isFullScreenTable
    />
  );
};
