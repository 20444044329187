import { Form, Formik } from 'formik';
import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import Input from 'ui/Input';
import Error from 'ui/Error';
import FormRow from 'ui/FormRow';
import { MediaCampaignDropdown } from 'commonComponents/MediaCampaignDropdown';
import { RewardTariffType, MediaItemRewardTariffTypeName } from 'commonTypes';
import { RevShareTariffDropdown } from 'commonComponents/RevShareTariffDropdown';
import { CpaTariffDropdown } from 'commonComponents/CpaTariffDropdown';
import { getInitialValues } from './helpers';
import { AffiliateReferralLinkItemDetailsData } from '../../queries/generated/AffiliateReferralLinkItemDetailsData';
import { useUpdateReferralLinkMediaItem } from './hooks/useUpdateReferralLinkMediaItem';
import { validate } from './validation';
import { FieldName, mapCreatePromoCodeFieldToTitle } from './const';

interface Props {
  referralLinkId: string;
  referralLinkMediaItem: AffiliateReferralLinkItemDetailsData;
}

export const EditMediaItemReferralLinkModal: FC<Props> = ({
  referralLinkId,
  referralLinkMediaItem,
}) => {
  const initialValues = getInitialValues({
    referralLinkId,
    referralLinkMediaItem,
  });

  const isCpaTariff =
    referralLinkMediaItem.mediaItemRewardTariff.__typename ===
    MediaItemRewardTariffTypeName.Cpa;

  const { onUpdate, loading, error } = useUpdateReferralLinkMediaItem({
    referralLinkId,
    isCpaTariff,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onUpdate}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isValid, dirty, values }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <FormRow isWide>
              <Input
                placeholder="Введите название"
                name={FieldName.Name}
                label={mapCreatePromoCodeFieldToTitle.name}
                maxLength={50}
              />
            </FormRow>
            <FormRow isWide>
              <MediaCampaignDropdown
                name={FieldName.MediaCampaignId}
                label={mapCreatePromoCodeFieldToTitle.mediaCampaignId}
              />
            </FormRow>
            {values.type === RewardTariffType.RevShare && (
              <FormRow isWide>
                <RevShareTariffDropdown
                  name={FieldName.RevShareTariffId}
                  label={mapCreatePromoCodeFieldToTitle.cpaTariffId}
                />
              </FormRow>
            )}
            {values.type === RewardTariffType.Cpa && (
              <>
                <FormRow isWide>
                  <CpaTariffDropdown
                    name={FieldName.CpaTariffId}
                    label={mapCreatePromoCodeFieldToTitle.cpaTariffId}
                  />
                </FormRow>
                <FormRow isWide>
                  <RevShareTariffDropdown
                    name={FieldName.RevShareTariffId}
                    label={mapCreatePromoCodeFieldToTitle.revShareTariffId}
                  />
                </FormRow>
              </>
            )}
          </FormRowsWrapper>
          <Button
            size={ButtonSize.Large}
            theme={ButtonTheme.Process}
            type="submit"
            isLoading={loading}
            disabled={!isValid || !dirty || loading}
          >
            Сохранить
          </Button>
        </Form>
      )}
    </Formik>
  );
};
