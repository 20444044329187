import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FilterState } from '../types';
import { FilterSelectOption } from '../../../types';

export const useMultiSelectInitialValues = (
  options: Array<FilterSelectOption>,
  filterId: string,
  activeFilterIds?: Array<string>,
) => {
  const [searchParams] = useSearchParams();
  const params = searchParams.getAll(filterId);

  const initialValues = useMemo(() => {
    const optionIds = options.map(({ id }) => id);
    const unionIds = Array.from(
      new Set([...optionIds, ...(activeFilterIds || [])]),
    );

    const values: FilterState = {};

    unionIds.forEach((id) => {
      values[id] = activeFilterIds?.length
        ? activeFilterIds.includes(id)
        : params.includes(id);
    });

    return values;
  }, [options, activeFilterIds, params]);

  return { initialValues };
};
