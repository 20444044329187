import { currencySignMap } from 'src/const';
import { FilterSelectOption } from 'ui/Filters/types';

export const getFilterCurrencyOptions = (): Array<FilterSelectOption> =>
  Array.from(
    Object.entries(currencySignMap).filter(
      ([key]) => key !== currencySignMap.XXX,
    ),
    ([key, value]) => ({ id: key, label: value }),
  );
