import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { useGetLoginHistoryColumns } from './hooks/useGetLoginHistoryColumns';
import { LoginHistoryData } from '../../queries/generated/LoginHistoryData';

interface Props {
  loginHistory?: Array<LoginHistoryData>;
}

export const LoginHistoryTable: FC<Props> = ({ loginHistory }) => {
  const { columns } = useGetLoginHistoryColumns();

  return <BlockTable columns={columns} data={loginHistory} isFullScreenTable />;
};
