import { RewardTariffType } from 'commonTypes';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import Block from 'ui/Block';
import { RewardTariffsTabs } from 'commonComponents/RewardTariffsTabs';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useNavigate } from 'react-router';
import { Route } from 'src/router/routes.const';
import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { PageTypeStorage } from 'utils/storage';
import SearchInput from 'ui/SearchInput';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { UserPrivilege } from 'generatedUserPrivilege';
import { RewardsHeaderActions } from '../RewardsHeaderActions';
import { useGetRewards } from '../../hooks/useGetRewards';
import { RewardsTable } from '../RewardsTable';
import {
  REWARD_BLOCK_TITLE,
  CPA_REWARD_INPUT_NAME,
  REWARD_EMPTY_TEXT,
  REWARD_SEARCH_PLACEHOLDER,
} from '../../const';

const Component: FC = withBlockProvider(() => {
  const navigate = useNavigate();

  const {
    rewardsList,
    loadRewards,
    loading,
    error,
    data,
    refetch,
    fetchMoreCallback,
    handleSearch,
    hasNextPage,
  } = useGetRewards(RewardTariffType.Cpa);

  useBlockComponentState({
    loadData: loadRewards,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return (
    <>
      <SearchInput
        name={CPA_REWARD_INPUT_NAME}
        placeholder={REWARD_SEARCH_PLACEHOLDER}
        onSubmit={handleSearch}
      />
      <Block
        title={REWARD_BLOCK_TITLE}
        id="cpaRewards"
        headerContent={
          <RewardsHeaderActions
            rewardTariffType={RewardTariffType.Cpa}
            refetch={refetch}
          />
        }
        subHeader={
          <RewardTariffsTabs
            onClick={() => navigate(Route.REWARDS_REV_SHARE)}
            currentTabId={RewardTariffType.Cpa}
            viewRevSharePrivilege={UserPrivilege.ViewReward}
            viewCpaPrivilege={UserPrivilege.ViewReward}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText={REWARD_EMPTY_TEXT}
        isEmpty={isListEmpty(rewardsList)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(rewardsList) && <RewardsTable rewards={rewardsList} />}
      </Block>
    </>
  );
});

export const CpaRewards = () => (
  <SearchContextProvider pageType={PageTypeStorage.CpaReward}>
    <Component />
  </SearchContextProvider>
);
