import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useSearchContext } from 'src/contexts/SearchContext';
import { useGetMediaItemPromoCodeActivationsLazyQuery } from '../queries/generated/GetMediaItemPromoCodeActivations';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetPromoCodeActivations = () => {
  const { searchValue } = useSearchContext();

  const [variables, setVariables] = useState({
    first: getCountByWindowHeight(cellHeight, 0),
  });

  useEffect(() => {
    setVariables((oldVariables) => ({
      ...oldVariables,
      search: searchValue,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    loadPromoCodeActivations,
    { data, loading, error, fetchMore, refetch },
  ] = useGetMediaItemPromoCodeActivationsLazyQuery();

  const loadPromoCodeActivationsCallback = useCallback(
    () =>
      loadPromoCodeActivations({
        variables,
      }),
    [variables, loadPromoCodeActivations],
  );

  const promoCodeActivations = useMemo(
    () => data?.promoCodeMediaItemActivations?.edges.map(({ node }) => node),
    [data?.promoCodeMediaItemActivations?.edges],
  );

  const endCursor = data?.promoCodeMediaItemActivations?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  return {
    promoCodeActivations,
    handleSearch: () =>
      loadPromoCodeActivations({
        variables: { ...variables, search: searchValue },
      }),
    loadPromoCodeActivations: loadPromoCodeActivationsCallback,
    loading,
    error,
    data,
    fetchMoreCallback,
    refetch,
    hasNextPage: data?.promoCodeMediaItemActivations?.pageInfo.hasNextPage,
  };
};
