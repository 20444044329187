import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import errorToast from 'ui/Toast/ErrorToast';
import { useContext } from 'react';
import { ApolloError } from '@apollo/client';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { GetPartnerPromoCodeMediaItemsBlockDocument } from 'queries/generated/GetPartnerPromoCodeMediaItemsBlock';
import { useCreatePartnerPromoCodeMediaItem } from 'queries/generated/CreatePartnerPromoCodeMediaItem';
import { FieldName } from '../const';
import { CreatePromoCodeValues } from '../types';

const cellHeight = Heights.defaultCellHeight;

interface Result {
  onCreatePromoCodeSubmitHandler: (values: CreatePromoCodeValues) => void;
}

interface Props {
  partnerId: string;
}

export const useCreatePromoCode = ({ partnerId }: Props): Result => {
  const { dispatch } = useContext(ModalContext);

  const onCompletedHandler = () => {
    dispatch({
      type: ModalActions.Close,
    });

    toast.success('Вы создали новый промокод');
  };

  const onErrorHandler = (error: ApolloError) => {
    dispatch({
      type: ModalActions.Close,
    });

    errorToast({
      header: 'Промокод не создан',
      text: error.message,
      toastId: 'create-promo-code-media-item-error',
    });
  };

  const [createPromoCodeMediaItem] = useCreatePartnerPromoCodeMediaItem({
    onCompleted: onCompletedHandler,
    onError: onErrorHandler,
    refetchQueries: [
      {
        query: GetPartnerPromoCodeMediaItemsBlockDocument,
        variables: {
          first: getCountByWindowHeight(cellHeight, 0),
          partnerId,
        },
      },
    ],
  });

  const onCreatePromoCodeSubmitHandler = (values: CreatePromoCodeValues) => {
    if (!values[FieldName.Usage]) {
      return;
    }

    createPromoCodeMediaItem({
      variables: {
        input: {
          partnerId,
          promoCodeMediaItem: {
            token: values[FieldName.Token],
            usageType: values[FieldName.Usage],
            activationLimit: values[FieldName.ActivationLimit] || null,
            referralLinkMediaItem: { id: values[FieldName.ReferralLinkId] },
            ...(values[FieldName.ConnectionWithFreespins]
              ? {
                  freespin: {
                    count: values[FieldName.FreespinCount],
                    currency: values[FieldName.FreespinCurrency],
                    gameId: values[FieldName.FreespinGameId],
                    ...(values[FieldName.FreespinWager]
                      ? {
                          bonusWager: values[FieldName.FreespinWager],
                        }
                      : null),
                    ...(values[FieldName.FreespinMinBalance]
                      ? {
                          minAccountBalanceAmount: {
                            amount: values[FieldName.FreespinMinBalance],
                            currency: values[FieldName.FreespinCurrency],
                          },
                        }
                      : null),
                  },
                }
              : null),
            ...(values[FieldName.ConnectionWithBonus]
              ? {
                  bonus: {
                    amount: {
                      amount: values[FieldName.BonusAmount],
                      currency: values[FieldName.BonusCurrency],
                    },
                    bonusConfig: {
                      cashout: values[FieldName.BonusCashout],
                      currency: values[FieldName.BonusCurrency],
                      minAccountBalanceAmount:
                        values[FieldName.BonusMinBalance],
                      wager: values[FieldName.BonusWager],
                    },
                  },
                }
              : null),
          },
        },
      },
    });
  };

  return { onCreatePromoCodeSubmitHandler };
};
