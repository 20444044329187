import { FC, useContext, useEffect } from 'react';
import { ModalActions, ModalContext, Tabs, TabsWrapper } from 'ui/Modal';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { EditMediaItemReferralLinkModal } from 'commonComponents/EditMediaItemReferralLinkModal';
import { AboutReferralLinkSteps } from '../../const';
import { useGetCompanyReferralLinkMediaItem } from './queries/generated/GetCompanyReferralLinkMediaItem';
import { AboutReferralLinkMediaItem } from '../AboutReferralLinkMediaItem';

interface Props {
  companyId: string;
  referralLinkId: string;
}

export const CompanyReferralLinkMediaItem: FC<Props> = ({
  companyId,
  referralLinkId,
}) => {
  const { state, dispatch } = useContext(ModalContext);

  const { data, loading, error } = useGetCompanyReferralLinkMediaItem({
    variables: {
      companyId,
      referralLinkMediaItemId: referralLinkId,
    },
  });

  useEffect(() => {
    if (state.step === AboutReferralLinkSteps.EditReferralLinkStep) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Редактировать рефссылку',
          subTitle: addHashTagToId(referralLinkId),
        },
      });
    } else if (state.step === AboutReferralLinkSteps.BaseStep) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: addHashTagToId(referralLinkId),
          subTitle: 'Рефссылка',
        },
      });
    }
  }, [dispatch, referralLinkId, state.step]);

  if (state.step === AboutReferralLinkSteps.EditReferralLinkStep) {
    if (!data) {
      return null;
    }

    const { mediaCampaign, mediaItemRewardTariff, name, id } =
      data.company.referralLinkMediaItem;

    return (
      <EditMediaItemReferralLinkModal
        mediaItemReferralLinkId={id}
        mediaCampaign={mediaCampaign}
        name={name}
        mediaItemRewardTariff={mediaItemRewardTariff}
      />
    );
  }

  const tabs: Array<Tabs> = [
    {
      title: 'О рефссылке',
      content: (
        <AboutReferralLinkMediaItem
          data={data}
          loading={loading}
          error={error}
        />
      ),
    },
  ];

  return <TabsWrapper tabs={tabs} />;
};
