import { useCallback, useRef } from 'react';
import { ApolloError } from '@apollo/client';
import { throttle } from 'lodash-es';
import errorToast from 'ui/Toast/ErrorToast';
import { nbsp } from 'src/const';
import { useGetProviderGameForFilterLazyQuery } from '../queries/generated/GetProviderGameForFilter';

const COUNT_PER_PAGE = 10;

export const useGetProviderGameQuery = () => {
  const handleError = (error: ApolloError) => {
    errorToast({
      header: `При${nbsp}загрузке игр произошла ошибка`,
      text: error.message,
      toastId: 'get-games-for-filter-error',
    });
  };

  const [loadGames, { data, loading, fetchMore: fetchMoreGames, error }] =
    useGetProviderGameForFilterLazyQuery({
      onError: handleError,
    });

  const hasNextPage = data?.providerGames.pageInfo.hasNextPage;
  const endCursor = data?.providerGames?.pageInfo.endCursor;

  const fetchMore = useCallback(
    () =>
      fetchMoreGames({
        variables: {
          first: COUNT_PER_PAGE,
          after: endCursor,
        },
      }),
    [fetchMoreGames, endCursor],
  );

  const loadProviderGamesWithThrottle = useRef(
    throttle(
      async (searchText = '') =>
        loadGames({
          variables: { search: searchText, first: COUNT_PER_PAGE },
        }),
      1000,
    ),
  ).current;

  return {
    loadProviderGamesWithThrottle,
    data,
    loading,
    hasNextPage,
    fetchMore,
    error,
  };
};
