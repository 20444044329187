import Button, { ButtonTheme } from 'ui/Button';
import { FC } from 'react';
import styles from './ConfirmationButtons.module.scss';

interface Props {
  onResetFilter: () => void;
  isSubmitDisabled: boolean;
  isResetDisabled: boolean;
}

export const ConfirmationButtons: FC<Props> = ({
  onResetFilter,
  isSubmitDisabled,
  isResetDisabled,
}) => (
  <div className={styles.buttons}>
    <Button
      disabled={isResetDisabled}
      onClick={onResetFilter}
      className={styles.button}
      theme={ButtonTheme.Cancel}
    >
      Сбросить
    </Button>
    <div className={styles.buttonContainer}>
      <Button
        disabled={isSubmitDisabled}
        type="submit"
        className={styles.button}
        theme={ButtonTheme.Submit}
      >
        Применить
      </Button>
    </div>
  </div>
);
