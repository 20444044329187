import { CompanyApplicationStatus } from 'generatedGraphql';
import { CardIconConfig } from 'ui/Card/CardIcon';
import { companyApplicationStatusMap, nbsp } from 'src/const';
import { getCompanyApplicationStatusIcon } from './getCompanyApplicationStatusIcon';
import { CompanyApplicationData } from '../queries/generated/CompanyApplicationData';

export const getCompanyApplicationStatus = (
  status: CompanyApplicationStatus,
  admin?: CompanyApplicationData['admin'] | null,
  declineReason?: string | null,
): [string, CardIconConfig, string | null] => {
  const formattedStatus = companyApplicationStatusMap[status];
  const iconConf = getCompanyApplicationStatusIcon(status);

  let statusText = formattedStatus;
  let subTitle = null;

  if (status === CompanyApplicationStatus.InProgress && admin) {
    const { firstName, lastName } = admin;

    statusText = `${formattedStatus}:${nbsp}${firstName}${nbsp}${lastName}`;
  }

  if (status === CompanyApplicationStatus.Declined && declineReason) {
    subTitle = declineReason;
  }

  return [statusText, iconConf, subTitle];
};
