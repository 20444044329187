export enum ColumnId {
  IPAddress = 'ipAddress',
  Asn = 'asn',
  Country = 'country',
  City = 'city',
  UserAgent = 'userAgent',
  LoginAt = 'loginAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.IPAddress]: 'IP-адрес',
  [ColumnId.Asn]: 'ASN',
  [ColumnId.Country]: 'Страна',
  [ColumnId.City]: 'Город',
  [ColumnId.UserAgent]: 'Браузер, устройство',
  [ColumnId.LoginAt]: 'Дата, время',
};

export const pageColumnsIds = [
  ColumnId.IPAddress,
  ColumnId.Asn,
  ColumnId.Country,
  ColumnId.City,
  ColumnId.UserAgent,
  ColumnId.LoginAt,
];

export const blockColumnsIds = [
  ColumnId.IPAddress,
  ColumnId.Country,
  ColumnId.City,
  ColumnId.UserAgent,
  ColumnId.LoginAt,
];
