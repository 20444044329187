export enum AffiliateMediaItemsSteps {
  BaseStep = 1,
  PromoCodeDetails = 2,
  CreatePromoCodeBaseInfo = 3,
  CreatePromoCodeConnection = 4,
  CreatePromoCodeConfirm = 5,
  ReferralLinkDetails = 6,
  CreateReferralLink = 7,
  EditReferralLink = 8,
}
