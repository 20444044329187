import { useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { RewardTariffType } from 'commonTypes';
import { useUpdateReferralLinkMediaItem } from '../queries/generated/UpdateReferralLinkMediaItem';
import { UpdateReferralLinkMediaItemValues } from '../types';

interface Params {
  mediaItemReferralLinkId: string;
}

export const useEditMediaItemReferralLinkModal = ({
  mediaItemReferralLinkId,
}: Params) => {
  const [updateReferralLinkMediaItem, { error, loading, data }] =
    useUpdateReferralLinkMediaItem();

  const { dispatch } = useContext(ModalContext);

  const handleError = () => {
    toast.error('Параметры рефссылки не изменены');
  };

  const handleComplete = () => {
    dispatch({ type: ModalActions.Close });
    toast.success('Вы изменили параметры рефссылки');
  };

  const onEdit = (values: UpdateReferralLinkMediaItemValues) => {
    const {
      name,
      mediaCampaignId,
      cpaRewardTariffId,
      fallbackTariffId,
      revShareRewardTariffId,
      typeRewardTariff,
    } = values;

    const hasCpaTariff =
      !!cpaRewardTariffId && typeRewardTariff === RewardTariffType.Cpa;
    const hasRevShareTariff =
      !!revShareRewardTariffId &&
      typeRewardTariff === RewardTariffType.RevShare;

    updateReferralLinkMediaItem({
      variables: {
        input: {
          id: mediaItemReferralLinkId,
          name,
          mediaCampaignId,
          mediaItemRewardTariff: {
            ...(hasCpaTariff
              ? {
                  cpaRewardTariff: {
                    id: cpaRewardTariffId,
                    fallbackRewardTariffId: fallbackTariffId,
                  },
                }
              : null),
            ...(hasRevShareTariff
              ? {
                  revShareRewardTariff: {
                    id: revShareRewardTariffId,
                  },
                }
              : null),
          },
        },
      },
      onError: handleError,
      onCompleted: handleComplete,
    });
  };

  return { onEdit, loading, error, data };
};
