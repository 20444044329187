import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdateCompanyAffiliateReferralLinkMediaItemVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateReferralLinkMediaItemInput;
}>;


export type UpdateCompanyAffiliateReferralLinkMediaItem = { __typename: 'Mutation', updateReferralLinkMediaItem: { __typename: 'UpdateReferralLinkMediaItemPayload', referralLinkMediaItem: { __typename: 'ReferralLinkMediaItem', id: string, name: string } } };


export const UpdateCompanyAffiliateReferralLinkMediaItemDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateCompanyAffiliateReferralLinkMediaItem"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateReferralLinkMediaItemInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateReferralLinkMediaItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referralLinkMediaItem"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode;
export type UpdateCompanyAffiliateReferralLinkMediaItemMutationFn = Apollo.MutationFunction<UpdateCompanyAffiliateReferralLinkMediaItem, UpdateCompanyAffiliateReferralLinkMediaItemVariables>;

/**
 * __useUpdateCompanyAffiliateReferralLinkMediaItem__
 *
 * To run a mutation, you first call `useUpdateCompanyAffiliateReferralLinkMediaItem` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyAffiliateReferralLinkMediaItem` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyAffiliateReferralLinkMediaItem, { data, loading, error }] = useUpdateCompanyAffiliateReferralLinkMediaItem({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyAffiliateReferralLinkMediaItem(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyAffiliateReferralLinkMediaItem, UpdateCompanyAffiliateReferralLinkMediaItemVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateCompanyAffiliateReferralLinkMediaItem, UpdateCompanyAffiliateReferralLinkMediaItemVariables>(UpdateCompanyAffiliateReferralLinkMediaItemDocument, options);
      }
export type UpdateCompanyAffiliateReferralLinkMediaItemHookResult = ReturnType<typeof useUpdateCompanyAffiliateReferralLinkMediaItem>;
export type UpdateCompanyAffiliateReferralLinkMediaItemMutationResult = Apollo.MutationResult<UpdateCompanyAffiliateReferralLinkMediaItem>;
export type UpdateCompanyAffiliateReferralLinkMediaItemMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyAffiliateReferralLinkMediaItem, UpdateCompanyAffiliateReferralLinkMediaItemVariables>;