import { useCallback } from 'react';
import { useGetPartnerReferralCodesLazyQuery } from 'queries/generated/GetPartnerReferralCodes';

const COUNT_PER_PAGE = 10;

export const useGetReferralCodes = () => {
  const [
    loadPartnerReferralCodes,
    {
      data: getPartnerReferralCodesResult,
      loading,
      fetchMore: getPartnerReferralCodesFetchMore,
    },
  ] = useGetPartnerReferralCodesLazyQuery();

  const referralLinkMediaItems =
    getPartnerReferralCodesResult?.partner.referralLinkMediaItems;

  const loadReferralCodes = useCallback(
    (affiliateId: string) => {
      loadPartnerReferralCodes({
        variables: {
          partnerId: affiliateId,
          first: COUNT_PER_PAGE,
        },
      });
    },
    [loadPartnerReferralCodes],
  );

  const options = referralLinkMediaItems?.edges.map(({ node: { code } }) => ({
    id: code,
    label: code,
  }));

  const hasNextPage = referralLinkMediaItems?.pageInfo.hasNextPage;
  const endCursor = referralLinkMediaItems?.pageInfo.endCursor;

  const fetchMore = useCallback(
    () =>
      getPartnerReferralCodesFetchMore({
        variables: {
          first: COUNT_PER_PAGE,
          after: endCursor,
        },
      }),
    [getPartnerReferralCodesFetchMore, endCursor],
  );

  return {
    loadReferralCodes,
    options,
    hasNextPage,
    fetchMore,
    loading,
  };
};
