import NowrapCell from 'ui/Table/components/NowrapCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { twoEmDash } from 'src/const';
import {
  ColumnId,
  mapColumnIdToName,
  columnsIds,
  mapLoginHistoryActorTypeToText,
} from '../const';
import { LoginHistoryData } from '../../../queries/generated/LoginHistoryData';
import { getEmailByTypeName, getRouteByTypeName } from '../helper';

const columnHelper = createColumnHelper<LoginHistoryData>();

const columnsSource: ColumnsSource<LoginHistoryData> = [
  columnHelper.accessor(ColumnId.IPAddress, {
    id: ColumnId.IPAddress,
    header: mapColumnIdToName[ColumnId.IPAddress],
    cell: ({ row }) => MayBeNullCell(row.original.ipAddress),
  }),
  columnHelper.accessor(ColumnId.Actor, {
    id: ColumnId.Actor,
    header: mapColumnIdToName[ColumnId.Actor],
    cell: ({ row }) => {
      const { actor } = row.original;

      return (
        <MultilineCellWithLink
          isOpenInNewWindow
          title={actor.name}
          subTitle={getEmailByTypeName(actor)}
          route={getRouteByTypeName(actor)}
        />
      );
    },
  }),
  columnHelper.accessor(ColumnId.ActorType, {
    id: ColumnId.ActorType,
    header: mapColumnIdToName[ColumnId.ActorType],
    cell: ({ row }) =>
      mapLoginHistoryActorTypeToText[row.original.actor.__typename],
  }),
  columnHelper.accessor(ColumnId.Asn, {
    id: ColumnId.Asn,
    header: mapColumnIdToName[ColumnId.Asn],
    cell: ({ row }) => MayBeNullCell(row.original.asn),
  }),
  columnHelper.accessor(ColumnId.Country, {
    id: ColumnId.Country,
    header: mapColumnIdToName[ColumnId.Country],
    cell: ({ row }) => MayBeNullCell(row.original.country),
  }),
  columnHelper.accessor(ColumnId.City, {
    id: ColumnId.City,
    header: mapColumnIdToName[ColumnId.City],
    cell: ({ row }) => MayBeNullCell(row.original.city),
  }),
  columnHelper.accessor(ColumnId.UserAgent, {
    id: ColumnId.UserAgent,
    header: mapColumnIdToName[ColumnId.UserAgent],
    cell: ({ row }) => MayBeNullCell(row.original.userAgent),
  }),
  columnHelper.accessor(ColumnId.Locale, {
    id: ColumnId.Locale,
    header: mapColumnIdToName[ColumnId.Locale],
    cell: ({ row }) => MayBeNullCell(row.original.locale, twoEmDash),
  }),
  columnHelper.accessor(ColumnId.LoginAt, {
    id: ColumnId.LoginAt,
    header: mapColumnIdToName[ColumnId.LoginAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.loginAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetLoginHistoryColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return { columns };
};
