import { Dispatch, SetStateAction } from 'react';
import { FilterState } from '../types';

interface Params<T> {
  setFilter: (filterId: T, values: Array<string>) => void;
  filterId: T;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}

export const getHandleSubmit =
  <T extends string>({ setFilter, filterId, setIsOpened }: Params<T>) =>
  (values: FilterState) => {
    const selectedValue = values[filterId];

    setFilter(filterId, selectedValue ? [selectedValue] : []);
    setIsOpened(false);
  };
