import { Dispatch, SetStateAction } from 'react';
import { Formik } from 'formik';
import { ApolloError } from '@apollo/client';
import { getHandleSubmit } from './helpers';
import { getResetFilters } from '../../helpers';
import { FilterSelectOption } from '../../types';
import { useMultiSelectInitialValues } from './hooks/useMultiSelectInitialValues';
import { MultiSelectForm } from './components/MultiSelectForm';

interface Props<T> {
  options: Array<FilterSelectOption>;
  setFilter: (filterId: T, values: Array<string>) => void;
  filterId: T;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  getFilter?: (filterId: T) => Array<string>;
  fetchMoreConfig?: {
    fetchMore?: () => void;
    hasNextPage?: boolean;
  };
  error?: ApolloError;
  loading?: boolean;
  extendedClassname?: string;
  onSearchInList?: (value: string) => void;
  placeholder?: string;
  emptyListText?: string;
  enableReinitialize?: boolean;
}

export const MultiSelectFilter = <T extends string>({
  options,
  setFilter,
  getFilter,
  filterId,
  setIsOpened,
  onSearchInList,
  fetchMoreConfig,
  error,
  loading,
  extendedClassname,
  placeholder = 'Поиск',
  emptyListText = 'Ничего не найдено',
  enableReinitialize = true,
}: Props<T>) => {
  const activeFilterIds = getFilter ? getFilter(filterId) : [];
  const { initialValues } = useMultiSelectInitialValues(
    options,
    filterId,
    activeFilterIds,
  );

  const handleSubmit = getHandleSubmit({
    setFilter,
    filterId,
    setIsOpened,
  });

  const resetFilter = getResetFilters({ setFilter, filterId, setIsOpened });

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <MultiSelectForm
        options={options}
        initialValues={initialValues}
        filterId={filterId}
        getFilter={getFilter}
        resetFilter={resetFilter}
        onSearchInList={onSearchInList}
        fetchMoreConfig={fetchMoreConfig}
        error={error}
        loading={loading}
        extendedClassname={extendedClassname}
        placeholder={placeholder}
        emptyListText={emptyListText}
      />
    </Formik>
  );
};
