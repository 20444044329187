import { FC, useState } from 'react';
import Block from 'ui/Block';
import { isListEmpty } from 'ui/Block/BlockTable/utils';
import { withBlockProvider } from 'ui/Block/store';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { MediaItemsTabs } from 'commonComponents/MediaItemsTabs';
import { MediaItemTypeName } from 'commonTypes';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useAuth } from 'commonComponents/Auth';
import { useGetMediaItemsData } from './hooks/useGetMediaItemsData';
import { MediaItemTable } from './components/MediaItemTable';
import { EMPTY_PROMO_CODE_LIST, EMPTY_REFERRAL_LINK_LIST } from './const';
import { MediaItemsBlockHeaderContent } from './components/MediaItemsBlockHeaderContent';

interface Props {
  id: string;
  isFullScreenTable: boolean;
  shouldReturnToPrevPage?: boolean;
}

export const PartnerMediaItemsBlock: FC<Props> = withBlockProvider(
  ({ id, isFullScreenTable, shouldReturnToPrevPage }) => {
    const auth = useAuth();

    const [mediaItemsType, setMediaItemsType] = useState<MediaItemTypeName>(
      MediaItemTypeName.ReferralLink,
    );

    const {
      mediaItems,
      refetch,
      loadPartnerMediaItem,
      loading,
      error,
      fetchMore,
      hasNextPage,
      mediaItemsData,
      columns,
    } = useGetMediaItemsData({
      mediaItemsType,
      partnerId: id,
      isFullScreenTable,
    });

    useBlockComponentState({
      loadData: loadPartnerMediaItem,
      loading,
      error,
    });

    useBlockTableComponentState({
      data: mediaItemsData,
      fetchMoreCallback: fetchMore,
      refetch,
      loading,
      hasNextPage,
      isFullScreenTable,
    });

    const emptyBlockText =
      mediaItemsType === MediaItemTypeName.PromoCode
        ? EMPTY_PROMO_CODE_LIST
        : EMPTY_REFERRAL_LINK_LIST;

    const isReferralLinkVisible = auth.privileges.areEveryGranted({
      privileges: [UserPrivilege.ViewPartnerReferralLinkMediaItem],
    });
    const isPromoCodeVisible = auth.privileges.areEveryGranted({
      privileges: [UserPrivilege.ViewPartnerPromoCodeMediaItem],
    });

    return (
      <Block
        title="Медиаэлементы"
        id="partnerMediaItems"
        headerContent={
          <MediaItemsBlockHeaderContent
            handleRefetchClick={() => refetch()}
            partnerId={id}
            isFullScreenTable={isFullScreenTable}
          />
        }
        subHeader={
          <MediaItemsTabs
            currentTabId={mediaItemsType}
            onClick={setMediaItemsType}
            isReferralLinkVisible={isReferralLinkVisible}
            isPromoCodeVisible={isPromoCodeVisible}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText={emptyBlockText}
        isEmpty={isListEmpty(mediaItems)}
        shouldReturnToPrevPage={shouldReturnToPrevPage}
      >
        <MediaItemTable
          isFullScreenTable={isFullScreenTable}
          mediaItemsType={mediaItemsType}
          mediaItems={mediaItems}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          columns={columns as any}
          partnerId={id}
        />
      </Block>
    );
  },
);
