import { CreateMediaElementOptionsIds } from '../const';
import { useOpenCreateMediaItemsModal } from './useOpenCreateMediaItemsModal';

interface Params {
  partnerId: string;
  isFullScreenTable: boolean;
}
export const useGetCreateMediaItemAction = ({
  partnerId,
  isFullScreenTable,
}: Params) => {
  const { openCreateReferralLinkModal, openCreatePromoCodeModal } =
    useOpenCreateMediaItemsModal({
      partnerId,
      isFullScreenTable,
    });

  const mapOptionToAction: {
    [key in CreateMediaElementOptionsIds]: () => void;
  } = {
    [CreateMediaElementOptionsIds.ReferralLink]: openCreateReferralLinkModal,
    [CreateMediaElementOptionsIds.PromoCode]: openCreatePromoCodeModal,
  };

  return { mapOptionToAction };
};
