import { FC, useContext } from 'react';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { Filters } from 'ui/Filters';
import { GameActionsTable } from './components/GameActionsTable';
import { useGetGameActions, useGetFiltersConfig } from './hooks';
import styles from './GameActions.module.scss';

const BLOCK_TITLE = 'Игровые действия';
const EMPTY_TEXT = 'Игровых действий нет';

export const GameActions: FC = withBlockProvider(() => {
  const { state } = useContext(BlockContext);

  const {
    gameActions,
    loading,
    loadGameActions,
    error,
    data,
    fetchMoreCallback,
    refetch,
    hasNextPage,
  } = useGetGameActions();

  useBlockComponentState({
    loadData: loadGameActions,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  const filterConfigs = useGetFiltersConfig();

  return (
    <>
      <Filters
        containerClassName={styles.filter}
        filterConfigs={filterConfigs}
        isDisabled={loading}
      />
      <Block
        title={BLOCK_TITLE}
        id="gameActions"
        headerContent={
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={() => refetch()}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText={EMPTY_TEXT}
        isEmpty={isListEmpty(gameActions)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(gameActions) && <GameActionsTable gameActions={gameActions} />}
      </Block>
    </>
  );
});
