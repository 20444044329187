import NowrapCell from 'ui/Table/components/NowrapCell';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { CopyTableRow } from 'ui/CopyTableRow';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import {
  mapColumnIdToName,
  ColumnId,
  pageColumnsIds,
  blockColumnsIds,
} from '../const';
import { CompanyReferralLinksData } from '../queries/generated/CompanyReferralLinksData';

const columnHelper = createColumnHelper<CompanyReferralLinksData>();

const columnsSource: ColumnsSource<CompanyReferralLinksData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
  }),
  columnHelper.accessor(ColumnId.Name, {
    id: ColumnId.Name,
    header: mapColumnIdToName[ColumnId.Name],
  }),
  columnHelper.accessor(ColumnId.MediaCampaign, {
    id: ColumnId.MediaCampaign,
    header: mapColumnIdToName[ColumnId.MediaCampaign],
    cell: ({ row }) => {
      const { name, url } = row.original.mediaCampaign;

      return <MultilineCellWithIcon title={name} subTitle={url} />;
    },
  }),
  columnHelper.accessor(ColumnId.ReferralLink, {
    id: ColumnId.ReferralLink,
    header: mapColumnIdToName[ColumnId.ReferralLink],
    cell: ({ row }) => (
      <CopyTableRow
        value={row.original.referralLink}
        toastText="Вы скопировали рефссылку"
      />
    ),
  }),
  columnHelper.accessor(ColumnId.PostbackCount, {
    id: ColumnId.PostbackCount,
    header: mapColumnIdToName[ColumnId.PostbackCount],
  }),
  columnHelper.accessor(ColumnId.RewardTariff, {
    id: ColumnId.RewardTariff,
    header: mapColumnIdToName[ColumnId.RewardTariff],
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetReferralLinksColumns = (isFullScreenTable = false) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource,
  );

  return {
    columns,
  };
};
