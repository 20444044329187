import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DateTimeValues } from 'ui/DatePicker/types';
import { parseDateFromString } from 'utils/parseDateFromString';

export const useDateFilterInitialValues = (filterId: string) => {
  const [searchParams] = useSearchParams();
  const params = searchParams.getAll(filterId);

  const [initialValues, setInitialValues] = useState<DateTimeValues>({
    dates: [],
    times: [],
  });

  useEffect(() => {
    if ((params[0] || params[1]) && initialValues.dates.length === 0) {
      const [queryStartDate, startTime] = params[0].split(' ');
      const [queryEndDate, endTime] = (params[1] || '').split(' ');

      const startDate = queryStartDate
        ? parseDateFromString(queryStartDate)
        : null;

      const endDate = queryEndDate ? parseDateFromString(queryEndDate) : null;

      setInitialValues({
        dates: [startDate, endDate],
        times: [startTime, endTime],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(params)]);

  return { initialValues };
};
