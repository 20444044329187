import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { useFormikContext } from 'formik';
import { TextOverflowMiddle } from 'ui/TextOverflowMiddle';
import { FieldName, mapFieldNameToTitle } from '../../../../const';
import { promoCodeUsageTypeMap } from '../../../../../../../../../../const';
import { CreatePromoCodeValues } from '../../../../types';
import styles from './DataCardMain.module.scss';

export const DataCardMain: FC = () => {
  const { values } = useFormikContext<CreatePromoCodeValues>();

  return (
    <DataCard
      containerClassName={styles.container}
      rows={[
        {
          title: mapFieldNameToTitle[FieldName.ActivationLimit],
          value: values[FieldName.ActivationLimit],
        },
        {
          title: mapFieldNameToTitle[FieldName.Token],
          value: values[FieldName.Token],
        },
        {
          title: mapFieldNameToTitle[FieldName.MediaCampaignName],
          value: values[FieldName.MediaCampaignName],
        },
        {
          title: mapFieldNameToTitle[FieldName.MediaCampaignUrl],
          value: values[FieldName.MediaCampaignUrl],
        },
        values[FieldName.Usage] && {
          title: mapFieldNameToTitle[FieldName.Usage],
          value: promoCodeUsageTypeMap[values[FieldName.Usage]],
        },
        {
          title: mapFieldNameToTitle[FieldName.ReferralLinkUrl],
          value: values[FieldName.ReferralLinkUrl] && (
            <div className={styles.referralLink}>
              <TextOverflowMiddle text={values[FieldName.ReferralLinkUrl]} />
            </div>
          ),
        },
      ]}
    />
  );
};
