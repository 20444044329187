export enum ColumnId {
  Id = 'id',
  PartnerInfo = '__typename',
  ContactForCommunication = 'contactForCommunication',
  TrafficSource = 'trafficSource',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.PartnerInfo]: 'Имя пользователя и почта',
  [ColumnId.ContactForCommunication]: 'Контактная информация',
  [ColumnId.TrafficSource]: 'Источник трафика',
  [ColumnId.Status]: 'Статус',
  [ColumnId.CreatedAt]: 'Создан',
  [ColumnId.UpdatedAt]: 'Обновлён',
};

export const columnsIds = [
  ColumnId.Id,
  ColumnId.PartnerInfo,
  ColumnId.ContactForCommunication,
  ColumnId.TrafficSource,
  ColumnId.Status,
  ColumnId.CreatedAt,
  ColumnId.UpdatedAt,
];
