import { FC } from 'react';
import { ProgressBar, ProgressBarView } from 'ui/ProgressBar';
import { getPromoCodeActivationProgressBarConfig } from 'utils/getPromoCodeActivationProgressBarConfig';
import styles from './ProgressBarWithLimit.module.scss';

interface Props {
  activated: number;
  limit: number;
}

export const ProgressBarWithLimit: FC<Props> = ({ activated, limit }) => {
  const config = getPromoCodeActivationProgressBarConfig({ activated, limit });

  if (!config) {
    return null;
  }

  const { isInfinity, percent } = config;
  const progressText = isInfinity ? activated : `${activated}/${limit}`;

  return (
    <>
      <div className={styles.progress}>{progressText}</div>
      <ProgressBar
        viewType={isInfinity ? ProgressBarView.Infinity : undefined}
        percent={percent}
      />
    </>
  );
};
