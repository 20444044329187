import NowrapCell from 'ui/Table/components/NowrapCell';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import { generatePath } from 'react-router';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { Route } from 'src/router/routes.const';
import { TableCellAlign } from 'ui/Table/const';
import { gameActionTypeMap, mapBalanceTypeToTitle, twoEmDash } from 'src/const';
import { formatSumAmount } from 'utils/accounting';
import { ColumnId, mapColumnIdToName, columnsIds } from '../const';
import { GameActionData } from '../../../queries/generated/GameActionData';
import { getStatusIcon } from '../helpers/getStatusConfig';

const columnHelper = createColumnHelper<GameActionData>();

const columnsSource: ColumnsSource<GameActionData> = [
  columnHelper.accessor(ColumnId.PlayerId, {
    id: ColumnId.PlayerId,
    header: mapColumnIdToName[ColumnId.PlayerId],
    cell: ({ row }) => {
      const { id, name, email } = row.original.player;
      const playerUrl = generatePath(Route.PLAYER, { id });

      return (
        <MultilineCellWithLink
          isOpenInNewWindow
          title={name}
          subTitle={email}
          route={playerUrl}
        />
      );
    },
  }),
  columnHelper.accessor(ColumnId.Total, {
    id: ColumnId.Total,
    header: mapColumnIdToName[ColumnId.Total],
    meta: {
      align: TableCellAlign.Right,
    },
    cell: ({ row }) => formatSumAmount(row.original.total),
  }),
  columnHelper.accessor(ColumnId.Balance, {
    id: ColumnId.Balance,
    header: mapColumnIdToName[ColumnId.Balance],
    meta: {
      align: TableCellAlign.Right,
    },
    cell: ({ row }) => formatSumAmount(row.original.balanceAfterAction),
  }),
  columnHelper.accessor(ColumnId.BalanceType, {
    id: ColumnId.BalanceType,
    header: mapColumnIdToName[ColumnId.BalanceType],
    cell: ({ row }) => mapBalanceTypeToTitle[row.original.balanceType],
  }),
  columnHelper.accessor(ColumnId.Currency, {
    id: ColumnId.Currency,
    header: mapColumnIdToName[ColumnId.Currency],
  }),
  columnHelper.accessor(ColumnId.Action, {
    id: ColumnId.Action,
    header: mapColumnIdToName[ColumnId.Action],
    cell: ({ row }) => {
      const { type } = row.original;

      const title = gameActionTypeMap[type];
      const iconConfig = getStatusIcon(type);

      return MultilineCellWithIcon({ title, iconConfig });
    },
  }),
  columnHelper.accessor(ColumnId.Provider, {
    id: ColumnId.Provider,
    header: mapColumnIdToName[ColumnId.Provider],
    cell: ({ row }) => {
      const gameName = row.original.providerGame.name;
      const providerName = row.original.providerGame.provider;

      return MultilineCellWithIcon({ title: gameName, subTitle: providerName });
    },
  }),
  columnHelper.accessor(ColumnId.Category, {
    id: ColumnId.Category,
    header: mapColumnIdToName[ColumnId.Category],
  }),
  columnHelper.accessor(ColumnId.RoundId, {
    id: ColumnId.RoundId,
    header: mapColumnIdToName[ColumnId.RoundId],
    cell: ({ row }) => MayBeNullCell(row.original.roundId, twoEmDash),
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDateWithSeconds)}
      </NowrapCell>
    ),
  }),
];

export const useGetGameActionsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
