export const getRangePreviousReportingPeriod = () => {
  const startDate = new Date();
  const endDate = new Date();

  const todayDay = new Date().getDate();

  if (todayDay < 15) {
    startDate.setDate(15);
    startDate.setMonth(startDate.getMonth() - 1);

    endDate.setDate(0);
  } else {
    startDate.setDate(1);
    endDate.setDate(14);
  }

  return { startOfRange: startDate, endOfRange: endDate };
};
