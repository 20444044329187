import { FC } from 'react';
import { Tabs, TabFieldInterface } from 'ui/Tabs';
import { RewardTariffType } from 'commonTypes';
import { rewardTariffMap } from 'src/const';
import { useAuth, UserPrivilege } from 'commonComponents/Auth';

interface Props {
  currentTabId?: RewardTariffType;
  onClick: (type: RewardTariffType) => void;
  viewRevSharePrivilege: UserPrivilege;
  viewCpaPrivilege: UserPrivilege;
}

export const RewardTariffsTabs: FC<Props> = ({
  currentTabId,
  onClick,
  viewRevSharePrivilege,
  viewCpaPrivilege,
}) => {
  const auth = useAuth();

  const tabs: Array<TabFieldInterface<RewardTariffType>> = [
    {
      id: RewardTariffType.RevShare,
      name: <span>{rewardTariffMap[RewardTariffType.RevShare]}</span>,
      isVisible: auth.privileges.isSomeGranted({
        privileges: [viewRevSharePrivilege],
      }),
    },
    {
      id: RewardTariffType.Cpa,
      name: <span>{rewardTariffMap[RewardTariffType.Cpa]}</span>,
      isVisible: auth.privileges.isSomeGranted({
        privileges: [viewCpaPrivilege],
      }),
    },
  ];

  const handleTabChange = (id: RewardTariffType) => {
    onClick(id);
  };

  return (
    <Tabs
      tabs={tabs}
      currentTabId={currentTabId}
      onChangeTab={handleTabChange}
    />
  );
};
