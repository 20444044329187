import { useState } from 'react';
import { DatePickerHandleProps } from 'ui/DatePicker';
import { nbsp } from 'src/const';
import {
  getDateTitle,
  getRangeAllTime,
  getRangeTwoLastWeek,
  getRangeLastMonth,
  getRangeLastWeek,
  getRangeToday,
  getRangePrevMonth,
  getRangeCurrentMonth,
  getRangeYesterday,
  getRangePreviousReportingPeriod,
  getRangeCurrentReportingPeriod,
} from '../helpers';
import { DateRanges } from '../const';

interface UseDatePickerWithRange {
  minDateInitial: Date | null | undefined;
  startDateInitial: Date | null | undefined;
  endDateInitial: Date | null | undefined;
  onDateSubmit: (date: DatePickerHandleProps) => void;
}

export const useDatePickerWithRange = ({
  minDateInitial,
  startDateInitial,
  endDateInitial,
  onDateSubmit,
}: UseDatePickerWithRange) => {
  const [activeCustomRange, setActiveCustomRange] = useState<DateRanges | null>(
    null,
  );

  const [startDate, setStartDate] = useState(startDateInitial || null);
  const [endDate, setEndDate] = useState(endDateInitial || null);

  const handleSubmit = (dates: DatePickerHandleProps) => {
    if (!Array.isArray(dates)) {
      return;
    }

    const [startDateSubmit, endDateSubmit] = dates;

    if (startDateSubmit && endDateSubmit) {
      onDateSubmit(dates);
    }

    if (!startDateSubmit && !endDateSubmit) {
      onDateSubmit(dates);
      setActiveCustomRange(null);
    }
  };

  const handleChange = (dates: DatePickerHandleProps) => {
    setActiveCustomRange(null);
    if (Array.isArray(dates)) {
      const [startDateSubmit, endDateSubmit] = dates;

      setStartDate(startDateSubmit);
      setEndDate(endDateSubmit);
    }
  };

  const setDateAllTime = () => {
    if (activeCustomRange !== DateRanges.AllTime) {
      const { startRange, endRange } = getRangeAllTime(minDateInitial);

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.AllTime);
  };

  const setDateLastWeek = () => {
    if (activeCustomRange !== DateRanges.LastWeek) {
      const { startRange, endRange } = getRangeLastWeek();

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.LastWeek);
  };

  const setDateTwoLastWeek = () => {
    if (activeCustomRange !== DateRanges.TwoLastWeek) {
      const { startRange, endRange } = getRangeTwoLastWeek();

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.TwoLastWeek);
  };

  const setDateLastMonth = () => {
    if (activeCustomRange !== DateRanges.LastMonth) {
      const { startRange, endRange } = getRangeLastMonth();

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.LastMonth);
  };

  const setDateCurrentMonth = () => {
    if (activeCustomRange !== DateRanges.CurrentMonth) {
      const { startRange, endRange } = getRangeCurrentMonth();

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.CurrentMonth);
  };

  const setDatePrevMonth = () => {
    if (activeCustomRange !== DateRanges.PrevMonth) {
      const { startRange, endRange } = getRangePrevMonth();

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.PrevMonth);
  };

  const setDateToday = () => {
    if (activeCustomRange !== DateRanges.Today) {
      const { startRange, endRange } = getRangeToday();

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.Today);
  };

  const setDateYesterday = () => {
    if (activeCustomRange !== DateRanges.Yesterday) {
      const { startRange, endRange } = getRangeYesterday();

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.Yesterday);
  };

  const setDateCurrentReportingPeriod = () => {
    if (activeCustomRange !== DateRanges.CurrentReportingPeriod) {
      const { startOfRange, endOfRange } = getRangeCurrentReportingPeriod();

      setStartDate(startOfRange);
      setEndDate(endOfRange);
    }

    setActiveCustomRange(DateRanges.CurrentReportingPeriod);
  };

  const setDatePreviousReportingPeriod = () => {
    if (activeCustomRange !== DateRanges.PreviousReportingPeriod) {
      const { startOfRange, endOfRange } = getRangePreviousReportingPeriod();

      setStartDate(startOfRange);
      setEndDate(endOfRange);
    }

    setActiveCustomRange(DateRanges.PreviousReportingPeriod);
  };

  const customRanges = {
    [DateRanges.Yesterday]: {
      onClick: setDateYesterday,
      title: `Вчера`,
    },
    [DateRanges.Today]: {
      onClick: setDateToday,
      title: `Сегодня`,
    },
    [DateRanges.CurrentMonth]: {
      onClick: setDateCurrentMonth,
      title: `Текущий месяц`,
    },
    [DateRanges.PrevMonth]: {
      onClick: setDatePrevMonth,
      title: `Прошлый месяц`,
    },
    [DateRanges.AllTime]: {
      onClick: setDateAllTime,
      title: `Всё время`,
    },
    [DateRanges.LastWeek]: {
      onClick: setDateLastWeek,
      title: `Последние 7 дней`,
    },
    [DateRanges.TwoLastWeek]: {
      onClick: setDateTwoLastWeek,
      title: `За${nbsp}последние две недели`,
    },
    [DateRanges.LastMonth]: {
      onClick: setDateLastMonth,
      title: `Последние 30 дней`,
    },
    [DateRanges.CurrentReportingPeriod]: {
      onClick: setDateCurrentReportingPeriod,
      title: `Текущий отчётный период`,
    },
    [DateRanges.PreviousReportingPeriod]: {
      onClick: setDatePreviousReportingPeriod,
      title: `Прошлый отчётный период`,
    },
  };

  const dateTitle = getDateTitle({
    startDate,
    endDate,
  });

  return {
    customRanges,
    activeCustomRange,
    startDate,
    endDate,
    handleSubmit,
    handleChange,
    dateTitle,
  };
};
