import { useContext } from 'react';
import { BlockContext } from 'ui/Block/store';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import BlockTable from 'ui/Block/BlockTable';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { ColumnDef } from '@tanstack/react-table';
import { nbsp } from 'src/const';
import { useGetPartnerLoginHistoryByTableSize } from './hooks/useGetPartnerLoginHistoryByTableSize';

interface Props<T extends object> {
  partnerId: string;
  columns: Array<ColumnDef<T>>;
  isFullScreenTable?: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
}

export const PartnerLoginHistoryTable = <T extends { __typename: string }>({
  partnerId,
  columns,
  isFullScreenTable,
  shouldReturnToPrevPage,
  routeToCustomPage,
}: Props<T>) => {
  const { state } = useContext(BlockContext);

  const {
    loadPartnerLoginHistoryList,
    data,
    loading,
    error,
    hasNextPage,
    refetch,
    fetchMoreCallback,
    partnerLoginHistoryList,
  } = useGetPartnerLoginHistoryByTableSize<T>({ partnerId, isFullScreenTable });

  useBlockComponentState({
    loadData: loadPartnerLoginHistoryList,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable,
  });

  return (
    <Block
      emptyText={`Нет историй входа в${nbsp}систему`}
      title="Логины"
      id="partnerLoginHistoryList"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          hidden={!state.isOpened}
          disabled={state.isLoading}
          onClick={() => refetch()}
        />
      }
      isEmpty={isListEmpty(partnerLoginHistoryList)}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {hasList(partnerLoginHistoryList) && (
        <BlockTable
          columns={columns}
          data={partnerLoginHistoryList}
          isFullScreenTable={isFullScreenTable}
          routeToCustomPage={routeToCustomPage}
        />
      )}
    </Block>
  );
};
