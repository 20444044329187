import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { ProgressBarWagerType } from 'src/components/ProgressBarWager/const';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell/MayBeNullCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCellAlign } from 'ui/Table/const';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { twoEmDash } from 'src/const';
import ProgressBarWager from 'commonComponents/ProgressBarWager';
import { getTransferType } from '../helpers';
import {
  blockColumnsIds,
  mapColumnIdToName,
  pageColumnsIds,
  ColumnId,
} from '../const';
import { TransferData } from '../queries/generated/TransferData';

const columnHelper = createColumnHelper<TransferData>();

const columnsSource: ColumnsSource<TransferData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
  }),
  columnHelper.accessor(ColumnId.Type, {
    id: ColumnId.Type,
    header: mapColumnIdToName[ColumnId.Type],
    cell: ({ row }) => {
      const [title, iconConfig] = getTransferType(row.original.type);

      return MultilineCellWithIcon({ title, iconConfig });
    },
  }),
  columnHelper.accessor(ColumnId.Account, {
    id: ColumnId.Account,
    header: mapColumnIdToName[ColumnId.Account],
    cell: ({ row }) => `${row.original.counterparty.moneyTransferAccount}`,
  }),
  columnHelper.accessor(ColumnId.Wager, {
    id: ColumnId.Wager,
    header: mapColumnIdToName[ColumnId.Wager],
    cell: ({ row }) => {
      const { wager } = row.original;

      return MayBeNullCell(
        wager && (
          <ProgressBarWager wager={wager} type={ProgressBarWagerType.Short} />
        ),
        twoEmDash,
      );
    },
  }),
  columnHelper.accessor(ColumnId.Counterparty, {
    id: ColumnId.Counterparty,
    header: mapColumnIdToName[ColumnId.Counterparty],
    cell: ({ row }) => {
      const { email, phone, name } = row.original.counterparty;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return MultilineCellWithIcon({ title: email || phone, subTitle: name });
    },
  }),
  columnHelper.accessor(ColumnId.Counterparty, {
    id: ColumnId.RecipientSender,
    header: mapColumnIdToName[ColumnId.RecipientSender],
    cell: ({ row }) => {
      const { email, phone } = row.original.counterparty;

      return email || phone;
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.NickName, {
    id: ColumnId.NickName,
    header: mapColumnIdToName[ColumnId.NickName],
  }),
  columnHelper.accessor(ColumnId.Sum, {
    id: ColumnId.Sum,
    header: mapColumnIdToName[ColumnId.Sum],
    cell: ({ row }) => {
      const { amount, currency } = row.original.sum;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetTransfersColumns = (isFullScreenTable: boolean) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource,
  );

  return {
    columns,
  };
};
