import { TabFieldInterface } from 'ui/Tabs';
import { MediaItemTypeName } from 'commonTypes';

interface Params {
  isReferralLinkVisible: boolean;
  isPromoCodeVisible: boolean;
}

export const getMediaItemsTabs = ({
  isReferralLinkVisible,
  isPromoCodeVisible,
}: Params): Array<TabFieldInterface<MediaItemTypeName>> => [
  {
    id: MediaItemTypeName.ReferralLink,
    name: <span>Ссылки</span>,
    isVisible: isReferralLinkVisible,
  },
  {
    id: MediaItemTypeName.PromoCode,
    name: <span>Промокоды</span>,
    isVisible: isPromoCodeVisible,
  },
];
