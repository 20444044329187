import moment from 'moment';

export enum DateFormat {
  FullDateWithSeconds = 'DD\u00A0MMM\u00A0YYYY, HH:mm:ss',
  FullDate = 'DD\u00A0MMM\u00A0YYYY, HH:mm',
  DefaultDate = 'DD.MM.YYYY',
  DefaultDateWithTime = 'DD.MM.YYYY, HH:mm',
  DefaultDateWithDash = 'YYYY-MM-DD',
  FullDateOld = 'YYYY-MM-DDTHH:mm:ssZ',
  Time = 'hh:mm:ss',
  ShortDate = 'MMMM\u00A0YYYY',
  DefaultDateOld = 'DD\u00A0MMM\u00A0YYYY',
  Year = 'YYYY',
  Month = 'MMMM',
  DateWithFullYearMonthDate = 'DD\u00A0MMMM\u00A0YYYY',
}

export const monthArray = [
  'янв',
  'февр',
  'марта',
  'апр',
  'мая',
  'июня',
  'июля',
  'авг',
  'сент',
  'окт',
  'нояб',
  'дек',
];

export const formatDate = (
  date: Date | string,
  format = DateFormat.DefaultDate,
  utcFormat = true,
): string => {
  const dateDate = date instanceof Date ? date : new Date(date);

  if (utcFormat) {
    return moment.utc(dateDate).format(format);
  }

  return moment(dateDate).format(format);
};
