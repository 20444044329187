import { useFormikContext } from 'formik';
import { useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { CreatePromoCodeValues } from '../../../types';
import { CreatePromoCodeModalSteps, FieldName } from '../../../const';

interface Result {
  clickGoToFirstStepHandler: () => void;
  clickGoToConfirmStepHandler: () => boolean;
}

export const useGoToCreatePromoCodeSteps = (): Result => {
  const { dispatch } = useContext(ModalContext);
  const { values, isValid, setTouched } =
    useFormikContext<CreatePromoCodeValues>();

  const updateModalStep = (step: CreatePromoCodeModalSteps) => {
    dispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        step,
        previousStep: CreatePromoCodeModalSteps.ConnectionWithFreespinsAndBonus,
      },
    });
  };

  // Set touched fields to show error after click on next step
  const clickGoToConfirmStepHandler = () => {
    setTouched(
      {
        ...(values[FieldName.ConnectionWithFreespins]
          ? {
              [FieldName.FreespinCount]: true,
            }
          : null),
        ...(values[FieldName.ConnectionWithBonus]
          ? {
              [FieldName.BonusAmount]: true,
            }
          : null),
      },
      true,
    );

    if (isValid) {
      updateModalStep(CreatePromoCodeModalSteps.ConfirmCreation);
    }

    return false;
  };

  const clickGoToFirstStepHandler = () => {
    updateModalStep(CreatePromoCodeModalSteps.BaseStep);
  };

  return {
    clickGoToFirstStepHandler,
    clickGoToConfirmStepHandler,
  };
};
