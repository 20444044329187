import classnames from 'classnames';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { Dispatch, SetStateAction } from 'react';
import styles from './Trigger.module.scss';

interface Props<T> {
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  filterId: T;
  selectedLabels: Array<string>;
  removeFilter: (filterId: T) => void;
  isDisabled: boolean;
}

const MAX_COUNT_TO_VIEW = 3;

export const Trigger = <T extends string>({
  filterId,
  setIsOpened,
  isOpened,
  selectedLabels,
  removeFilter,
  isDisabled,
}: Props<T>) => {
  const counter = selectedLabels.length - MAX_COUNT_TO_VIEW;
  const isSingleItem = selectedLabels.length === 1;

  const openClickByTrigger = () => {
    if (!isDisabled) {
      setIsOpened(!isOpened);
    }
  };

  return (
    <div
      onClick={openClickByTrigger}
      className={classnames(
        styles.container,
        isOpened && styles.opened,
        isDisabled && styles.disabled,
      )}
    >
      <div className={styles.activeFilters}>
        {selectedLabels.map((value, index) => {
          if (index > MAX_COUNT_TO_VIEW) {
            return null;
          }

          const text = index === MAX_COUNT_TO_VIEW ? `+${counter}` : value;

          return (
            <div
              key={value}
              className={classnames(
                styles.activeFilter,
                isSingleItem && styles.singleItem,
              )}
            >
              {text}
            </div>
          );
        })}
      </div>
      <ActionButton
        disabled={isDisabled}
        dataTestId="removeFilter"
        onClick={() => removeFilter(filterId)}
        actionType={ButtonType.Close}
      />
    </div>
  );
};
