import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { SportsBetModal } from '../components/SportsBetModal';
import { SportsBetData } from '../queries/generated/SportsBetData';

interface Result<T> {
  openModal: (arg: T, initiatorId: string) => void;
}

export const useOpenSportsBetModal = <T extends object>(): Result<T> => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const openModal = useCallback(
    (sportsBet: T, initiatorId: string) => {
      const { externalId, id } = sportsBet as SportsBetData;

      modalDispatch({
        type: ModalActions.Open,
        payload: {
          title: 'Ставка',
          subTitle: externalId,
          content: <SportsBetModal id={id} />,
          initiatorId,
        },
      });
    },
    [modalDispatch],
  );

  return {
    openModal,
  };
};
