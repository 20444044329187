import {
  BalanceType,
  CompanyAffiliateApplicationStatus,
  CompanyApplicationStatus,
  DepositStatus,
  GameActionType,
  Gender,
  PartnerApplicationStatus,
  PaymentStatus,
  PromoCodeUsageType,
  ReferralStatus,
  RewardStatus,
  SignInRestrictionStatus,
  SportsBetActionType,
  SportsBetStatus,
  SportsBetType,
  WithdrawalStatus,
} from 'generatedGraphql';
import {
  PlayerRank,
  PaymentProcessor,
  PaymentMethodName,
  DepositPaymentMethodName,
  PaymentProvider,
  Currency,
  RewardTariffType,
  ContactForCommunicationType,
  MoneyTransferType,
  OwnerTypeName,
} from 'commonTypes';

export const defaultCurrency = Currency.Rub;

export const nbsp = '\u00A0';

export const enDash = '\u2013';
export const twoEmDash = '\u2E3A';

export const PLAYER_HEADER_HEIGHT = 336;
export const SEARCH_INPUT_HEIGHT = 80;

export const SCROLL_LOCKED_CLASSNAME = 'scrollLocked';

export const PLAYERS_SEARCH_PLACEHOLDER = 'Поиск пользователя';
export const PLAYERS_SEARCH_INPUT = 'playersSearch';

export const SEARCH_COUNT_PER_PAGE = 10;

export const playerPaymentNameMap: { [key: string]: string } = {
  [DepositPaymentMethodName.BankCardForm]: 'Банковская карта (страница оплаты)',
  [PaymentMethodName.BankCard]: 'Банковская карта (шлюз)',
  [PaymentMethodName.Bitcoin]: 'Bitcoin',
  [PaymentMethodName.Binance]: 'Binance',
  [PaymentMethodName.BloomzedWallet]: 'Bloomzed Wallet',
  [PaymentMethodName.Boleto]: 'Boleto',
  [PaymentMethodName.Cepbank]: 'CepBank',
  [PaymentMethodName.Eparatr]: 'Eparatr',
  [PaymentMethodName.Ethereum]: 'Ethereum',
  [PaymentMethodName.Jeton]: 'Jeton',
  [PaymentMethodName.Litecoin]: 'Litecoin',
  [PaymentMethodName.Loterica]: 'Loterica',
  [PaymentMethodName.Monetix]: 'Monetix',
  [PaymentMethodName.MuchBetter]: 'MuchBetter',
  [PaymentMethodName.Neteller]: 'Neteller',
  [PaymentMethodName.OnlineBrazilBanks]: 'Online Brazil Banks',
  [PaymentMethodName.Piastrix]: 'Piastrix',
  [PaymentMethodName.Pix]: 'PIX',
  [PaymentMethodName.Qiwi]: 'QIWI',
  [PaymentMethodName.RapidTransfer]: 'Rapid Transfer',
  [PaymentMethodName.Skrill]: 'Skrill',
  [PaymentMethodName.Tether]: 'Tether ERC-20',
  [PaymentMethodName.TetherTRC20]: 'Tether TRC-20',
  [PaymentMethodName.Tron]: 'TRON',
  [PaymentMethodName.Webmoney]: 'Webmoney',
  [PaymentMethodName.YandexMoney]: 'Яндекс.Деньги',
};

export const paymentStatusMap: { [key in PaymentStatus]: string } = {
  [PaymentStatus.Accepted]: 'Выполнен (вручную)',
  [PaymentStatus.PartiallyAccepted]: 'Частично выплачен (вручную)',
  [PaymentStatus.Complete]: 'Выполнен',
  [PaymentStatus.PartiallyPaid]: 'Частично выплачен',
  [PaymentStatus.Declined]: 'Отклонён',
  [PaymentStatus.PresumablyFailed]: 'Предположительно ошибка',
  [PaymentStatus.Failed]: 'Ошибка',
  [PaymentStatus.InProgress]: 'В\u00a0обработке',
  [PaymentStatus.Pending]: 'В\u00a0ожидании',
  [PaymentStatus.Awaiting]: 'В\u00a0ожидании обработки',
  [PaymentStatus.Unknown]: 'Unknown',
};

export const paymentProcessorMap: { [key: string]: string } = {
  [PaymentProcessor.BTech]: 'B-tech',
  [PaymentProcessor.Cjmm]: 'CJMM',
  [PaymentProcessor.CoinsPaid]: 'CoinsPaid',
  [PaymentProcessor.Ecommpay]: 'Epay',
  [PaymentProcessor.EcommpayLatAm]: 'Epay Latin America',
  [PaymentProcessor.GateExpress]: 'Gate Express',
  [PaymentProcessor.Interkassa]: 'Interkassa',
  [PaymentProcessor.Jeton]: 'Jeton',
  [PaymentProcessor.Maldopay]: 'Maldopay',
  [PaymentProcessor.Mpay]: 'Mpay',
  [PaymentProcessor.MuchBetter]: 'MuchBetter',
  [PaymentProcessor.Neteller]: 'Neteller',
  [PaymentProcessor.Paycos]: 'Paycos',
  [PaymentProcessor.PaycosCard]: 'Paycos Card',
  [PaymentProcessor.PaycosCode]: 'Paycos Code',
  [PaymentProcessor.PaycosWebCode]: 'Paycos (Web Code)',
  [PaymentProcessor.Piastrix]: 'Piastrix',
  [PaymentProcessor.PiastrixTransfer]: 'Piastrix Transfer',
  [PaymentProcessor.Qiwi]: 'Qiwi',
  [PaymentProcessor.QPayments]: 'QPayments',
  [PaymentProcessor.QpaymentsCardRu]: 'Qpayments Card RU',
  [PaymentProcessor.QpaymentsCardWorld]: 'Qpayments Card World',
  [PaymentProcessor.RapidTransferSkrill]: 'Skrill (Rapid Transfer)',
  [PaymentProcessor.SkrillUsdEea]: 'Skrill USD EEA',
  [PaymentProcessor.SkrillUsdRow]: 'Skrill USD Row',
  [PaymentProcessor.Tossacoin]: 'Tossacoin',
  [PaymentProcessor.Unlimint]: 'Unlimint',
  [PaymentProcessor.XchangeFundry]: 'Xchange Fundry',
};

export const genderMap: { [key in Gender]: string } = {
  [Gender.Female]: 'Женский',
  [Gender.Male]: 'Мужской',
};

export const playerRankMap: { [key: string]: string } = {
  [PlayerRank.Noob]: 'Новичок',
  [PlayerRank.Gambler]: 'Игрок',
  [PlayerRank.Bronze]: 'Бронзовый',
  [PlayerRank.Silver]: 'Серебряный',
  [PlayerRank.Gold]: 'Золотой',
  [PlayerRank.Platinum]: 'Платиновый',
};

export const paymentProviderMap: { [key in PaymentProvider]: string } = {
  [PaymentProvider.ApplePayCoreEcommpayPaymentPage]: 'Epay (Apple Pay)',
  [PaymentProvider.BadBank]: 'BadBank',
  [PaymentProvider.BinanceBTech]: 'B-tech (Binance)',
  [PaymentProvider.BinanceCoinsPaidInvoiceless]:
    'CoinsPaid Invoiceless (Binance)',
  [PaymentProvider.BinanceERC20BTech]: 'B-tech (Binance ERC-20)',
  [PaymentProvider.BitcoinBTech]: 'B-tech (Bitcoin)',
  [PaymentProvider.BitcoinCoinsPaid]: 'CoinsPaid (Bitcoin)',
  [PaymentProvider.BitcoinCoinsPaidInvoiceless]:
    'CoinsPaid Invoiceless (Bitcoin)',
  [PaymentProvider.BitcoinPiastrix]: 'Piastrix (Bitcoin)',
  [PaymentProvider.BloomzedWalletEcommpayPaymentPage]: 'Epay (Bloomzed Wallet)',
  [PaymentProvider.BoletoEcommpayPaymentPage]: 'Epay (Boleto)',
  [PaymentProvider.CardEcommpayPaymentPage]: 'Epay (страница оплаты)',
  [PaymentProvider.CardPartnerEcommpayPaymentPage]:
    'Epay (карты через\u00a0партнёра)',
  [PaymentProvider.CardPaycosPaymentPage]: 'Paycos (страница оплаты)',
  [PaymentProvider.CardPaycosWebPaymentPage]: 'Paycos (web страница оплаты)',
  [PaymentProvider.CardPiastrix]: 'Piastrix (карты)',
  [PaymentProvider.CardQpaymentsGate]: 'Qpayments карты (шлюз)',
  [PaymentProvider.CepbankEcommpayPaymentPage]: 'Epay (CepBank)',
  [PaymentProvider.ChargeBack]: 'Chargeback',
  [PaymentProvider.Cjmm]: 'CJMM',
  [PaymentProvider.CodePaycosPaymentPage]: 'Paycos (code)',
  [PaymentProvider.EcommpayGate]: 'Epay (шлюз)',
  [PaymentProvider.EparatrMaldopayPaymentPage]: 'MaldoPay (Papara)',
  [PaymentProvider.EthereumBTech]: 'B-tech (Ethereum)',
  [PaymentProvider.EthereumCoinsPaid]: 'CoinsPaid (Ethereum)',
  [PaymentProvider.EthereumCoinsPaidInvoiceless]:
    'CoinsPaid Invoiceless (Ethereum)',
  [PaymentProvider.EthereumPiastrix]: 'Piastrix (Ethereum)',
  [PaymentProvider.FasterPaymentsSystemECommpayPaymentPage]: 'Epay (СБП)',
  [PaymentProvider.FasterPaymentsSystemPiastrix]: 'Piastrix (СБП)',
  [PaymentProvider.GateExpressPaymentPage]: 'Gate Express (страница оплаты)',
  [PaymentProvider.GateExpress]: 'Gate Express (шлюз)',
  [PaymentProvider.HizliqrEcommpayPaymentPage]: 'Epay (Hizli QR)',
  [PaymentProvider.Interkassa]: 'Interkassa (шлюз)',
  [PaymentProvider.Jeton]: 'Jeton',
  [PaymentProvider.Kassify]: 'Kassify',
  [PaymentProvider.LiteCoinBTech]: 'B-tech (Litecoin)',
  [PaymentProvider.LiteCoinCoinsPaid]: 'CoinsPaid (Litecoin)',
  [PaymentProvider.LiteCoinCoinsPaidInvoiceless]:
    'CoinsPaid Invoiceless (Litecoin)',
  [PaymentProvider.LotericaEcommpayPaymentPage]: 'Epay (Loterica)',
  [PaymentProvider.McQpaymentsGate]: 'Qpayments мобильный платёж',
  [PaymentProvider.MexicoOnlineBankingEcommpayPaymentPage]:
    'Epay (Mexico Online Banking)',
  [PaymentProvider.MkarekodMaldopayPaymentPage]: 'MaldoPay (Mkarekod)',
  [PaymentProvider.MobileEcommpayPaymentPage]: 'Epay (Мобильный платёж)',
  [PaymentProvider.MonetixWalletEcommpayPaymentPage]: 'Epay (Monetix)',
  [PaymentProvider.MpayGate]: 'Mpay (шлюз)',
  [PaymentProvider.MpayPaymentPage]: 'Mpay (страница оплаты)',
  [PaymentProvider.MuchBetter]: 'MuchBetter',
  [PaymentProvider.Neteller]: 'Neteller',
  [PaymentProvider.OnlineBrazilBanksEcommpayPaymentPage]:
    'Epay (Brazil Online Banking)',
  [PaymentProvider.OxxoEcommpayPaymentPage]: 'Epay (Oxxo)',
  [PaymentProvider.P2pPaycosPaymentPage]: 'Paycos (p2p)',
  [PaymentProvider.PaycosGate]: 'Paycos (шлюз)',
  [PaymentProvider.PaycosWebGate]: 'Paycos (web gate)',
  [PaymentProvider.Piastrix]: 'Piastrix',
  [PaymentProvider.PixECommpayPaymentPage]: 'Epay (PIX)',
  [PaymentProvider.QiwiEcommpayPaymentPage]: 'Epay (Qiwi)',
  [PaymentProvider.QiwiKzEcommpayPaymentPage]: 'Epay (Qiwi KZT)',
  [PaymentProvider.QiwiPiastrix]: 'Piastrix (Qiwi)',
  [PaymentProvider.Qiwi]: 'Qiwi',
  [PaymentProvider.QpaymentsForm]: 'Qpayments (форма)',
  [PaymentProvider.RapidTransferSkrill]: 'Skrill (Rapid Transfer)',
  [PaymentProvider.Skrill]: 'Skrill',
  [PaymentProvider.TetherBTech]: 'B-tech (Tether ERC-20)',
  [PaymentProvider.TetherCoinsPaid]: 'CoinsPaid (Tether ERC-20)',
  [PaymentProvider.TetherCoinsPaidInvoiceless]:
    'CoinsPaid Invoiceless (Tether ERC-20)',
  [PaymentProvider.TetherPiastrix]: 'Piastrix (Tether)',
  //
  [PaymentProvider.TetherTRC20BTech]: 'B-tech (Tether TRC-20)',
  [PaymentProvider.TetherTRC20CoinsPaid]: 'CoinsPaid (Tether TRC-20)',
  [PaymentProvider.TetherTRC20CoinsPaidInvoiceless]:
    'CoinsPaid Invoiceless (Tether TRC-20)',
  [PaymentProvider.Tossacoin]: 'Toss-a-coin',
  [PaymentProvider.TronBTech]: 'B-tech (TRON)',
  [PaymentProvider.TronCoinsPaidInvoiceless]: 'CoinsPaid Invoiceless (Tron)',
  [PaymentProvider.UnlimintGate]: 'Unlimint (шлюз)',
  [PaymentProvider.WebmoneyLightEcommpayPaymentPage]: 'Epay (Webmoney)',
  [PaymentProvider.XchangeGate]: 'Xchange',
  [PaymentProvider.YandexMoneyPiastrix]: 'Piastrix (Яндекс.Деньги)',
};

export const currencySignMap: { [key in Currency]: string } = {
  [Currency.Bgn]: 'BGN',
  [Currency.Brl]: 'BRL',
  [Currency.Eur]: 'EUR',
  [Currency.Huf]: 'HUF',
  [Currency.Kzt]: 'KZT',
  [Currency.Mxn]: 'MXN',
  [Currency.Pln]: 'PLN',
  [Currency.Rub]: 'RUB',
  [Currency.Sek]: 'SEK',
  [Currency.Try]: 'TRY',
  [Currency.Uah]: 'UAH',
  [Currency.Usd]: 'USD',
  [Currency.Aud]: 'AUD',
  [Currency.Byn]: 'BYN',
  [Currency.Cad]: 'CAD',
  [Currency.Jpy]: 'JPY',
  [Currency.Krw]: 'KRW',
  [Currency.Nok]: 'NOK',
  [Currency.Ron]: 'RON',
  [Currency.Uzs]: 'UZS',
  [Currency.Azn]: 'AZN',
  [Currency.Xxx]: 'XXX',
};

export const signInRestrictionStatusMap: {
  [key in SignInRestrictionStatus]: string;
} = {
  [SignInRestrictionStatus.Active]: 'Активна',
  [SignInRestrictionStatus.Deactivated]: 'Снята',
  [SignInRestrictionStatus.Expired]: 'Истекла',
  [SignInRestrictionStatus.Unknown]: 'Unknown',
};

export const sportsBetStatusMap: {
  [key in SportsBetStatus]: string;
} = {
  [SportsBetStatus.Pending]: `В${nbsp}ожидании`,
  [SportsBetStatus.WaitingForRegulator]: `В${nbsp}ожидании регулятора`,
  [SportsBetStatus.Waiting]: 'Ожидает обработки',
  [SportsBetStatus.Rejected]: 'Отклонена',
  [SportsBetStatus.Open]: 'Открыта',
  [SportsBetStatus.PartialCashout]: 'Частичный кешаут',
  [SportsBetStatus.Cashout]: 'Кешаут',
  [SportsBetStatus.Lost]: 'Проиграна',
  [SportsBetStatus.Win]: 'Выиграна',
  [SportsBetStatus.Void]: 'Аннулирована',
};

export const sportsBetTypeMap: {
  [key in SportsBetType]: string;
} = {
  [SportsBetType.Single]: `Одинар`,
  [SportsBetType.Express]: `Экспресс`,
  [SportsBetType.System]: 'Система',
};

export const partnerApplicationStatusMap: {
  [key in PartnerApplicationStatus]: string;
} = {
  [PartnerApplicationStatus.Active]: 'Активна',
  [PartnerApplicationStatus.Declined]: 'Отклонена',
  [PartnerApplicationStatus.Pending]: 'В\u00a0ожидании',
  [PartnerApplicationStatus.InProgress]: 'В\u00a0обработке',
  [PartnerApplicationStatus.Unknown]: 'Unknown',
};

export const rewardTariffMap: {
  [key in RewardTariffType]: string;
} = {
  [RewardTariffType.Cpa]: 'CPA',
  [RewardTariffType.RevShare]: 'RevShare',
};

export const depositStatusMap: { [key in DepositStatus]: string } = {
  [DepositStatus.Accepted]: 'Исполнен',
  [DepositStatus.Declined]: 'Отклонён',
  [DepositStatus.PresumablyDeclined]: 'Предположительно отклонён',
  [DepositStatus.Expired]: 'Истёк',
  [DepositStatus.InProgress]: `В${nbsp}обработке`,
  [DepositStatus.Pending]: `В${nbsp}ожидании`,
  [DepositStatus.Unknown]: `Unknown`,
};

export const contactForCommunicationTypeMap: {
  [key in ContactForCommunicationType]: string;
} = {
  [ContactForCommunicationType.Skype]: 'Skype',
  [ContactForCommunicationType.Whatsapp]: 'Whatsapp',
  [ContactForCommunicationType.Telegram]: 'Telegram',
  [ContactForCommunicationType.Other]: 'Другое',
};

export const moneyTransferTypeMap: { [key in MoneyTransferType]: string } = {
  [MoneyTransferType.Incoming]: 'Входящий',
  [MoneyTransferType.Outgoing]: 'Исходящий',
};

export const companyAffiliateApplicationStatusMap: {
  [key in CompanyAffiliateApplicationStatus]: string;
} = {
  [CompanyAffiliateApplicationStatus.Active]: 'Одобрена',
  [CompanyAffiliateApplicationStatus.Declined]: 'Отклонена',
  [CompanyAffiliateApplicationStatus.Pending]: 'В\u00a0ожидании',
  [CompanyAffiliateApplicationStatus.InProgress]: 'В\u00a0обработке',
  [CompanyAffiliateApplicationStatus.Unknown]: 'Unknown',
};

export const ownerTypeNameMap: {
  [key in OwnerTypeName]: string;
} = {
  [OwnerTypeName.Partner]: 'Партнёр',
  [OwnerTypeName.Company]: 'Компания',
  [OwnerTypeName.CompanyAffiliate]: 'Партнёр компании',
};

export const promoCodeUsageTypeMap: {
  [key in PromoCodeUsageType]: string;
} = {
  [PromoCodeUsageType.AllPlayers]: `Для${nbsp}всех игроков`,
  [PromoCodeUsageType.NewPlayersOnly]: `Только для${nbsp}новых игроков`,
  [PromoCodeUsageType.AffiliatedPlayersOnly]: `Только для${nbsp}игроков партнера/компании`,
  [PromoCodeUsageType.Unknown]: 'Unknown',
};

export const withdrawalStatusMap: { [key in WithdrawalStatus]: string } = {
  [WithdrawalStatus.Automatic]: 'В\u00a0обработке: Автоматически',
  [WithdrawalStatus.Accepted]: 'Исполнен',
  [WithdrawalStatus.Canceled]: 'Отменено',
  [WithdrawalStatus.Declined]: 'Отклонено',
  [WithdrawalStatus.InterruptedByCancel]: 'Отменено (частично)',
  [WithdrawalStatus.InterruptedByDecline]: 'Отклонено (частично)',
  [WithdrawalStatus.InProgress]: 'В\u00a0обработке',
  [WithdrawalStatus.Pending]: 'В\u00a0ожидании',
  [WithdrawalStatus.Unknown]: 'Unknown',
};

export const rewardStatusMap: {
  [key in RewardStatus]: string;
} = {
  [RewardStatus.Accepted]: 'Подтверждено',
  [RewardStatus.Declined]: 'Отклонено',
  [RewardStatus.Pending]: `В${nbsp}ожидании`,
};

export const referralStatusMap: {
  [key in ReferralStatus]: string;
} = {
  [ReferralStatus.Approved]: 'Подтверждён',
  [ReferralStatus.Rejected]: 'Отклонен',
  [ReferralStatus.RevShare]: `В${nbsp}RevShare`,
  [ReferralStatus.Qualified]: 'Квалифицирован',
  [ReferralStatus.Canceled]: 'Неквалифицирован',
  [ReferralStatus.Paid]: 'Оплачен',
  [ReferralStatus.Unpaid]: `Без${nbsp}оплаты`,
  [ReferralStatus.Hold]: `В${nbsp}ожидании`,
};

export const companyApplicationStatusMap: {
  [key in CompanyApplicationStatus]: string;
} = {
  [CompanyApplicationStatus.Active]: 'Активна',
  [CompanyApplicationStatus.Declined]: 'Отклонена',
  [CompanyApplicationStatus.Pending]: `В${nbsp}ожидании`,
  [CompanyApplicationStatus.InProgress]: `В${nbsp}обработке`,
  [CompanyApplicationStatus.Unknown]: 'Unknown',
};

export const gameActionTypeMap: {
  [key in GameActionType]: string;
} = {
  [GameActionType.Bet]: 'Ставка',
  [GameActionType.CancelBet]: 'Отмена ставки',
  [GameActionType.CancelWin]: 'Отмена выигрыша',
  [GameActionType.Refund]: 'Возврат средств',
  [GameActionType.Tombstone]: 'Tombstone',
  [GameActionType.Win]: 'Выигрыш',
};

export enum StatisticDatePeriod {
  From = 'statisticPeriodFrom',
  To = 'statisticPeriodTo',
}

export enum StatisticOrdering {
  OrderBy = 'orderBy',
  Direction = 'direction',
}

export const STATISTIC_CURRENCY = 'currency';

export const DEFAULT_CURRENCY = Currency.Usd;

export const STATISTIC_QUERY_PARAMS = [
  StatisticDatePeriod.From,
  StatisticDatePeriod.To,
  STATISTIC_CURRENCY,
];

export const mapSportsBetActionTypeToTitle: {
  [key in SportsBetActionType]: string;
} = {
  [SportsBetActionType.CashoutBet]: 'CashoutBet',
  [SportsBetActionType.PartialCashoutBet]: 'PartialCashoutBet',
  [SportsBetActionType.PlaceBet]: `PlaceBet`,
  [SportsBetActionType.RefundBet]: 'RefundBet',
  [SportsBetActionType.AwardWinnings]: 'AwardWinnings',
  [SportsBetActionType.LossSignal]: 'LossSignal',
  [SportsBetActionType.NewCredit]: `NewCredit`,
  [SportsBetActionType.NewDebit]: `NewDebit`,
  [SportsBetActionType.StakeDecrease]: `StakeDecrease`,
};

export const mapBalanceTypeToTitle: {
  [key in BalanceType]: string;
} = {
  [BalanceType.Money]: 'Денежный',
  [BalanceType.Freespin]: 'Фриспин',
  [BalanceType.Bonus]: `Бонусный`,
};
