import { Route } from 'src/router/routes.const';
import { LoginHistoryTypeName } from 'commonTypes';
import { LoginHistoryData } from '../../../queries/generated/LoginHistoryData';

export const getRouteByTypeName = (actor: LoginHistoryData['actor']) => {
  switch (actor.__typename) {
    case LoginHistoryTypeName.Company:
      return `${Route.COMPANIES}/${actor.id}`;
    case LoginHistoryTypeName.Partner:
      return `${Route.PARTNERS}/${actor.id}`;
    case LoginHistoryTypeName.Player:
      return `${Route.PLAYERS}/${actor.id}`;

    default:
      return Route.LOGIN_HISTORY;
  }
};
