export enum ColumnId {
  PlayerId = 'player.id',
  Total = 'total',
  Balance = 'balanceAfterAction',
  BalanceType = 'balanceType',
  Currency = 'currency',
  Action = 'type',
  Provider = 'providerGame.id',
  Category = 'providerGame.category',
  RoundId = 'roundId',
  CreatedAt = 'createdAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.PlayerId]: 'Игрок',
  [ColumnId.Total]: 'Сумма',
  [ColumnId.Balance]: 'Баланс',
  [ColumnId.BalanceType]: 'Тип баланса',
  [ColumnId.Currency]: 'Валюта',
  [ColumnId.Action]: 'Действие',
  [ColumnId.Provider]: 'Игра, провайдер',
  [ColumnId.RoundId]: 'ID раунда',
  [ColumnId.Category]: 'Категория',
  [ColumnId.CreatedAt]: 'Создан',
};

export const columnsIds = [
  ColumnId.PlayerId,
  ColumnId.Total,
  ColumnId.Balance,
  ColumnId.BalanceType,
  ColumnId.Currency,
  ColumnId.Action,
  ColumnId.Provider,
  ColumnId.Category,
  ColumnId.RoundId,
  ColumnId.CreatedAt,
];
