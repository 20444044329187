import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext, useModalManageData } from 'ui/Modal';
import { addHashTagToId } from 'utils/addHashTagToId';
import { useGetAffiliateReferralLinkItemDetails } from '../queries/generated/GetAffiliateReferralLinkItemDetails';
import { AffiliateMediaItemsSteps } from '../../AffiliatePromoCodeItem/const';

interface Params {
  referralLinkId: string;
  companyId: string;
  affiliateId: string;
}

export const useMediaItemReferralLinkModal = ({
  referralLinkId,
  affiliateId,
  companyId,
}: Params) => {
  const { state, dispatch: modalDispatch } = useContext(ModalContext);
  const { data, loading, error } = useGetAffiliateReferralLinkItemDetails({
    variables: {
      referralLinkId,
      affiliateId,
      companyId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'media-item-errors' },
    hasData: !!data,
  });

  useEffect(() => {
    if (state.step === AffiliateMediaItemsSteps.ReferralLinkDetails) {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: addHashTagToId(referralLinkId),
          subTitle: 'Рефссылка',
        },
      });

      return;
    }

    modalDispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        title: 'Редактировать рефссылку',
        subTitle: addHashTagToId(referralLinkId),
      },
    });
  }, [referralLinkId, modalDispatch, state.step]);

  return {
    referralLinkMediaItem: data?.company.affiliate.referralLinkMediaItem,
    step: state.step,
  };
};
