import { useContext } from 'react';
import { ModalActions, ModalContext, useModalManageData } from 'ui/Modal';
import { toast } from 'react-toastify';
import { EditMediaItemReferralLinkInitialValues } from '../types';
import { useUpdateCompanyAffiliateReferralLinkMediaItem } from '../../../queries/generated/UpdateCompanyAffiliateReferralLinkMediaItem';

interface Params {
  referralLinkId: string;
  isCpaTariff: boolean;
}

export const useUpdateReferralLinkMediaItem = ({
  referralLinkId,
  isCpaTariff,
}: Params) => {
  const [updateReferralLinkMediaItem, { error, loading, data }] =
    useUpdateCompanyAffiliateReferralLinkMediaItem();

  const { dispatch } = useContext(ModalContext);

  const handleError = () => {
    toast.error('Параметры рефссылки не изменены');
  };

  const handleComplete = () => {
    dispatch({ type: ModalActions.Close });
    toast.success('Вы изменили параметры рефссылки');
  };

  const onUpdate = async (values: EditMediaItemReferralLinkInitialValues) => {
    const mediaItemRewardTariff = isCpaTariff
      ? {
          cpaRewardTariff: {
            id: values.cpaTariffId,
            fallbackRewardTariffId: values.revShareTariffId,
          },
        }
      : { revShareRewardTariff: { id: values.revShareTariffId } };

    await updateReferralLinkMediaItem({
      variables: {
        input: {
          id: referralLinkId,
          mediaCampaignId: values.mediaCampaignId,
          name: values.name,
          mediaItemRewardTariff,
        },
      },
      onError: handleError,
      onCompleted: handleComplete,
    });
  };

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'edit-company-affiliate-media-item-error' },
    hasData: !!data,
  });

  return { onUpdate, loading, error };
};
