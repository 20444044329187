export const enum FieldName {
  Name = 'name',
  CPARewardTariffId = 'cpaRewardTariffId',
  FallbackTariffId = 'fallbackTariffId',
  RevShareRewardTariffId = 'revShareRewardTariffId',
  MediaCampaignId = 'mediaCampaignId',
}

export const mapFieldNameToTitle = {
  [FieldName.MediaCampaignId]: 'Медиакампания',
  [FieldName.Name]: 'Название медиаэлемента',
  [FieldName.CPARewardTariffId]: 'Партнёрский тариф',
  [FieldName.FallbackTariffId]: 'Резервный тариф',
  [FieldName.RevShareRewardTariffId]: 'Партнёрский тариф',
};
