import { Form, Formik } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { SingleSelectList } from 'ui/Filters/components/SingleSelectFilter/components/SingleSelectList';
import Error from 'ui/Error';
import Input, { InputSize } from 'ui/Input';
import styles from './SingleSelectFilter.module.scss';
import { getHandleSubmit } from './helpers';
import { FilterSelectOptionWithRightLabel } from '../../types';
import { ConfirmationButtons } from '../ConfirmationButtons';
import { getResetFilters } from '../../helpers';

interface Props<T> {
  options: Array<FilterSelectOptionWithRightLabel>;
  setFilter: (filterId: T, value: Array<string>) => void;
  filterId: T;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  fetchMoreConfig?: {
    fetchMore?: () => void;
    hasNextPage?: boolean;
  };
  error?: ApolloError;
  loading?: boolean;
  onSearchInList?: (value: string) => void;
  placeholder?: string;
  emptyListText?: string;
}

export const SingleSelectFilter = <T extends string>({
  options,
  setFilter,
  filterId,
  setIsOpened,
  fetchMoreConfig,
  error,
  loading,
  onSearchInList,
  placeholder = 'Поиск',
  emptyListText = 'Ничего не найдено',
}: Props<T>) => {
  const SINGLE_SELECT_SEARCH_FIELD_NAME = `${filterId}_single_select_search`;

  const [searchQuery, setSearchQuery] = useState('');
  const [searchParams] = useSearchParams();
  const param = searchParams.get(filterId) || '';

  const initialValues = { [filterId]: param };
  const hasCheckedItem = Boolean(param);

  const handleSearchChange = (searchValue: string) => {
    setSearchQuery(searchValue);
    if (onSearchInList) {
      onSearchInList(searchValue);
    }
  };

  const handleSubmit = getHandleSubmit({
    setFilter,
    filterId,
    setIsOpened,
  });
  const resetFilter = getResetFilters({
    setFilter,
    filterId,
    setIsOpened,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ dirty }) => (
        <Form className={styles.dropdown}>
          {error && <Error error={error} />}
          {onSearchInList && (
            <Input
              value={searchQuery}
              name={SINGLE_SELECT_SEARCH_FIELD_NAME}
              placeholder={placeholder}
              onChange={handleSearchChange}
              wrapperClassName={styles.searchInputWrapper}
              inputSize={InputSize.Small}
              autoFocus
            />
          )}
          <SingleSelectList
            options={options}
            loading={loading}
            emptyListText={emptyListText}
            fetchMoreConfig={fetchMoreConfig}
            filterId={filterId}
          />
          <ConfirmationButtons
            isResetDisabled={!hasCheckedItem}
            isSubmitDisabled={!dirty}
            onResetFilter={resetFilter}
          />
        </Form>
      )}
    </Formik>
  );
};
