const MAX_COUNT_TO_VIEW = 4;

export const getCoefficients = (coefficients: Array<number>): string => {
  const count = coefficients.length - MAX_COUNT_TO_VIEW;
  const filteredCoefficient: Array<number | string> = [];

  coefficients.forEach((value, index) => {
    if (index > MAX_COUNT_TO_VIEW) {
      return;
    }

    const coefficient = index === MAX_COUNT_TO_VIEW ? `+${count}` : value;

    filteredCoefficient.push(coefficient);
  });

  return filteredCoefficient.join(', ');
};
