import React, { FocusEvent } from 'react';
import { PatternFormat } from 'react-number-format';
import styles from './InputDate.module.scss';

interface Props {
  value: string;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onChange: (value: string) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
}

const datePattern = /^\d{2}\.\d{2}\.\d{4}$/;

export const InputDate: React.FC<Props> = ({
  value,
  onChange,
  disabled,
  autoFocus,
  placeholder,
  minDate,
  maxDate,
}) => {
  const handleChange = (event: FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.trim();

    const isDateLike = datePattern.test(inputValue);

    if (isDateLike) {
      let [dd, mm, yyyy] = inputValue.split('.').map(Number);

      mm = Math.min(12, Math.max(1, mm));
      yyyy = Math.max(1900, Math.min(9999, yyyy));

      const daysInMonth = new Date(yyyy, mm, 0).getDate();

      dd = Math.min(daysInMonth, Math.max(1, dd));

      const date = new Date(yyyy, mm - 1, dd);

      if (minDate && date < minDate) {
        onChange(
          `${minDate.getDate().toString().padStart(2, '0')}.${(minDate.getMonth() + 1).toString().padStart(2, '0')}.${minDate.getFullYear()}`,
        );

        return;
      }

      if (maxDate && date > maxDate) {
        onChange(
          `${maxDate.getDate().toString().padStart(2, '0')}.${(maxDate.getMonth() + 1).toString().padStart(2, '0')}.${maxDate.getFullYear()}`,
        );

        return;
      }

      onChange(
        `${dd.toString().padStart(2, '0')}.${mm.toString().padStart(2, '0')}.${yyyy}`,
      );
    } else {
      onChange(inputValue);
    }
  };

  return (
    <PatternFormat
      className={styles.inputDate}
      format="##.##.####"
      mask="_"
      autoFocus={autoFocus}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};
