import { DataCard } from 'commonComponents/DataCard';
import { sportsBetStatusMap, sportsBetTypeMap } from 'src/const';
import { formatSumWithCurrency } from 'utils/accounting';
import { DateFormat, formatDate } from 'utils/formatDate';
import { getNumberSign } from 'utils/getNumberSign';
import { SportsBetType } from 'generatedGraphql';
import { FC } from 'react';
import { getCoefficients } from '../../helpers/getCoefficients';
import { getStatusIcon } from '../../../../../../helpers';
import { GetSportsBetInfo } from '../../../../../../queries/generated/GetSportsBetInfo';

interface Props {
  sportsBet?: GetSportsBetInfo['sportsBet'];
}

export const InfoBlock: FC<Props> = ({ sportsBet }) => {
  if (!sportsBet) {
    return null;
  }

  const {
    player,
    status,
    type,
    betSum,
    resultSum,
    coefficients,
    createdAt,
    updatedAt,
    possibleWinSum,
    combination,
  } = sportsBet;

  const playerEmail = player.email;
  const resultSumWithSign = `${getNumberSign(resultSum.amount)}${formatSumWithCurrency(resultSum.amount, resultSum.currency)}`;

  const isShowCombination = type === SportsBetType.System;

  return (
    <DataCard
      statusConfig={
        status && {
          status: sportsBetStatusMap[status],
          iconConfig: getStatusIcon(status),
        }
      }
      rows={[
        {
          title: 'Имя игрока',
          value: player.name,
        },
        {
          title: playerEmail ? 'Почта игрока' : 'Телефон игрока',
          value: playerEmail || player.phone,
        },
        {
          title: 'Тип',
          value: sportsBetTypeMap[type],
        },
        {
          title: 'Сумма ставки',
          value: formatSumWithCurrency(betSum.amount, betSum.currency),
        },
        {
          title: 'Результат',
          value: resultSumWithSign,
        },
        isShowCombination && {
          title: 'Комбинация',
          value: combination,
        },
        {
          title: 'Возможный выигрыш',
          value: formatSumWithCurrency(
            possibleWinSum.amount,
            possibleWinSum.currency,
          ),
        },
        ...getCoefficients(coefficients),
        {
          title: 'Создана',
          value: formatDate(createdAt, DateFormat.FullDateWithSeconds),
        },
        {
          title: 'Обновлена',
          value: formatDate(updatedAt, DateFormat.FullDateWithSeconds),
        },
      ]}
    />
  );
};
