import { FC, useState } from 'react';
import { MultiSelectFilter } from 'ui/Filters';
import { FilterComponentProps } from 'ui/Filters/types';
import { GameActionsFilter } from '../../const';
import { getFilterCurrencyOptions } from './helpers/getFilterCurrencyOptions';

const currencyOptions = getFilterCurrencyOptions();

export const GameActionsCurrencyFilter: FC<
  FilterComponentProps<GameActionsFilter>
> = ({ setFilter, filterId, setIsOpened }) => {
  const [options, setOptions] = useState(currencyOptions);

  const onSearchInList = (value: string) => {
    const filteredOptions = currencyOptions.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase()),
    );

    setOptions(filteredOptions);
  };

  return (
    <MultiSelectFilter
      options={options}
      setFilter={setFilter}
      filterId={filterId}
      setIsOpened={setIsOpened}
      onSearchInList={onSearchInList}
    />
  );
};
