import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';
import { FocusEvent, useCallback } from 'react';
import { useGetReferralLinkMediaItemByCodeLazyQuery } from 'src/queries/generated/GetReferralLinkMediaItemByCode';
import { FieldName } from '../../../const';
import { CreatePromoCodeValues } from '../../../types';

interface Result {
  getReferralLinkByCode: (event: FocusEvent<HTMLInputElement>) => void;
  getReferralLinkByCodeLoading: boolean;
  mediaCampaignName?: string;
}

export const useGetReferralLinkMediaItemByCode = (): Result => {
  const { setFieldValue, values, validateForm } =
    useFormikContext<CreatePromoCodeValues>();

  const [
    getReferralLinkMediaItemByCodeQuery,
    { data: mediaItemData, loading },
  ] = useGetReferralLinkMediaItemByCodeLazyQuery();

  const getReferralLinkByCode = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const referralCode = event.target.value;

      if (!referralCode) {
        return;
      }

      validateForm(values);

      getReferralLinkMediaItemByCodeQuery({
        variables: {
          code: referralCode,
        },
        onCompleted: (data) => {
          setFieldValue(
            FieldName.ReferralCodeId,
            data.referralLinkMediaItemByCode.id,
            true,
          );
          setFieldValue(
            FieldName.MediaCampaignId,
            data.referralLinkMediaItemByCode.mediaCampaign.id,
          );
          setFieldValue(
            FieldName.MediaCampaignName,
            data.referralLinkMediaItemByCode.mediaCampaign.name,
          );
          setFieldValue(
            FieldName.MediaCampaignUrl,
            data.referralLinkMediaItemByCode.mediaCampaign.url,
          );
        },
        onError: () => {
          setFieldValue(FieldName.ReferralCodeId, undefined, true);

          toast.error('Такого реферального кода нет');
        },
      });
    },
    [getReferralLinkMediaItemByCodeQuery, setFieldValue, validateForm, values],
  );

  return {
    getReferralLinkByCode,
    getReferralLinkByCodeLoading: loading,
    mediaCampaignName:
      mediaItemData?.referralLinkMediaItemByCode.mediaCampaign.name,
  };
};
