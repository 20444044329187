import { FC, useContext } from 'react';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { nbsp } from 'src/const';
import { SportsBetsTable } from './components/SportsBetsTable';
import { useGetSportsBets } from './hooks/useGetSportsBets';

export const SportsBets: FC = withBlockProvider(() => {
  const { state } = useContext(BlockContext);

  const {
    sportsBets,
    loading,
    loadSportsBets,
    error,
    data,
    fetchMoreCallback,
    refetch,
    hasNextPage,
  } = useGetSportsBets();

  useBlockComponentState({
    loadData: loadSportsBets,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return (
    <Block
      title={`Ставки на${nbsp}спорт`}
      id="sportsBets"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          hidden={!state.isOpened}
          disabled={state.isLoading}
          onClick={() => refetch()}
        />
      }
      shrinkLoaderWrapper={false}
      emptyText="Ставок нет"
      isEmpty={isListEmpty(sportsBets)}
      shouldReturnToPrevPage={false}
      isPermanentOpened
    >
      {hasList(sportsBets) && <SportsBetsTable sportsBets={sportsBets} />}
    </Block>
  );
});
