import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useSearchContext } from 'src/contexts/SearchContext';
import {
  GetLoginHistoryVariables,
  useGetLoginHistoryLazyQuery,
} from '../queries/generated/GetLoginHistory';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetLoginHistory = () => {
  const { searchValue } = useSearchContext();

  const baseVariables: GetLoginHistoryVariables = {
    first: getCountByWindowHeight(cellHeight, 0),
  };

  const [variables, setVariables] = useState(baseVariables);

  useEffect(() => {
    setVariables({
      ...baseVariables,
      search: searchValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loadLoginHistory, { data, loading, error, fetchMore, refetch }] =
    useGetLoginHistoryLazyQuery();

  const loadLoginHistoryCallback = useCallback(
    () => loadLoginHistory({ variables }),
    [loadLoginHistory, variables],
  );

  const loginHistory = useMemo(
    () => data?.loginHistory?.edges.map(({ node }) => node),
    [data?.loginHistory?.edges],
  );

  const endCursor = data?.loginHistory?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  return {
    loginHistory,
    loadLoginHistory: loadLoginHistoryCallback,
    loading,
    error,
    data,
    fetchMoreCallback,
    refetch,
    hasNextPage: data?.loginHistory?.pageInfo.hasNextPage,
    handleSearch: () =>
      loadLoginHistory({
        variables: { ...variables, search: searchValue },
      }),
  };
};
