interface Params {
  limit?: number;
  activated?: number;
}

interface Return {
  isInfinity: boolean;
  endTitle: string;
  startTitle: string;
  percent: number;
}

export const getPromoCodeActivationProgressBarConfig = ({
  limit,
  activated,
}: Params): Return | null => {
  if (activated === undefined || limit === undefined) {
    return null;
  }

  const isInfinity = limit === 0;
  const endTitle = isInfinity ? '∞' : `${limit}`;
  const startTitle = `Активировано: ${activated}`;

  let percent;

  if (isInfinity) {
    percent = 100;
  } else {
    percent = (activated / limit) * 100;
  }

  return { percent, endTitle, startTitle, isInfinity };
};
