import * as SchemaTypes from '../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetReferralLinkMediaItemByCodeVariables = SchemaTypes.Exact<{
  code: SchemaTypes.Scalars['ReferralLinkCode']['input'];
}>;


export type GetReferralLinkMediaItemByCode = { __typename: 'Query', referralLinkMediaItemByCode: { __typename: 'ReferralLinkMediaItem', id: string, referralLink: string, mediaCampaign: { __typename: 'MediaCampaign', id: string, name: string, url: string } } };


export const GetReferralLinkMediaItemByCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetReferralLinkMediaItemByCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"code"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReferralLinkCode"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referralLinkMediaItemByCode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"code"},"value":{"kind":"Variable","name":{"kind":"Name","value":"code"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"mediaCampaign"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}},{"kind":"Field","name":{"kind":"Name","value":"referralLink"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetReferralLinkMediaItemByCode__
 *
 * To run a query within a React component, call `useGetReferralLinkMediaItemByCode` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralLinkMediaItemByCode` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralLinkMediaItemByCode({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetReferralLinkMediaItemByCode(baseOptions: ApolloReactHooks.QueryHookOptions<GetReferralLinkMediaItemByCode, GetReferralLinkMediaItemByCodeVariables> & ({ variables: GetReferralLinkMediaItemByCodeVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetReferralLinkMediaItemByCode, GetReferralLinkMediaItemByCodeVariables>(GetReferralLinkMediaItemByCodeDocument, options);
      }
export function useGetReferralLinkMediaItemByCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReferralLinkMediaItemByCode, GetReferralLinkMediaItemByCodeVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetReferralLinkMediaItemByCode, GetReferralLinkMediaItemByCodeVariables>(GetReferralLinkMediaItemByCodeDocument, options);
        }
export function useGetReferralLinkMediaItemByCodeSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetReferralLinkMediaItemByCode, GetReferralLinkMediaItemByCodeVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetReferralLinkMediaItemByCode, GetReferralLinkMediaItemByCodeVariables>(GetReferralLinkMediaItemByCodeDocument, options);
        }
export type GetReferralLinkMediaItemByCodeHookResult = ReturnType<typeof useGetReferralLinkMediaItemByCode>;
export type GetReferralLinkMediaItemByCodeLazyQueryHookResult = ReturnType<typeof useGetReferralLinkMediaItemByCodeLazyQuery>;
export type GetReferralLinkMediaItemByCodeSuspenseQueryHookResult = ReturnType<typeof useGetReferralLinkMediaItemByCodeSuspenseQuery>;
export type GetReferralLinkMediaItemByCodeQueryResult = Apollo.QueryResult<GetReferralLinkMediaItemByCode, GetReferralLinkMediaItemByCodeVariables>;