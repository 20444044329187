import classnames from 'classnames';
import { FC } from 'react';
import styles from './ResetButton.module.scss';

interface Props {
  onClick: () => void;
  isDisabled: boolean;
}

export const ResetButton: FC<Props> = ({ onClick, isDisabled }) => (
  <button
    disabled={isDisabled}
    type="button"
    onClick={onClick}
    className={classnames(styles.resetButton)}
  >
    Сбросить всё
  </button>
);
