import { FC } from 'react';
import { Tabs } from 'ui/Tabs';
import { MediaItemTypeName } from 'commonTypes';
import { getMediaItemsTabs } from 'commonComponents/MediaItemsTabs/helpers/getMediaItemsTabs';

interface Props {
  currentTabId?: MediaItemTypeName;
  onClick: (type: MediaItemTypeName) => void;
  isReferralLinkVisible: boolean;
  isPromoCodeVisible: boolean;
}

export const MediaItemsTabs: FC<Props> = ({
  currentTabId,
  onClick,
  isReferralLinkVisible,
  isPromoCodeVisible,
}) => {
  const handleTabChange = (id: MediaItemTypeName) => {
    onClick(id);
  };

  const tabs = getMediaItemsTabs({
    isReferralLinkVisible,
    isPromoCodeVisible,
  });

  return (
    <Tabs
      tabs={tabs}
      currentTabId={currentTabId}
      onChangeTab={handleTabChange}
    />
  );
};
