import React, { FC } from 'react';
import styles from './AdditionalData.module.scss';
import { GetProviderGameForFilter } from '../../queries/generated/GetProviderGameForFilter';

interface AdditionalDataProps {
  node: GetProviderGameForFilter['providerGames']['edges'][number]['node'];
}

export const AdditionalData: FC<AdditionalDataProps> = ({ node }) => (
  <span className={styles.additionalData}>
    <span className={styles.label}>{node.provider}</span>
    {node.system ? <span className={styles.label}>{node.system}</span> : null}
  </span>
);
