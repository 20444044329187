import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { getNumberSign } from 'utils/getNumberSign';
import { formatSumWithCurrency } from 'utils/accounting';
import { DateFormat, formatDate } from 'utils/formatDate';
import { mapSportsBetActionTypeToTitle } from 'src/const';
import Error from 'ui/Error';
import { SidebarLoader } from 'ui/SidebarLoader';
import { useGetSportsBetActionHistory } from '../../../../queries/generated/GetSportsBetActionHistory';

interface Props {
  id: string;
}

export const ActionHistory: FC<Props> = ({ id }) => {
  const { data, loading, error } = useGetSportsBetActionHistory({
    variables: {
      id,
    },
  });

  if (error) {
    return <Error error={error} />;
  }

  const actionHistory = data?.sportsBet.actionHistory;

  return (
    <SidebarLoader loading={loading}>
      {actionHistory?.map(
        ({ sum, balance, createdAt, actionType, externalId }) => {
          const sumWithSign = `${getNumberSign(sum.amount)}${formatSumWithCurrency(sum.amount, sum.currency)}`;

          return (
            <DataCard
              key={externalId}
              header={{
                leftTitle: mapSportsBetActionTypeToTitle[actionType],
                leftSubTitle: externalId,
              }}
              rows={[
                { title: 'Сумма', value: sumWithSign },
                {
                  title: 'Баланс',
                  value: formatSumWithCurrency(
                    balance.amount,
                    balance.currency,
                  ),
                },
                {
                  title: 'Создан',
                  value: formatDate(createdAt, DateFormat.FullDateWithSeconds),
                },
              ]}
              withBorder
            />
          );
        },
      )}
    </SidebarLoader>
  );
};
