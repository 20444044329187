import { nbsp } from 'src/const';

export enum CreatePromoCodeModalSteps {
  BaseStep = 1,
  ConnectionWithFreespinsAndBonus = 2,
  ConfirmCreation = 3,
}

export enum FieldName {
  ActivationLimit = 'activationLimit',
  Token = 'token',
  ReferralLinkId = 'referralLinkId',
  ReferralLinkUrl = 'referralLinkUrl',
  ReferralCodeId = 'referralCodeId',
  MediaCampaignName = 'mediaCampaignName',
  MediaCampaignUrl = 'mediaCampaignUrl',
  Usage = 'usage',

  FreespinGameName = 'freespinGameName',
  FreespinGameId = 'freespinGameId',
  FreespinCurrency = 'freespinCurrency',
  FreespinCount = 'freespinCount',
  FreespinWager = 'freespinWager',
  FreespinMinBalance = 'freespinMinBalance',

  BonusAmount = 'bonusAmount',
  BonusCurrency = 'bonusCurrency',
  BonusWager = 'bonusWager',
  BonusCashout = 'bonusCashout',
  BonusMinBalance = 'bonusMinBalance',

  ConnectionWithFreespins = 'connectionWithFreespins',
  ConnectionWithBonus = 'connectionWithBonus',
  InvalidToken = 'invalidToken',

  Partner = 'partner',
}

export const mapFieldNameToTitle: {
  [key in FieldName]: string;
} = {
  [FieldName.ActivationLimit]: 'Количество активаций',
  [FieldName.Token]: 'Токен',
  [FieldName.ReferralLinkId]: 'Рефссылка', // this is a service field, not visible on UI. It's needed for the promocode creation
  [FieldName.ReferralLinkUrl]: 'Рефссылка',
  [FieldName.ReferralCodeId]: 'Реферальный код',
  [FieldName.MediaCampaignName]: 'Название медиакампании',
  [FieldName.MediaCampaignUrl]: 'URL медиакампании',
  [FieldName.Usage]: 'Использование',

  [FieldName.FreespinGameId]: 'Слот',
  [FieldName.FreespinCurrency]: 'Валюта',
  [FieldName.FreespinCount]: 'Количество',
  [FieldName.FreespinWager]: 'Вейджер',
  [FieldName.FreespinMinBalance]: 'Минимальный баланс',
  [FieldName.FreespinGameName]: 'Название игры',

  [FieldName.BonusAmount]: 'Сумма',
  [FieldName.BonusWager]: 'Вейджер',
  [FieldName.BonusCashout]: 'Кэшаут',
  [FieldName.BonusMinBalance]: 'Минимальный баланс',
  [FieldName.BonusCurrency]: 'Валюта',

  [FieldName.ConnectionWithFreespins]: `Связь с${nbsp}фриспинами`,
  [FieldName.ConnectionWithBonus]: `Связь с${nbsp}бонусом`,
  [FieldName.InvalidToken]: `${nbsp}`,

  [FieldName.Partner]: 'Партнёр',
};
