import { useEffect, useState } from 'react';
import ClickOutside, { useClickOutside } from 'ui/ClickOutside';
import { Trigger } from './components/Trigger';
import styles from './ActiveFilter.module.scss';
import { SelectedFilter } from '../../types';

interface Props<T> {
  selectedFilter: SelectedFilter<T>;
  removeFilter: (filterId: T) => void;
  setFilter: (filterId: T, values: Array<string>) => void;
  isDisabled: boolean;
  getFilter?: (filterId: T) => Array<string>;
}

export const ActiveFilter = <T extends string>({
  removeFilter,
  setFilter,
  getFilter,
  selectedFilter,
  isDisabled,
}: Props<T>) => {
  const [isOpened, setIsOpened] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState<Array<string>>([]);

  const onClickOutside = useClickOutside(setIsOpened);
  const { filterId, values, getSelectedFilterValues } = selectedFilter;

  useEffect(() => {
    const getSelectedFilterValuesAsync = async () => {
      const labels = await getSelectedFilterValues(values);

      setSelectedLabels(labels);
    };

    getSelectedFilterValuesAsync();
  }, [getSelectedFilterValues, values]);

  return (
    <ClickOutside className={styles.filter} callback={onClickOutside}>
      {selectedLabels.length ? (
        <Trigger
          isDisabled={isDisabled}
          removeFilter={removeFilter}
          filterId={filterId}
          selectedLabels={selectedLabels}
          setIsOpened={setIsOpened}
          isOpened={isOpened}
        />
      ) : null}
      {isOpened && selectedFilter.FilterComponent && (
        <selectedFilter.FilterComponent
          setFilter={setFilter}
          getFilter={getFilter}
          setIsOpened={setIsOpened}
          filterId={filterId}
        />
      )}
    </ClickOutside>
  );
};
