import errorToast from 'ui/Toast/ErrorToast';
import { nbsp } from 'src/const';
import { useGetPlayerForPreviewFilterLazyQuery } from '../queries/generated/GetPlayerForPreviewFilter';

export const useGetSelectedPlayerFilterValues = () => {
  const [loadPlayer] = useGetPlayerForPreviewFilterLazyQuery();

  const getSelectedPlayerFilterValues = async (
    values: Array<string>,
  ): Promise<Array<string>> => {
    const playerId = values[0];

    try {
      const result = await loadPlayer({ variables: { id: playerId } });
      const { name: playerName, email, phone } = result.data?.player || {};

      return playerName ? [`${playerName}, ${email || phone}`] : [playerId];
    } catch (error) {
      errorToast({
        header: `При${nbsp}загрузке игрока произошла ошибка`,
        text: (error as Error).message,
        toastId: 'get-player-for-filter-error',
      });

      return [playerId];
    }
  };

  return { getSelectedPlayerFilterValues };
};
