import { RewardTariffType, RewardTariffTypeName } from 'commonTypes';
import { Maybe, MediaItemCpaRewardTariff } from 'generatedGraphql';
import { AffiliateReferralLinkItemDetailsData } from '../../queries/generated/AffiliateReferralLinkItemDetailsData';

interface GetInitialValuesParams {
  referralLinkMediaItem: Maybe<AffiliateReferralLinkItemDetailsData>;
  referralLinkId: string;
}

export const getInitialValues = ({
  referralLinkMediaItem,
  referralLinkId,
}: GetInitialValuesParams) => {
  const rewardTariff =
    referralLinkMediaItem?.mediaItemRewardTariff.rewardTariff;
  const isCpaTariff = rewardTariff?.__typename === RewardTariffTypeName.Cpa;

  const revShareRewardTariffId = isCpaTariff
    ? (referralLinkMediaItem?.mediaItemRewardTariff as MediaItemCpaRewardTariff)
        .fallbackRewardTariff.id
    : rewardTariff?.id;

  return {
    id: referralLinkId,
    name: referralLinkMediaItem?.name || '',
    mediaCampaignId: referralLinkMediaItem?.mediaCampaign.id || '',
    type: isCpaTariff ? RewardTariffType.Cpa : RewardTariffType.RevShare,
    cpaTariffId: rewardTariff?.id || '',
    revShareTariffId: revShareRewardTariffId || '',
  };
};
