import { Tabs, TabsWrapper } from 'ui/Modal';
import { FC } from 'react';
import { Info } from './components/Info';
import { ActionHistory } from './components/ActionHistory';
import { EventHistory } from './components/EventHistory';

interface Props {
  id: string;
}

export const SportsBetModal: FC<Props> = ({ id }) => {
  const tabs: Array<Tabs> = [
    {
      title: 'Информация',
      content: <Info id={id} />,
    },
    {
      title: 'События',
      content: <EventHistory id={id} />,
    },
    {
      title: 'История действий',
      content: <ActionHistory id={id} />,
    },
  ];

  return <TabsWrapper tabs={tabs} activeTabDefault={0} />;
};
