/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useSearchParams } from 'react-router-dom';
import { Currency } from 'commonTypes';
import { useGetGameActionsLazyQuery } from '../queries/generated/GetGameActions';
import { GameActionsFilter, MAX_DATE, MIN_DATE } from '../const';
import { parseDateWithFormat } from './helpers/parseDateWithFormat';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetGameActions = () => {
  const [searchParams] = useSearchParams();

  const currency = searchParams.getAll(
    GameActionsFilter.Currency,
  ) as Array<Currency>;
  const playerId = searchParams.get(GameActionsFilter.Player);
  const providerGameId = searchParams.getAll(GameActionsFilter.ProviderGame);
  const dates = searchParams.getAll(GameActionsFilter.Date);

  const [loadGameActions, { data, loading, error, fetchMore, refetch }] =
    useGetGameActionsLazyQuery();

  const loadGameActionsCallback = useCallback(() => {
    const period =
      dates[0] || dates[1]
        ? {
            from: parseDateWithFormat(dates[0], {
              fallbackDate: MIN_DATE,
            }),
            to: parseDateWithFormat(dates[1], {
              addOneDayIfNoTime: true,
              fallbackDate: MAX_DATE,
            }),
          }
        : undefined;

    return loadGameActions({
      variables: {
        first: getCountByWindowHeight(cellHeight, 0),
        filter: {
          playerId: playerId || undefined,
          currency: currency.length ? currency : undefined,
          providerGameId: providerGameId.length ? providerGameId : undefined,
          period,
        },
      },
    });
  }, [
    playerId,
    JSON.stringify(providerGameId),
    JSON.stringify(currency),
    JSON.stringify(dates),
    loadGameActions,
  ]);

  const gameActions = useMemo(
    () => data?.gameActions?.edges.map(({ node }) => node),
    [data?.gameActions?.edges],
  );

  const endCursor = data?.gameActions?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  return {
    gameActions,
    loadGameActions: loadGameActionsCallback,
    loading,
    error,
    data,
    fetchMoreCallback,
    refetch,
    hasNextPage: data?.gameActions?.pageInfo.hasNextPage,
  };
};
