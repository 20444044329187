import NowrapCell from 'ui/Table/components/NowrapCell';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import { generatePath } from 'react-router';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { Route } from 'src/router/routes.const';
import { twoEmDash } from 'src/const';
import { ColumnId, mapColumnIdToName, columnsIds } from '../const';
import { MediaItemPromoCodeActivationData } from '../../../queries/generated/MediaItemPromoCodeActivationData';
import { getOwnerData } from '../helpers/getOwnerData';

const columnHelper = createColumnHelper<MediaItemPromoCodeActivationData>();

const columnsSource: ColumnsSource<MediaItemPromoCodeActivationData> = [
  columnHelper.accessor(ColumnId.Player, {
    id: ColumnId.Player,
    header: mapColumnIdToName[ColumnId.Player],
    cell: ({ row }) => {
      const { id, name, email } = row.original.player;
      const playerUrl = generatePath(Route.PLAYER, { id });

      return (
        <MultilineCellWithLink
          isOpenInNewWindow
          title={name}
          subTitle={email}
          route={playerUrl}
        />
      );
    },
  }),
  columnHelper.accessor(ColumnId.Token, {
    id: ColumnId.Token,
    header: mapColumnIdToName[ColumnId.Token],
  }),
  columnHelper.accessor(ColumnId.MediaCampaign, {
    id: ColumnId.MediaCampaign,
    header: mapColumnIdToName[ColumnId.MediaCampaign],
    cell: ({ row }) => {
      const { name, url } = row.original.promoCodeMediaItem.mediaCampaign;

      return <MultilineCellWithIcon title={name} subTitle={url} />;
    },
  }),
  columnHelper.accessor(ColumnId.Owner, {
    id: ColumnId.Owner,
    header: mapColumnIdToName[ColumnId.Owner],
    cell: ({ row }) => {
      const referralLink = row.original.promoCodeMediaItem?.referralLink;

      const owner = getOwnerData(referralLink);

      const email = owner?.email;
      const name = owner?.name;

      return MayBeNullCell(
        name && <MultilineCellWithIcon title={name} subTitle={email} />,
        twoEmDash,
      );
    },
  }),
  columnHelper.accessor(ColumnId.PromoCodeId, {
    id: ColumnId.PromoCodeId,
    header: mapColumnIdToName[ColumnId.PromoCodeId],
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetPromoCodeActivationsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
