import { Dispatch, SetStateAction } from 'react';
import { DateTimeValues } from 'ui/DatePicker/types';
import { formatDateTime } from './formatDateTime';

interface Params<T> {
  setFilter: (filterId: T, value: Array<string>) => void;
  filterId: T;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}

export const getHandleSubmit = <T>({
  setFilter,
  filterId,
  setIsOpened,
}: Params<T>) => {
  const handleSubmit = (dateRange: DateTimeValues) => {
    const [startDate, endDate] = dateRange.dates;
    const [startTime, endTime] = dateRange.times;

    const formattedStartDateTime = startDate
      ? formatDateTime(startDate, startTime)
      : '';

    const formattedEndDateTime = endDate
      ? formatDateTime(endDate, endTime)
      : '';

    setFilter(filterId, [formattedStartDateTime, formattedEndDateTime]);
    setIsOpened(false);
  };

  return handleSubmit;
};
