import { FC } from 'react';
import Error from 'ui/Error';
import { SidebarLoader } from 'ui/SidebarLoader';
import { useGetSportsBetInfo } from '../../../../queries/generated/GetSportsBetInfo';
import { InfoBlock } from './components/InfoBlock';

interface Props {
  id: string;
}

export const Info: FC<Props> = ({ id }) => {
  const { data, loading, error } = useGetSportsBetInfo({
    variables: {
      id,
    },
  });

  if (error) {
    return <Error error={error} />;
  }

  const sportsBet = data?.sportsBet;

  return (
    <SidebarLoader loading={loading}>
      <InfoBlock sportsBet={sportsBet} />
    </SidebarLoader>
  );
};
