import { useEffect, useState } from 'react';
import { Form, useFormikContext } from 'formik';
import Error from 'ui/Error';
import classnames from 'classnames';
import Input, { InputSize } from 'ui/Input';
import { ConfirmationButtons } from 'ui/Filters/components/ConfirmationButtons';
import { ApolloError } from '@apollo/client';
import { FilterSelectOption } from 'ui/Filters/types';
import { MULTISELECT_SEARCH_FIELD_NAME } from '../../const';
import { MultiSelectList } from '../MultiSelectList';
import { isSubmitDisabled } from '../../helpers';
import { FilterState } from '../../types';
import styles from './MultiSelectForm.module.scss';

interface FormikSyncProps<T> {
  options: Array<FilterSelectOption>;
  initialValues: FilterState;
  filterId: T;
  resetFilter: () => void;
  getFilter?: (filterId: T) => Array<string>;
  fetchMoreConfig?: {
    fetchMore?: () => void;
    hasNextPage?: boolean;
  };
  error?: ApolloError;
  loading?: boolean;
  extendedClassname?: string;
  onSearchInList?: (value: string) => void;
  placeholder: string;
  emptyListText: string;
}

export const MultiSelectForm = <T extends string>({
  options,
  initialValues,
  getFilter,
  filterId,
  resetFilter,
  fetchMoreConfig,
  error,
  loading,
  extendedClassname,
  onSearchInList,
  placeholder,
  emptyListText,
}: FormikSyncProps<T>) => {
  const [searchQuery, setSearchQuery] = useState('');

  const { values, resetForm } = useFormikContext<FilterState>();

  const hasCheckedItems = Object.values(initialValues).some(Boolean);
  const selectedFilterValues = getFilter
    ? getFilter(filterId)
    : Object.keys(initialValues).filter((key) => initialValues[key]);

  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
    if (onSearchInList) {
      onSearchInList(value);
    }
  };

  useEffect(() => {
    const mergedValues = { ...initialValues, ...values };

    resetForm({ values: mergedValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, resetForm]);

  return (
    <Form>
      {error && <Error error={error} />}
      <div className={classnames(styles.dropdown, extendedClassname)}>
        {onSearchInList && (
          <Input
            value={searchQuery}
            name={MULTISELECT_SEARCH_FIELD_NAME}
            placeholder={placeholder}
            onChange={handleSearchChange}
            wrapperClassName={styles.searchInputWrapper}
            inputSize={InputSize.Small}
            autoFocus
          />
        )}
        <MultiSelectList
          options={options}
          loading={loading}
          emptyListText={emptyListText}
          fetchMoreConfig={fetchMoreConfig}
          onSearchInList={onSearchInList}
        />
        <ConfirmationButtons
          isResetDisabled={!hasCheckedItems}
          isSubmitDisabled={isSubmitDisabled({
            values,
            selectedFilterValues,
          })}
          onResetFilter={resetFilter}
        />
      </div>
    </Form>
  );
};
