import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PartnerPageContainer } from '../../components/PartnerPageContainer/PartnerPageContainer';
import { PartnerLoginHistoryTable } from '../../components/PartnerLoginHistoryBlock/components/LoginHistoryTable';
import { useGetPartnerLoginHistoryColumns } from '../../components/PartnerLoginHistoryBlock/components/LoginHistoryTable/hooks/useGetPartnerLoginHistoryColumns';

export const PartnerLoginHistory: FC = () => {
  const { columns } = useGetPartnerLoginHistoryColumns(true);
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PartnerPageContainer partnerId={id}>
      <PartnerLoginHistoryTable
        partnerId={id}
        columns={columns}
        shouldReturnToPrevPage
        isFullScreenTable
      />
    </PartnerPageContainer>
  );
};
