import { useEffect } from 'react';
import { MultiSelectFilter } from 'ui/Filters';
import { FilterComponentProps } from 'ui/Filters/types';
import { useGetProviderGameQuery } from './hooks/useGetProviderGameQuery';
import { getProviderGamesFilterOptions } from './helpers';
import styles from './ProviderGameFilter.module.scss';
import { GameActionsFilter } from '../../const';

export const ProviderGameFilter = ({
  setFilter,
  filterId,
  setIsOpened,
  getFilter,
}: FilterComponentProps<GameActionsFilter>) => {
  const {
    data,
    loading,
    error,
    fetchMore,
    hasNextPage,
    loadProviderGamesWithThrottle,
  } = useGetProviderGameQuery();

  useEffect(() => {
    loadProviderGamesWithThrottle();
  }, [loadProviderGamesWithThrottle]);

  const options = getProviderGamesFilterOptions({
    providerGames: data?.providerGames,
  });

  return (
    <MultiSelectFilter
      options={options}
      setFilter={setFilter}
      getFilter={getFilter}
      filterId={filterId}
      setIsOpened={setIsOpened}
      fetchMoreConfig={{
        fetchMore: () => {
          if (hasNextPage) {
            fetchMore();
          }
        },
        hasNextPage,
      }}
      extendedClassname={styles.dropdown}
      loading={loading}
      error={error}
      onSearchInList={loadProviderGamesWithThrottle}
      enableReinitialize={false}
    />
  );
};
