import { FC, useEffect } from 'react';
import { Dropdown } from 'ui/Dropdown';
import { useGetReferralCodes } from './hooks/useGetReferralCodes';

interface Props {
  partnerId: string;
  dropdownName: string;
  onChange: (code: string) => void;
}

export const ReferralLinkCodeDropdown: FC<Props> = ({
  partnerId,
  dropdownName,
  onChange,
}) => {
  const { fetchMore, options, loadReferralCodes, hasNextPage, loading } =
    useGetReferralCodes();

  useEffect(() => {
    loadReferralCodes(partnerId);
  }, [loadReferralCodes, partnerId]);

  return (
    <Dropdown
      onChange={(value) => {
        if (!Array.isArray(value)) {
          onChange(value);
        }
      }}
      fetchMoreConfig={{
        fetchMore: () => {
          if (hasNextPage) {
            fetchMore();
          }
        },
        hasNextPage,
      }}
      name={dropdownName}
      label="Реферальный код"
      placeholder="Выберите реферальный код"
      loading={loading}
      options={options}
    />
  );
};
