import { FC } from 'react';
import Input from 'ui/Input';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import { Form, Formik, FormikHelpers } from 'formik';
import FormRow from 'ui/FormRow';
import Button, { ButtonTheme } from 'ui/Button';
import Error from 'ui/Error';
import { Tooltip } from 'ui/Tooltip';
import { Dropdown } from 'ui/Dropdown';
import { RewardTariffType } from 'commonTypes';
import { ReferralLinkData } from 'commonComponents/ReferralLinkBlock';
import { MediaCampaignDropdown } from 'commonComponents/MediaCampaignDropdown';
import { validate } from './validation';
import { useCreateReferralLink } from './hooks/useCreateReferralLink';
import { mediaItemTypeOptions, rewardTypeOptions } from '../../../../helpers';
import { RevShareForm } from './components/RevShareForm';
import { CpaForm } from './components/CpaForm';

interface Props {
  partnerApplicationId: string;
  partnerName: string;
  setReferralLinkData: (value: ReferralLinkData) => void;
  referralLinkData?: ReferralLinkData | null;
}

export const CreateReferralLink: FC<Props> = ({
  partnerName,
  partnerApplicationId,
  setReferralLinkData,
  referralLinkData,
}) => {
  const { initialValues, onSubmitForm, loading, error, buttonText, errorText } =
    useCreateReferralLink({
      partnerName,
      partnerApplicationId,
      setReferralLinkData,
      referralLinkData,
    });

  const resetTariff = (
    setFieldValue: FormikHelpers<typeof initialValues>['setFieldValue'],
  ) => {
    setFieldValue('cpaTariffId', '');
    setFieldValue('revShareTariffId', '');
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isValid, dirty, values, setFieldValue }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <FormRow isWide>
              <Input name="partnerName" label="Партнёр" disabled />
            </FormRow>
            <FormRow isWide>
              <Dropdown
                disabled
                name="typeMediaItem"
                label="Тип элемента"
                options={mediaItemTypeOptions}
              />
            </FormRow>
            <FormRow isWide>
              <Input
                placeholder="Введите название"
                name="referralLinkName"
                label="Название"
              />
            </FormRow>
            <FormRow isWide>
              <MediaCampaignDropdown
                name="mediaCampaignId"
                label="Медиакампания"
              />
            </FormRow>
            <FormRow isWide>
              <Input
                name="referralLinkCode"
                label="Реферальный код"
                placeholder="Укажите ссылку"
              />
            </FormRow>
            <FormRow isWide>
              <Dropdown
                onChange={() => {
                  resetTariff(setFieldValue);
                }}
                name="typeOfPaymentModel"
                label="Модель оплаты"
                options={rewardTypeOptions}
              />
            </FormRow>
            {values.typeOfPaymentModel === RewardTariffType.RevShare && (
              <RevShareForm />
            )}
            {values.typeOfPaymentModel === RewardTariffType.Cpa && <CpaForm />}
          </FormRowsWrapper>
          <Tooltip
            tooltipContent={!isValid || !dirty ? errorText : undefined}
            placement="right"
            shouldSetWidthFitContent
          >
            <Button
              type="submit"
              isLoading={loading}
              disabled={!isValid || !dirty}
              theme={ButtonTheme.Success}
            >
              {buttonText}
            </Button>
          </Tooltip>
        </Form>
      )}
    </Formik>
  );
};
