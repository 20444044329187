import { FormikErrors } from 'formik';
import { RewardTariffType } from 'commonTypes';
import { EditMediaItemReferralLinkInitialValues } from './types';

export const validate = (
  values: EditMediaItemReferralLinkInitialValues,
): FormikErrors<EditMediaItemReferralLinkInitialValues> => {
  const errors: FormikErrors<EditMediaItemReferralLinkInitialValues> = {};

  if (
    values.typeRewardTariff &&
    values.typeRewardTariff === RewardTariffType.Cpa &&
    !values.cpaTariffId
  ) {
    errors.cpaTariffId = 'Выберите партнёрский тариф';
  }

  if (!values.name) {
    errors.name = 'Поле не может быть пустым';
  }

  if (
    values.typeRewardTariff &&
    values.typeRewardTariff === RewardTariffType.RevShare &&
    !values.revShareTariffId
  ) {
    errors.revShareTariffId = 'Выберите партнёрский тариф';
  }

  if (
    values.typeRewardTariff === RewardTariffType.Cpa &&
    !values.revShareTariffId
  ) {
    errors.revShareTariffId = 'Выберите резервный тариф';
  }

  if (!values.mediaCampaignId) {
    errors.mediaCampaignId = 'Выберите медиакампанию';
  }

  return errors;
};
