import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FilterConfig, SelectedFilter } from '../types';

interface Params<T> {
  filterConfigs: Array<FilterConfig<T>>;
}

export const useGetSelectedFilters = <T extends string>({
  filterConfigs,
}: Params<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedFilters, setSelectedFilters] = useState<
    Array<SelectedFilter<T>>
  >([]);

  useEffect(() => {
    const newSelectedFilters: Array<SelectedFilter<T>> = [];

    filterConfigs.forEach((filterConfig) => {
      const { filterId, getSelectedFilterValues, FilterComponent } =
        filterConfig;
      const values = searchParams.getAll(filterId);

      if (values.length) {
        newSelectedFilters.push({
          filterId,
          values,
          getSelectedFilterValues,
          FilterComponent,
        });
      }
    });
    setSelectedFilters(newSelectedFilters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterConfigs)]);

  const resetAllFilters = () => {
    const newParams = new URLSearchParams(searchParams);

    filterConfigs.forEach(({ filterId }) => {
      newParams.delete(filterId);
    });
    setSearchParams(newParams);
    setSelectedFilters([]);
  };

  const removeFilter = (filterId: T) => {
    const newParams = new URLSearchParams(searchParams);

    newParams.delete(filterId);

    setSearchParams(newParams);

    setSelectedFilters((prev) =>
      prev.filter((filter) => filter.filterId !== filterId),
    );
  };

  const setFilter = (filterId: T, values: Array<string>) => {
    const filterConfig = filterConfigs.find(
      ({ filterId: id }) => filterId === id,
    );

    if (!filterConfig) return;
    if (!values.length) {
      removeFilter(filterId);

      return;
    }

    const newParams = new URLSearchParams(searchParams);

    newParams.delete(filterId);
    values.forEach((value) => {
      newParams.append(filterId, value);
    });
    setSearchParams(newParams);
    setSelectedFilters((prev) => {
      const exists = prev.find((filter) => filter.filterId === filterId);

      if (!exists) {
        return [
          ...prev,
          {
            filterId,
            values,
            getSelectedFilterValues: filterConfig.getSelectedFilterValues,
            FilterComponent: filterConfig.FilterComponent,
          },
        ];
      }

      return prev.map((filter) =>
        filter.filterId === filterId ? { ...filter, values } : filter,
      );
    });
  };

  const getFilter = (filterId: T): Array<string> => {
    const selectedFilter = selectedFilters.find(
      (filter) => filter.filterId === filterId,
    );

    return selectedFilter ? selectedFilter.values : [];
  };

  const hasSelectedFilter = !!selectedFilters.length;

  return {
    selectedFilters,
    resetAllFilters,
    hasSelectedFilter,
    removeFilter,
    setFilter,
    getFilter,
  };
};
