import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { Route as RouteEnum } from 'src/router/routes.const';
import { generatePath } from 'react-router';
import { useGetCompanyLoginHistoryColumns } from './components/CompanyLoginHistoryTable/hooks/useGetCompanyLoginHistoryColumns';
import { CompanyLoginHistoryTable } from './components/CompanyLoginHistoryTable/CompanyLoginHistoryTable';

interface Props {
  id: string;
}

export const CompanyLoginHistoryBlock: FC<Props> = withBlockProvider(
  ({ id }) => {
    const { columns } = useGetCompanyLoginHistoryColumns(false);

    return (
      <CompanyLoginHistoryTable
        companyId={id}
        columns={columns}
        routeToCustomPage={generatePath(RouteEnum.COMPANY_LOGIN_HISTORY, {
          id,
        })}
      />
    );
  },
);
