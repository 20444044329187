import { DateFormat, formatDate } from 'utils/formatDate';
import { formatSumAmount } from 'utils/accounting';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { currencySignMap } from 'src/const';
import { TableCellAlign } from 'ui/Table/const';
import {
  blockColumnsIds,
  mapColumnIdToName,
  pageColumnsIds,
  ColumnId,
} from '../const';
import { PlayerAccountData } from '../queries/generated/PlayerAccountData';

const columnHelper = createColumnHelper<PlayerAccountData>();

const columnsSource: ColumnsSource<PlayerAccountData> = [
  columnHelper.accessor(ColumnId.ExternalId, {
    id: ColumnId.ExternalId,
    header: mapColumnIdToName[ColumnId.ExternalId],
  }),
  columnHelper.accessor(ColumnId.Currency, {
    id: ColumnId.Currency,
    header: mapColumnIdToName[ColumnId.Currency],
    cell: ({ row }) => currencySignMap[row.original.balance.currency],
  }),
  columnHelper.accessor(ColumnId.Balance, {
    id: ColumnId.Balance,
    header: mapColumnIdToName[ColumnId.Balance],
    cell: ({ row }) => formatSumAmount(row.original.balance.amount),
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.BlockedWithdrawalSum, {
    id: ColumnId.BlockedWithdrawalSum,
    header: mapColumnIdToName[ColumnId.BlockedWithdrawalSum],
    cell: ({ row }) =>
      formatSumAmount(row.original.blockedWithdrawalSum.amount),
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetPlayerAccountsColumns = (isFullScreenTable: boolean) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource,
  );

  return {
    columns,
  };
};
