import { Dispatch, SetStateAction } from 'react';
import { FilterState } from '../types';
import { MULTISELECT_SEARCH_FIELD_NAME } from '../const';

interface Params<T> {
  setFilter: (filterId: T, values: Array<string>) => void;
  filterId: T;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}

export const getHandleSubmit =
  <T>({ setFilter, filterId, setIsOpened }: Params<T>) =>
  (values: FilterState) => {
    const selectedCheckboxes: Array<string> = [];

    Object.entries(values).forEach(([key, value]) => {
      if (value && key !== MULTISELECT_SEARCH_FIELD_NAME) {
        selectedCheckboxes.push(key);
      }
    });

    setFilter(filterId, selectedCheckboxes);
    setIsOpened(false);
  };
