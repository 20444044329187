import { FC } from 'react';
import classnames from 'classnames';
import { useField } from 'formik';
import { FilterSelectOptionWithRightLabel } from 'ui/Filters/types';
import { LoadMoreStatus } from 'commonComponents/LoadMoreStatus';
import { useInfiniteScroll } from 'utils/hooks/useInfiniteScroll';
import styles from './SingleSelectList.module.scss';

interface Props<T extends string> {
  options: Array<FilterSelectOptionWithRightLabel>;
  emptyListText: string;
  loading?: boolean;
  fetchMoreConfig?: {
    fetchMore?: () => void;
    hasNextPage?: boolean;
  };
  filterId: T;
}

export const SingleSelectList: FC<Props<string>> = ({
  options,
  loading,
  emptyListText,
  fetchMoreConfig,
  filterId,
}) => {
  const [field, , helpers] = useField(filterId);

  const { setValue } = helpers;
  const { value } = field;

  const loadTrigger = useInfiniteScroll(fetchMoreConfig?.fetchMore);

  const isListWithFetchLoading = fetchMoreConfig && loading;
  const isOptionsListEmpty = !options || options.length === 0;
  const isEmptyListTextVisible = !loading && isOptionsListEmpty;

  return (
    <div className={styles.list}>
      {options.map(({ id, label, labelRight }) => (
        <div
          className={classnames(
            styles.option,
            value === id && styles.selectedOption,
          )}
          key={id}
          onClick={() => setValue(id)}
        >
          <span className={styles.label}>{label}</span>
          {labelRight && (
            <span className={styles.labelRight}>{labelRight}</span>
          )}
        </div>
      ))}
      {loadTrigger}
      {fetchMoreConfig && isListWithFetchLoading && (
        <LoadMoreStatus
          className={styles.dropdownLoader}
          fetchMore={fetchMoreConfig.fetchMore}
          isLoading={loading}
        />
      )}
      {isEmptyListTextVisible && (
        <div className={styles.searchWarningText}>{emptyListText}</div>
      )}
    </div>
  );
};
