import { GetPlayersForFilter } from '../queries/generated/GetPlayersForFilter';

export const getPlayersFilterOptions = ({
  players,
}: {
  players?: GetPlayersForFilter['players'];
}) =>
  players?.edges.map(({ node }) => ({
    id: node.id,
    label: node.name,
    labelRight: node.email || node.phone,
  })) || [];
