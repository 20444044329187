import { ReactElement, SyntheticEvent } from 'react';
import classnames from 'classnames';
import styles from './Tabs.module.scss';

export interface TabFieldInterface<T extends string> {
  id: T;
  name: string | ReactElement;
  isVisible?: boolean;
}

interface Props<T extends string = string> {
  tabs: Array<TabFieldInterface<T>>;
  currentTabId?: T;
  onChangeTab: (id: T) => void;
  className?: string;
}

export const Tabs = <T extends string = string>({
  tabs,
  currentTabId,
  onChangeTab,
  className,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
Props<T>): ReactElement<any, any> | null => {
  const onTabClick = (e: SyntheticEvent, tabId: T): void => {
    e.stopPropagation();
    onChangeTab(tabId);
  };

  return (
    <div className={classnames(styles.tabNamesWrapper, className)}>
      {tabs?.map(
        ({ name, id, isVisible = true }) =>
          isVisible && (
            <button
              type="button"
              key={id}
              className={classnames(styles.tabName, {
                [styles.tabNameSelected]: id === currentTabId,
              })}
              onClick={(e): void => onTabClick(e, id)}
            >
              {name}
            </button>
          ),
      )}
    </div>
  );
};
