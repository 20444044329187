import NowrapCell from 'ui/Table/components/NowrapCell';
import { CopyTableRow } from 'ui/CopyTableRow';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { promoCodeUsageTypeMap, twoEmDash } from 'src/const';
import { ProgressBarWithLimit } from 'commonComponents/ProgressBarWithLimit';
import {
  blockColumnsIds,
  pageColumnsIds,
  mapColumnIdToName,
  ColumnId,
} from '../const';
import { CompanyPromoCodeMediaItemsData } from '../queries/generated/CompanyPromoCodeMediaItemsData';

const columnHelper = createColumnHelper<CompanyPromoCodeMediaItemsData>();

const columnsSource: ColumnsSource<CompanyPromoCodeMediaItemsData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
  }),
  columnHelper.accessor(ColumnId.Token, {
    id: ColumnId.Token,
    header: mapColumnIdToName[ColumnId.Token],
  }),
  columnHelper.accessor(ColumnId.Activations, {
    id: ColumnId.Activations,
    header: mapColumnIdToName[ColumnId.Activations],
    cell: ({ row }) => {
      const { activation } = row.original.promoCode;

      return MayBeNullCell(
        activation && (
          <ProgressBarWithLimit
            activated={activation.activated}
            limit={activation.limit}
          />
        ),
        twoEmDash,
      );
    },
  }),
  columnHelper.accessor(ColumnId.MediaCampaign, {
    id: ColumnId.MediaCampaign,
    header: mapColumnIdToName[ColumnId.MediaCampaign],
    cell: ({ row }) => {
      const { name, url } = row.original.mediaCampaign;

      return <MultilineCellWithIcon title={name} subTitle={url} />;
    },
  }),
  columnHelper.accessor(ColumnId.UsageType, {
    id: ColumnId.UsageType,
    header: mapColumnIdToName[ColumnId.UsageType],
    cell: ({ row }) => promoCodeUsageTypeMap[row.original.usageType],
  }),
  columnHelper.accessor(ColumnId.ReferralLink, {
    id: ColumnId.ReferralLink,
    header: mapColumnIdToName[ColumnId.ReferralLink],
    cell: ({ row }) => {
      const { referralLink } = row.original;

      return MayBeNullCell(
        referralLink && (
          <CopyTableRow
            value={referralLink.referralLink}
            toastText="Вы скопировали рефссылку"
          />
        ),
        twoEmDash,
      );
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(ColumnId.UpdatedAt, {
    id: ColumnId.UpdatedAt,
    header: mapColumnIdToName[ColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetPromoCodeMediaItemsColumns = (
  isFullScreenTable: boolean,
) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource,
  );

  return {
    columns,
  };
};
