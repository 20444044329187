import { DatePicker } from 'ui/DatePicker';
import { Form, Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { useDateFilterInitialValues } from 'ui/Filters/components/DateFilter/hooks/useDateFilterInitialValues';
import { getHandleSubmit } from 'ui/Filters/components/DateFilter/helpers';
import { DateTimeInputs } from 'ui/Filters/components/DateFilter/components/DateTimeInputs/DateTimeInputs';
import { ConfirmationButtons } from 'ui/Filters/components/ConfirmationButtons';
import { logError } from 'utils/logError';
import { getResetFilters } from 'ui/Filters/helpers';
import styles from './DateFilter.module.scss';

interface Props<T> {
  setFilter: (filterId: T, value: Array<string>) => void;
  filterId: T;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  minDate?: Date;
  maxDate?: Date;
  openDateInitial?: Date;
}

export const DateFilter = <T extends string>({
  setFilter,
  filterId,
  setIsOpened,
  minDate,
  maxDate,
  openDateInitial,
}: Props<T>) => {
  const { initialValues } = useDateFilterInitialValues(filterId);

  const handleSubmit = getHandleSubmit({
    setFilter,
    filterId,
    setIsOpened,
  });

  const resetFilter = getResetFilters({ setFilter, filterId, setIsOpened });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ setValues, values }) => {
        const [startDate, endDate] = values.dates;
        const [startTime, endTime] = values.times;

        return (
          <Form>
            <DatePicker
              openDateInitial={openDateInitial}
              onChange={(selectedDates) => {
                if (Array.isArray(selectedDates)) {
                  setValues({
                    dates: selectedDates,
                    times: values.times,
                  });
                } else {
                  logError('An unexpected date type was provided');
                }
              }}
              className={styles.container}
              isSelectsRange
              startDateInitial={startDate}
              endDateInitial={endDate}
              maxDate={maxDate}
              minDate={minDate}
              name="FilterDatePicker"
              customHeader={
                <DateTimeInputs
                  startDate={startDate}
                  endDate={endDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  startTime={startTime}
                  endTime={endTime}
                  onChange={setValues}
                />
              }
              customFooter={
                <div className={styles.footerWrapper}>
                  <ConfirmationButtons
                    isResetDisabled={!values.dates.some(Boolean)}
                    isSubmitDisabled={!values.dates.some(Boolean)}
                    onResetFilter={resetFilter}
                  />
                </div>
              }
            />
          </Form>
        );
      }}
    </Formik>
  );
};
