import NowrapCell from 'ui/Table/components/NowrapCell';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import {
  mapColumnIdToName,
  ColumnId,
  pageColumnsIds,
  blockColumnsIds,
} from '../const';
import { PartnerRevShareRewardTariffsData } from '../queries/generated/PartnerRevShareRewardTariffsData';

const columnHelper = createColumnHelper<PartnerRevShareRewardTariffsData>();

const columnsSource: ColumnsSource<PartnerRevShareRewardTariffsData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
  }),
  columnHelper.accessor(ColumnId.Title, {
    id: ColumnId.Title,
    header: mapColumnIdToName[ColumnId.Title],
  }),
  columnHelper.accessor(ColumnId.Percent, {
    id: ColumnId.Percent,
    header: mapColumnIdToName[ColumnId.Percent],
    cell: ({ row }) => `${row.original.percent}%`,
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(ColumnId.UpdatedAt, {
    id: ColumnId.UpdatedAt,
    header: mapColumnIdToName[ColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetRevShareRewardTariffsColumns = (
  isFullScreenTable = false,
) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource,
  );

  return {
    columns,
  };
};
