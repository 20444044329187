import { DateFormat, formatDate } from 'src/utils/formatDate';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { mapColumnIdToName, ColumnId, columnsIds } from '../const';
import { CompanyAffiliateBasicData } from '../../../queries/generated/CompanyAffiliateBasicData';

const columnHelper = createColumnHelper<CompanyAffiliateBasicData>();

const columnsSource: ColumnsSource<CompanyAffiliateBasicData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
  }),
  columnHelper.accessor(ColumnId.Name, {
    id: ColumnId.Name,
    header: mapColumnIdToName[ColumnId.Name],
  }),
  columnHelper.accessor(ColumnId.Note, {
    id: ColumnId.Note,
    header: mapColumnIdToName[ColumnId.Note],
  }),
  columnHelper.accessor(ColumnId.ReferralLinksCount, {
    id: ColumnId.ReferralLinksCount,
    header: mapColumnIdToName[ColumnId.ReferralLinksCount],
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetAffiliatesColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
