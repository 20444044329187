export enum FieldName {
  CpaTariffId = 'cpaTariffId',
  RevShareTariffId = 'revShareTariffId',
  Name = 'name',
  MediaCampaignId = 'mediaCampaignId',
  Type = 'type',
}

export const mapCreatePromoCodeFieldToTitle: {
  [key in FieldName]: string;
} = {
  [FieldName.Name]: 'Название',
  [FieldName.MediaCampaignId]: 'Медиакампания',
  [FieldName.Type]: 'Модель оплаты',
  [FieldName.RevShareTariffId]: 'Резервный тариф',
  [FieldName.CpaTariffId]: 'Партнёрский тариф',
};
