export const getRangeCurrentReportingPeriod = () => {
  const startDate = new Date();
  const todayDate = new Date();

  const todayDay = new Date().getDate();

  if (todayDay < 15) {
    startDate.setDate(1);
  } else {
    startDate.setDate(15);
  }

  return { startOfRange: startDate, endOfRange: todayDate };
};
