import { FormikErrors } from 'formik';
import { RewardTariffType } from 'commonTypes';
import { UpdateReferralLinkMediaItemValues } from './types';

export const validate = (
  values: UpdateReferralLinkMediaItemValues,
): FormikErrors<UpdateReferralLinkMediaItemValues> => {
  const errors: FormikErrors<UpdateReferralLinkMediaItemValues> = {};

  if (!values.name) {
    errors.name = 'Введите название';
  }

  if (
    values.typeRewardTariff &&
    values.typeRewardTariff === RewardTariffType.Cpa &&
    !values.cpaRewardTariffId
  ) {
    errors.cpaRewardTariffId = 'Выберите партнёрский тариф';
  }

  if (
    values.typeRewardTariff &&
    values.typeRewardTariff === RewardTariffType.RevShare &&
    !values.revShareRewardTariffId
  ) {
    errors.revShareRewardTariffId = 'Выберите партнёрский тариф';
  }

  if (
    values.typeRewardTariff === RewardTariffType.Cpa &&
    !values.fallbackTariffId
  ) {
    errors.fallbackTariffId = 'Выберите резервный тариф';
  }

  return errors;
};
