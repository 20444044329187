import { ModalActions, ModalContext } from 'ui/Modal';
import { useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { PartnerBasicData } from 'queries/generated/PartnerBasicData';
import { CreateReferralLinkForm } from '../../CreateReferralLinkForm';
import { CreateMediaItemPromoCode } from '../../CreateMediaItemPromoCode';

interface Params {
  partnerId: string;
  isFullScreenTable: boolean;
}

export const useOpenCreateMediaItemsModal = ({
  partnerId,
  isFullScreenTable,
}: Params) => {
  const client = useApolloClient();
  const { dispatch } = useContext(ModalContext);

  const partner = client.readFragment({
    id: `Partner:${partnerId}`,
    fragment: PartnerBasicData,
  });

  const openCreateReferralLinkModal = () => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        title: 'Создать рефссылку',
        subTitle: `${partner?.name} #${partnerId}`,
        content: (
          <CreateReferralLinkForm
            partner={partner}
            isFullScreenTable={isFullScreenTable}
          />
        ),
      },
    });
  };

  const openCreatePromoCodeModal = () => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        title: 'Создать промокод',
        content: <CreateMediaItemPromoCode partner={partner} />,
        hasGoBackButtonForSteps: false,
      },
    });
  };

  return {
    openCreateReferralLinkModal,
    openCreatePromoCodeModal,
  };
};
