export enum ColumnId {
  ExternalId = 'externalId',
  Player = 'player',
  Type = 'type',
  Coefficient = 'coefficients',
  BetSum = 'betSum',
  ResultSum = 'resultSum',
  Currency = 'resultSum.currency',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.ExternalId]: 'Внешний ID',
  [ColumnId.Player]: 'Игрок',
  [ColumnId.Type]: 'Тип',
  [ColumnId.Coefficient]: 'Коэффициент',
  [ColumnId.BetSum]: 'Сумма',
  [ColumnId.ResultSum]: 'Результат',
  [ColumnId.Currency]: 'Валюта',
  [ColumnId.Status]: 'Статус',
  [ColumnId.CreatedAt]: 'Создана',
  [ColumnId.UpdatedAt]: 'Обновлена',
};

export const columnsIds = [
  ColumnId.ExternalId,
  ColumnId.Player,
  ColumnId.Type,
  ColumnId.Coefficient,
  ColumnId.BetSum,
  ColumnId.ResultSum,
  ColumnId.Currency,
  ColumnId.Status,
  ColumnId.CreatedAt,
  ColumnId.UpdatedAt,
];
