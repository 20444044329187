import NowrapCell from 'ui/Table/components/NowrapCell/NowrapCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { TournamentsTypeName } from 'commonTypes';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import {
  blockColumnsIds,
  mapColumnIdToName,
  pageColumnsIds,
  ColumnId,
} from '../const';
import { getNameTournamentByTypeName } from '../../../const';
import { TournamentRebuysData } from '../../../queries/generated/TournamentRebuysData';

const columnHelper = createColumnHelper<TournamentRebuysData>();

const columnsSource: ColumnsSource<TournamentRebuysData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
  }),
  columnHelper.accessor(ColumnId.TournamentType, {
    id: ColumnId.TournamentType,
    header: mapColumnIdToName[ColumnId.TournamentType],
    cell: ({ row }) =>
      getNameTournamentByTypeName(
        row.original.tournament.__typename as TournamentsTypeName,
      ),
  }),
  columnHelper.accessor(ColumnId.Name, {
    id: ColumnId.Name,
    header: mapColumnIdToName[ColumnId.Name],
  }),
  columnHelper.accessor(ColumnId.Sum, {
    id: ColumnId.Sum,
    header: mapColumnIdToName[ColumnId.Sum],
    cell: ({ row }) => {
      const { sum } = row.original;

      return formatSumWithCurrency(sum.amount, sum.currency);
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetTournamentRebuysColumns = (isFullScreenTable: boolean) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource,
  );

  return {
    columns,
  };
};
