import NowrapCell from 'ui/Table/components/NowrapCell';
import { CopyTableRow } from 'ui/CopyTableRow';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCellAlign } from 'ui/Table/const';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { PartnerReferralLinkMediaItemsData } from '../queries/generated/PartnerReferralLinkMediaItemsData';
import {
  blockColumnsIds,
  mapColumnIdToName,
  pageColumnsIds,
  ColumnId,
} from '../../../const';

const columnHelper = createColumnHelper<PartnerReferralLinkMediaItemsData>();

const columnsSource: ColumnsSource<PartnerReferralLinkMediaItemsData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
  }),
  columnHelper.accessor(ColumnId.Title, {
    id: ColumnId.Title,
    header: mapColumnIdToName[ColumnId.Title],
  }),
  columnHelper.accessor(ColumnId.MediaCampaign, {
    id: ColumnId.MediaCampaign,
    header: mapColumnIdToName[ColumnId.MediaCampaign],
    cell: ({ row }) => {
      const { mediaCampaign } = row.original;

      return (
        mediaCampaign &&
        MultilineCellWithIcon({
          title: mediaCampaign.name,
          subTitle: mediaCampaign.url,
        })
      );
    },
  }),
  columnHelper.accessor(ColumnId.RewardTariff, {
    id: ColumnId.RewardTariff,
    header: mapColumnIdToName[ColumnId.RewardTariff],
  }),
  columnHelper.accessor(ColumnId.ReferralLink, {
    id: ColumnId.ReferralLink,
    header: mapColumnIdToName[ColumnId.ReferralLink],
    cell: ({ row }) => (
      <CopyTableRow
        value={row.original.referralLink}
        toastText="Вы скопировали рефссылку"
      />
    ),
  }),
  columnHelper.accessor(ColumnId.PostbackCount, {
    id: ColumnId.PostbackCount,
    header: mapColumnIdToName[ColumnId.PostbackCount],
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetReferralLinkMediaItemsColumns = (
  isFullScreenTable: boolean,
) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource,
  );

  return {
    columns,
  };
};
