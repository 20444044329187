import {
  Heights,
  getCountByWindowHeight,
} from 'src/utils/getCountByWindowHeight';
import { useCallback } from 'react';
import { useGetPartnerPromoCodeMediaItemsBlockLazyQuery } from 'queries/generated/GetPartnerPromoCodeMediaItemsBlock';
import { useGetPartnerPromoCodeMediaItemsLazyQuery } from '../queries/generated/GetPartnerPromoCodeMediaItems';
import { COUNT_PER_PAGE } from '../const';

interface UseGetPromoCodeMediaItemsByTableSizeParams {
  isFullScreenTable: boolean;
  partnerId: string;
}

export const useGetPromoCodeMediaItemsByTableSize = ({
  partnerId,
  isFullScreenTable,
}: UseGetPromoCodeMediaItemsByTableSizeParams) => {
  const countPerPage = isFullScreenTable
    ? getCountByWindowHeight(Heights.defaultCellHeight)
    : COUNT_PER_PAGE;

  const currentUseGetPartnerPromoCodeMediaItems = isFullScreenTable
    ? useGetPartnerPromoCodeMediaItemsLazyQuery
    : useGetPartnerPromoCodeMediaItemsBlockLazyQuery;

  const [
    loadPartnerPromoCodeMediaItems,
    { data, loading, error, fetchMore, refetch },
  ] = currentUseGetPartnerPromoCodeMediaItems({
    variables: {
      partnerId,
      first: countPerPage,
    },
  });

  const promoCodeMediaItemsData = data?.partner.promoCodeMediaItems;
  const endCursor = promoCodeMediaItemsData?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  return {
    promoCodeMediaItemsData,
    loadPartnerPromoCodeMediaItems,
    loading,
    error,
    refetch,
    fetchMoreCallback,
  };
};
