import { MultiSelectFilter } from 'ui/Filters/components/MultiSelectFilter';
import { FC } from 'react';
import { FilterComponentProps } from 'ui/Filters/types';
import { PartnerApplicationStatus } from 'generatedGraphql';
import { partnerApplicationStatusMap } from '../../../../const';
import { PartnerApplicationFilterMember } from '../../const';

export const PartnerApplicationsStatusFilter: FC<
  FilterComponentProps<PartnerApplicationFilterMember>
> = ({ setFilter, filterId, setIsOpened }) => {
  const options = Array.from(
    Object.entries(partnerApplicationStatusMap).filter(
      ([key]) => key !== PartnerApplicationStatus.Unknown,
    ),
    ([key, value]) => ({ id: key, label: value }),
  );

  return (
    <MultiSelectFilter
      options={options}
      setFilter={setFilter}
      filterId={filterId}
      setIsOpened={setIsOpened}
    />
  );
};
