import { useCallback, useMemo } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useGetCompanyLoginHistoryBlockLazyQuery } from '../../../queries/generated/GetCompanyLoginHistoryBlock';
import { useGetCompanyLoginHistoryLazyQuery } from '../../../queries/generated/GetCompanyLoginHistory';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

interface Params {
  companyId: string;
  isFullScreenTable?: boolean;
}

export const useGetCompanyLoginHistoryByTableSize = <T>({
  companyId,
  isFullScreenTable,
}: Params) => {
  const countPerPage = isFullScreenTable
    ? getCountByWindowHeight(cellHeight)
    : COUNT_PER_PAGE;

  const currentUseGetCompanyLoginHistory = isFullScreenTable
    ? useGetCompanyLoginHistoryLazyQuery
    : useGetCompanyLoginHistoryBlockLazyQuery;

  const [
    loadCompanyLoginHistoryList,
    { data, error, loading, fetchMore, refetch },
  ] = currentUseGetCompanyLoginHistory({
    variables: {
      id: companyId,
      first: countPerPage,
    },
  });

  const companyLoginHistory = data?.company.loginHistory;
  const endCursor = companyLoginHistory?.pageInfo.endCursor;
  const hasNextPage = companyLoginHistory?.pageInfo.hasNextPage;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  const companyLoginHistoryList = useMemo(
    () =>
      companyLoginHistory?.edges.map(({ node }) => node) as unknown as Array<T>,
    [companyLoginHistory?.edges],
  );

  return {
    loadCompanyLoginHistoryList,
    data,
    loading,
    error,
    hasNextPage,
    refetch,
    fetchMoreCallback,
    companyLoginHistoryList,
  };
};
