import { DateFilter } from 'ui/Filters/components/DateFilter';
import { FC } from 'react';
import { FilterComponentProps } from 'ui/Filters/types';
import { GameActionsFilter, MAX_DATE, MIN_DATE } from '../../const';

export const GameActionsDateFilter: FC<
  FilterComponentProps<GameActionsFilter>
> = ({ setFilter, filterId, setIsOpened }) => (
  <DateFilter
    setFilter={setFilter}
    filterId={filterId}
    setIsOpened={setIsOpened}
    minDate={MIN_DATE}
    maxDate={MAX_DATE}
  />
);
