import { DropdownOption } from 'ui/DropdownMenu/types';
import { useMemo } from 'react';
import { useAuth, UserPrivilege } from 'commonComponents/Auth';
import { CreateMediaElementOptionsIds } from '../const';

export const useGetCreateMediaItemOptions = () => {
  const auth = useAuth();

  const canCreateReferralLink = auth.privileges.isSomeGranted({
    privileges: [
      UserPrivilege.CreateCompanyReferralLinkMediaItem,
      UserPrivilege.CreatePartnerReferralLinkMediaItem,
    ],
  });

  const canCreatePromoCode = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.CreatePromoCodeMediaItem],
  });

  const creationMediaItemOptions = useMemo(() => {
    const options: Array<DropdownOption> = [];

    if (canCreateReferralLink) {
      options.push({
        id: CreateMediaElementOptionsIds.ReferralLink,
        label: 'Создать рефссылку',
      });
    }

    if (canCreatePromoCode) {
      options.push({
        id: CreateMediaElementOptionsIds.PromoCode,
        label: 'Создать промокод',
      });
    }

    return options;
  }, [canCreatePromoCode, canCreateReferralLink]);

  return { creationMediaItemOptions };
};
