import { gql } from '@apollo/client';

export const getProviderGamesDynamicQuery = (values: Array<string>) => gql`
  query GetProviderGames {
    ${values
      .map(
        (id, index) => `
          providerGame${index}: providerGame(id: "${id}")
           {
            id
            name
           }
         `,
      )
      .join('\n')}
    }
`;
