import { MultiSelectFilter } from 'ui/Filters';
import { FC } from 'react';
import { FilterComponentProps } from 'ui/Filters/types';
import { companyApplicationStatusMap } from 'src/const';
import { CompanyApplicationStatus } from 'generatedGraphql';
import { CompanyApplicationFilterMember } from '../../const';

export const CompanyApplicationsStatusFilter: FC<
  FilterComponentProps<CompanyApplicationFilterMember>
> = ({ setFilter, filterId, setIsOpened }) => {
  const options = Array.from(
    Object.entries(companyApplicationStatusMap).filter(
      ([key]) => key !== CompanyApplicationStatus.Unknown,
    ),
    ([key, value]) => ({ id: key, label: value }),
  );

  return (
    <MultiSelectFilter
      options={options}
      setFilter={setFilter}
      filterId={filterId}
      setIsOpened={setIsOpened}
    />
  );
};
