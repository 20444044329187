import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import SearchInput from 'ui/SearchInput';
import { PageTypeStorage } from 'utils/storage';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { MEDIA_ITEMS_BLOCK_TITLE, MEDIA_ITEMS_EMPTY_TEXT } from '../../const';
import { PromoCodeActivationsTable } from './components/PromoCodeActivationsTable';
import { useGetPromoCodeActivations } from './hooks/useGetPromoCodeActivations';
import { MediaItemsBlockHeaderContent } from '../MediaItemsBlockHeaderContent';
import { MediaItemsTabs } from '../MediaItemsTabs';
import { MediaItemTabs } from '../MediaItemsTabs/const';
import {
  REFERRAL_LINK_INPUT_NAME,
  REFERRAL_LINK_SEARCH_PLACEHOLDER,
} from '../MediaItemsReferralLink/const';

export const Component: FC = withBlockProvider(() => {
  const {
    promoCodeActivations,
    loading,
    loadPromoCodeActivations,
    error,
    data,
    fetchMoreCallback,
    handleSearch,
    refetch,
    hasNextPage,
  } = useGetPromoCodeActivations();

  useBlockComponentState({
    loadData: loadPromoCodeActivations,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return (
    <>
      <SearchInput
        name={REFERRAL_LINK_INPUT_NAME}
        placeholder={REFERRAL_LINK_SEARCH_PLACEHOLDER}
        onSubmit={handleSearch}
      />
      <Block
        title={MEDIA_ITEMS_BLOCK_TITLE}
        id="mediaItemsPromoCodeActivations"
        headerContent={
          <MediaItemsBlockHeaderContent handleRefetchClick={() => refetch()} />
        }
        subHeader={
          <MediaItemsTabs currentTabId={MediaItemTabs.PromoCodeActivation} />
        }
        shrinkLoaderWrapper={false}
        emptyText={MEDIA_ITEMS_EMPTY_TEXT}
        isEmpty={isListEmpty(promoCodeActivations)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(promoCodeActivations) && (
          <PromoCodeActivationsTable
            promoCodeActivations={promoCodeActivations}
          />
        )}
      </Block>
    </>
  );
});

export const MediaItemsPromoCodeActivations = () => (
  <SearchContextProvider pageType={PageTypeStorage.PromoCodeActivationsPage}>
    <Component />
  </SearchContextProvider>
);
