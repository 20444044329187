import NowrapCell from 'ui/Table/components/NowrapCell';
import mayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { playerRankMap, twoEmDash } from 'src/const';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { PlayerIdentityCellWithCopy } from '../../PlayerIdentityCellWithCopy';
import { PlayerListData } from '../../../queries/generated/PlayerListData';
import { columnsIds, mapColumnIdToName, ColumnId } from '../const';
import { getAffiliateInfo } from '../helpers';

const columnHelper = createColumnHelper<PlayerListData>();

const columnsSource: ColumnsSource<PlayerListData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
  }),
  columnHelper.accessor(ColumnId.Player, {
    id: ColumnId.Player,
    header: mapColumnIdToName[ColumnId.Player],
    cell: ({ row }) => {
      const { name, email, phone } = row.original;

      return (
        <PlayerIdentityCellWithCopy name={name} email={email} phone={phone} />
      );
    },
  }),
  columnHelper.accessor(ColumnId.Rank, {
    id: ColumnId.Rank,
    header: mapColumnIdToName[ColumnId.Rank],
    cell: ({ row }) => playerRankMap[row.original.rank],
  }),
  columnHelper.accessor(ColumnId.Country, {
    id: ColumnId.Country,
    header: mapColumnIdToName[ColumnId.Country],
  }),
  columnHelper.accessor(ColumnId.Affiliate, {
    id: ColumnId.Affiliate,
    header: mapColumnIdToName[ColumnId.Affiliate],
    cell: ({ row }) => {
      const { affiliate } = row.original;
      const { name, email, route } = getAffiliateInfo(affiliate);

      return mayBeNullCell(
        affiliate && name && (
          <MultilineCellWithLink
            isOpenInNewWindow
            title={name}
            route={route}
            subTitle={email}
          />
        ),
        twoEmDash,
      );
    },
  }),
  columnHelper.accessor(ColumnId.Note, {
    id: ColumnId.Note,
    header: mapColumnIdToName[ColumnId.Note],
  }),
  columnHelper.accessor(ColumnId.LastVisited, {
    id: ColumnId.LastVisited,
    header: mapColumnIdToName[ColumnId.LastVisited],
    cell: ({ row }) => {
      const { lastVisited } = row.original;

      return mayBeNullCell(
        lastVisited && (
          <NowrapCell>
            {formatDate(lastVisited, DateFormat.FullDate)}
          </NowrapCell>
        ),
        twoEmDash,
      );
    },
  }),
];

export const useGetPlayersColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
