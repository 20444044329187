import { Form, Formik } from 'formik';
import { useContext, useRef } from 'react';
import { ModalContext } from 'ui/Modal';
import { Currency } from 'commonTypes';
import { generatePromoCodeToken } from 'src/utils/generatePromoCodeToken';
import { useSetDefaultToken } from 'src/utils/hooks/useSetDefaultToken';
import { PartnerBasicData } from 'queries/generated/PartnerBasicData';
import { useCreatePromoCodeModalSteps } from './hooks/useCreatePromoCodeModalSteps';
import { FieldName } from './const';
import { getCreatePromoCodeFormByStep } from './helpers';
import { validate } from './validation';
import { CreatePromoCodeValues } from './types';
import { useCreatePromoCode } from './hooks/useCreatePromoCode';

interface Props {
  partner: PartnerBasicData;
}
export const CreateMediaItemPromoCode = ({ partner }: Props) => {
  const { state } = useContext(ModalContext);
  const token = generatePromoCodeToken();
  const tokenRef = useRef<string>(token);

  const { id: partnerId, name: partnerName } = partner;

  useSetDefaultToken({ tokenRef });

  const initialValues: CreatePromoCodeValues = {
    [FieldName.ActivationLimit]: undefined,
    [FieldName.Token]: tokenRef.current,
    [FieldName.InvalidToken]: undefined,
    [FieldName.ReferralLinkId]: '',
    [FieldName.ReferralCodeId]: '',
    [FieldName.MediaCampaignUrl]: '',
    [FieldName.Usage]: null,

    [FieldName.FreespinGameId]: '',
    [FieldName.FreespinGameName]: '',
    [FieldName.FreespinCurrency]: Currency.Rub,
    [FieldName.FreespinCount]: 0,
    [FieldName.FreespinWager]: undefined,
    [FieldName.FreespinMinBalance]: undefined,

    [FieldName.BonusAmount]: 0,
    [FieldName.BonusCurrency]: Currency.Rub,
    [FieldName.BonusWager]: undefined,
    [FieldName.BonusCashout]: undefined,
    [FieldName.BonusMinBalance]: undefined,

    [FieldName.ConnectionWithFreespins]: false,
    [FieldName.ConnectionWithBonus]: false,
    [FieldName.Partner]: `#${partnerId} ${partnerName}` || '',
  };

  useCreatePromoCodeModalSteps();

  const { onCreatePromoCodeSubmitHandler } = useCreatePromoCode({
    partnerId,
  });

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => validate(values, state.step)}
      validateOnChange={false}
      onSubmit={onCreatePromoCodeSubmitHandler}
      enableReinitialize
    >
      <Form>{getCreatePromoCodeFormByStep(partnerId, state.step)}</Form>
    </Formik>
  );
};
