import { useCallback, useMemo } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useGetSportsBetsLazyQuery } from '../queries/generated/GetSportsBets';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetSportsBets = () => {
  const [loadSportsBets, { data, loading, error, fetchMore, refetch }] =
    useGetSportsBetsLazyQuery();

  const loadSportsBetsCallback = useCallback(
    () =>
      loadSportsBets({
        variables: {
          first: getCountByWindowHeight(cellHeight, 0),
        },
      }),
    [loadSportsBets],
  );

  const sportsBets = useMemo(
    () => data?.sportsBets?.edges.map(({ node }) => node),
    [data?.sportsBets?.edges],
  );

  const endCursor = data?.sportsBets?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  return {
    sportsBets,
    loadSportsBets: loadSportsBetsCallback,
    loading,
    error,
    data,
    fetchMoreCallback,
    refetch,
    hasNextPage: data?.sportsBets?.pageInfo.hasNextPage,
  };
};
