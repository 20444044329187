import NowrapCell from 'ui/Table/components/NowrapCell/NowrapCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { getCountryISOtoDisplayName } from 'src/utils/getCountryISOtoDisplayName';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { CpaRewardTariffData } from '../../../../../queries/generated/CpaRewardTariffData';
import { columnsIds, ColumnId, mapColumnIdToName } from '../const';

const columnHelper = createColumnHelper<CpaRewardTariffData>();

const columnsSource: ColumnsSource<CpaRewardTariffData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
  }),
  columnHelper.accessor(ColumnId.Title, {
    id: ColumnId.Title,
    header: mapColumnIdToName[ColumnId.Title],
  }),
  columnHelper.accessor(ColumnId.Countries, {
    id: ColumnId.Countries,
    header: mapColumnIdToName[ColumnId.Countries],
    cell: ({ row }) => getCountryISOtoDisplayName(row.original.countries),
  }),
  columnHelper.accessor(ColumnId.Baseline, {
    id: ColumnId.Baseline,
    header: mapColumnIdToName[ColumnId.Baseline],
    cell: ({ row }) => {
      const { amount, currency } = row.original.baselineSum;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.RewardSum, {
    id: ColumnId.RewardSum,
    header: mapColumnIdToName[ColumnId.RewardSum],
    cell: ({ row }) => {
      const { amount, currency } = row.original.rewardSum;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetCpaRewardTariffsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
