import { FilterConfig } from 'ui/Filters/types';
import { PartnerApplicationStatus } from 'generatedGraphql';
import { partnerApplicationStatusMap } from 'src/const';
import { PartnerApplicationFilterMember } from '../const';
import { PartnerApplicationsStatusFilter } from '../components/PartnerApplicationsStatusFilter';

export const getFiltersConfig = (): Array<
  FilterConfig<PartnerApplicationFilterMember>
> => [
  {
    title: 'Статус',
    filterId: PartnerApplicationFilterMember.Status,
    getSelectedFilterValues: (values) =>
      values.map(
        (value) =>
          partnerApplicationStatusMap[value as PartnerApplicationStatus],
      ),
    FilterComponent: PartnerApplicationsStatusFilter,
  },
];
