import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCellAlign } from 'ui/Table/const';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { generatePath } from 'react-router';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { Route } from 'src/router/routes.const';
import {
  ColumnId,
  blockColumnsIds,
  mapColumnIdToName,
  pageColumnsIds,
} from '../const';
import { PlayerExternalMoneyTransferData } from '../queries/generated/PlayerExternalMoneyTransferData';

const columnHelper = createColumnHelper<PlayerExternalMoneyTransferData>();

const columnsSource: ColumnsSource<PlayerExternalMoneyTransferData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
  }),
  columnHelper.accessor(ColumnId.Affiliate, {
    id: ColumnId.Affiliate,
    header: mapColumnIdToName[ColumnId.Affiliate],
    cell: ({ row }) => {
      const { affiliate } = row.original;
      const { id, name } = affiliate;

      const isCompany = affiliate.__typename === 'Company';

      const email = isCompany ? affiliate.companyEmail : affiliate.partnerEmail;

      return MultilineCellWithLink({
        title: name,
        subTitle: email,
        route: generatePath(isCompany ? Route.COMPANY : Route.PARTNER, { id }),
      });
    },
  }),
  columnHelper.accessor(ColumnId.Sum, {
    id: ColumnId.Sum,
    header: mapColumnIdToName[ColumnId.Sum],
    cell: ({ row }) => {
      const { amount, currency } = row.original.sum;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetExternalMoneyTransfersColumns = (
  isFullScreenTable: boolean,
) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource,
  );

  return {
    columns,
  };
};
