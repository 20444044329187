import moment from 'moment';
import { DateFormat } from 'utils/formatDate';

const dateTimeFilterFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
const dateTimeFormats = [
  DateFormat.DefaultDateWithTime,
  DateFormat.DefaultDate,
];

export const parseDateWithFormat = (
  dateStr = '',
  { addOneDayIfNoTime = false, fallbackDate = new Date() } = {},
) => {
  if (!dateStr) {
    return moment(fallbackDate).format(dateTimeFilterFormat);
  }

  const parsedDate = moment.utc(dateStr, dateTimeFormats, true);

  const isDateOnly = dateStr.length === 10;

  if (addOneDayIfNoTime && isDateOnly) {
    parsedDate.add(1, 'day');
  }

  return parsedDate.format(dateTimeFilterFormat);
};
