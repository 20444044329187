import { useState } from 'react';
import ClickOutside, { useClickOutside } from 'ui/ClickOutside';
import { FilterConfig } from '../../types';
import { PreviewTrigger } from './components/PreviewTrigger';
import styles from './PreviewFilter.module.scss';

interface Props<T> {
  filterConfig: FilterConfig<T>;
  setFilter: (filterId: T, values: Array<string>) => void;
  isDisabled: boolean;
  getFilter?: (filterId: T) => Array<string>;
}

export const PreviewFilter = <T extends string>({
  setFilter,
  getFilter,
  filterConfig,
  isDisabled,
}: Props<T>) => {
  const [isOpened, setIsOpened] = useState(false);
  const { filterId, title } = filterConfig;

  const onClickOutside = useClickOutside(setIsOpened);

  return (
    <ClickOutside className={styles.filter} callback={onClickOutside}>
      <PreviewTrigger
        isDisabled={isDisabled}
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        title={title}
      />
      {isOpened && filterConfig.FilterComponent && (
        <filterConfig.FilterComponent
          setFilter={setFilter}
          setIsOpened={setIsOpened}
          filterId={filterId}
          getFilter={getFilter}
        />
      )}
    </ClickOutside>
  );
};
