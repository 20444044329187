import classnames from 'classnames';
import { Dispatch, FC, SetStateAction } from 'react';
import styles from './PreviewTrigger.module.scss';

interface Props {
  title: string;
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  isDisabled: boolean;
}

export const PreviewTrigger: FC<Props> = ({
  title,
  isOpened,
  setIsOpened,
  isDisabled,
}) => (
  <button
    disabled={isDisabled}
    type="button"
    onClick={() => {
      setIsOpened(!isOpened);
    }}
    className={classnames(styles.trigger, isOpened && styles.opened)}
  >
    {title}
  </button>
);
