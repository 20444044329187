import { FC } from 'react';
import { ReferralReviewResultTypeName } from 'commonTypes';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { getCountryISOtoDisplayName } from 'src/utils/getCountryISOtoDisplayName';
import { ReviewResultCellWithTooltip } from '../ReviewResultCellWithTooltip';
import {
  DuplicateTooltipContent,
  DuplicateDeviceTooltipContent,
} from './components';
import { QualifiedReferralData } from '../../../../queries/generated/QualifiedReferralData';

interface Props {
  reviewResult?: QualifiedReferralData['review'];
}

export const QualifiedReferralReviewResult: FC<Props> = ({ reviewResult }) => {
  if (!reviewResult) {
    return 'Не проходил';
  }

  if (reviewResult.passed) {
    return 'Пройдена';
  }

  const reviewResultTypeName = reviewResult.__typename;

  if (reviewResultTypeName === ReferralReviewResultTypeName.Duplicate) {
    return (
      <ReviewResultCellWithTooltip title="Дубликат счета">
        <DuplicateTooltipContent
          account={reviewResult.paymentMethod.account}
          playerId={reviewResult.player.id}
        />
      </ReviewResultCellWithTooltip>
    );
  }

  if (reviewResultTypeName === ReferralReviewResultTypeName.DuplicateDevice) {
    return (
      <ReviewResultCellWithTooltip title="Дубликат устройства">
        <DuplicateDeviceTooltipContent playerId={reviewResult.player.id} />
      </ReviewResultCellWithTooltip>
    );
  }

  if (
    reviewResultTypeName === ReferralReviewResultTypeName.MinimumNumberOfBets
  ) {
    return 'Ставки';
  }

  if (reviewResultTypeName === ReferralReviewResultTypeName.Note) {
    return reviewResult.note.text;
  }

  if (reviewResultTypeName === ReferralReviewResultTypeName.Geolocation) {
    const { countryCodeActual } = reviewResult;

    return (
      <ReviewResultCellWithTooltip title="Геолокация">
        {MayBeNullCell(
          countryCodeActual && getCountryISOtoDisplayName([countryCodeActual]),
        )}
      </ReviewResultCellWithTooltip>
    );
  }

  return null;
};
