import { Dispatch, SetStateAction } from 'react';

interface Params<T> {
  setFilter: (filterId: T, value: Array<string>) => void;
  filterId: T;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}

export const getResetFilters =
  <T>({ setFilter, filterId, setIsOpened }: Params<T>) =>
  () => {
    setFilter(filterId, []);
    setIsOpened(false);
  };
