import { CardIconColor } from 'ui/Card';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeftFromLine,
  faArrowRotateLeft,
  faFlag,
  faTrophy,
  faTennisBall,
  faCircleCheck,
  faBan,
} from '@fortawesome/pro-solid-svg-icons';
import { CardIconConfig } from 'ui/Card/CardIcon';
import { GameActionType } from 'generatedGraphql';

export const getStatusIcon = (status: GameActionType): CardIconConfig => {
  let icon: IconProp;
  let iconColor;

  switch (status) {
    case GameActionType.Bet:
      icon = faFlag;
      iconColor = CardIconColor.BLUE;
      break;
    case GameActionType.CancelBet:
      icon = faBan;
      iconColor = CardIconColor.RED;
      break;
    case GameActionType.Refund:
      icon = faArrowRotateLeft;
      iconColor = CardIconColor.ORANGE;
      break;
    case GameActionType.Win:
      icon = faTrophy;
      iconColor = CardIconColor.GREEN;
      break;
    case GameActionType.CancelWin:
      icon = faArrowLeftFromLine;
      iconColor = CardIconColor.RED;
      break;
    case GameActionType.Tombstone:
      icon = faTennisBall;
      iconColor = CardIconColor.GREY;
      break;
    default:
      icon = faCircleCheck;
      iconColor = CardIconColor.GREEN;
      break;
  }

  return { icon, iconColor };
};
