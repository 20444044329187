import { GetProviderGameForFilter } from '../queries/generated/GetProviderGameForFilter';
import { AdditionalData } from '../components/AdditionalData';

interface Props {
  providerGames?: GetProviderGameForFilter['providerGames'];
}

export const getProviderGamesFilterOptions = ({ providerGames }: Props) =>
  providerGames?.edges.map(({ node }) => ({
    id: node.id,
    label: node.name,
    additionalData: <AdditionalData node={node} />,
  })) || [];
