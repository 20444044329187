import { FC } from 'react';
import { SidebarLoader } from 'ui/SidebarLoader';
import Error from 'ui/Error';
import { ApolloError } from '@apollo/client';
import { ReferralLinkMediaItemDataCard } from '../ReferralLinkMediaItemDataCard';
import { GetCompanyReferralLinkMediaItem } from '../CompanyReferralLinkMediaItem/queries/generated/GetCompanyReferralLinkMediaItem';

interface Props {
  data?: GetCompanyReferralLinkMediaItem;
  loading: boolean;
  error?: ApolloError;
}

export const AboutReferralLinkMediaItem: FC<Props> = ({
  data,
  loading,
  error,
}) => (
  <SidebarLoader loading={loading}>
    {error && <Error error={error} />}
    <ReferralLinkMediaItemDataCard data={data} />
  </SidebarLoader>
);
