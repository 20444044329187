import moment from 'moment';
import { DateFormat } from 'utils/formatDate';

export const formatDateTime = (initialDate: Date, time: string | null) => {
  const date = moment(initialDate);

  if (time) {
    const [hours, minutes] = time.split(':');

    date.set({
      hour: parseInt(hours, 10),
      minute: parseInt(minutes, 10),
    });

    return date.format(DateFormat.DefaultDateWithTime);
  }

  return date.format(DateFormat.DefaultDate);
};
