import { FilterState } from '../types';

interface Props {
  values?: FilterState;
  selectedFilterValues?: Array<string>;
}

export const isSubmitDisabled = ({
  values = {},
  selectedFilterValues = [],
}: Props) => {
  const filteredValues = Object.keys(values).filter((key) => values[key]);

  return (
    selectedFilterValues.length === filteredValues.length &&
    selectedFilterValues.every((value) => filteredValues.includes(value))
  );
};
