import NowrapCell from 'ui/Table/components/NowrapCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { BoldSumCellByCompare } from 'ui/Table/components/BoldSumCellByCompare';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import mayBeNullCell from 'ui/Table/components/MayBeNullCell/MayBeNullCell';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { rewardStatusMap, twoEmDash } from 'src/const';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { TableCellAlign } from 'ui/Table/const';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { ColumnId, mapColumnIdToName, columnsIds } from '../const';
import { RewardData } from '../../../queries/generated/RewardData';
import { getRewardStatusIcon, getAffiliateInfo } from '../../../helpers';
import { SuspiciousCellWrapper } from '../../SuspiciousCellWrapper';

const columnsHelper = createColumnHelper<RewardData>();

const columnsSource: ColumnsSource<RewardData> = [
  columnsHelper.accessor(ColumnId.SuspectState, {
    id: ColumnId.SuspectState,
    header: mapColumnIdToName[ColumnId.SuspectState],
    cell: ({ row }) => {
      const { id, suspectState } = row.original;

      return <SuspiciousCellWrapper id={id} suspectState={suspectState} />;
    },
  }),
  columnsHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
  }),
  columnsHelper.accessor(ColumnId.Affiliate, {
    id: ColumnId.Affiliate,
    header: mapColumnIdToName[ColumnId.Affiliate],
    cell: ({ row }) => {
      const { email, route, name } = getAffiliateInfo(row.original.affiliate);

      return mayBeNullCell(
        email && (
          <MultilineCellWithLink
            isOpenInNewWindow
            title={name}
            route={route}
            subTitle={email}
          />
        ),
        twoEmDash,
      );
    },
  }),
  columnsHelper.accessor(ColumnId.Balance, {
    id: ColumnId.Balance,
    header: mapColumnIdToName[ColumnId.Balance],
    cell: () => mayBeNullCell(null, twoEmDash),
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnsHelper.accessor(ColumnId.Status, {
    id: ColumnId.Status,
    header: mapColumnIdToName[ColumnId.Status],
    cell: ({ row }) => {
      const iconConfig = getRewardStatusIcon(row.original.status);
      const title = rewardStatusMap[row.original.status];

      return MultilineCellWithIcon({ title, iconConfig });
    },
  }),
  columnsHelper.accessor(ColumnId.Sum, {
    id: ColumnId.Sum,
    header: mapColumnIdToName[ColumnId.Sum],
    cell: ({ row }) => {
      const { amount, currency } = row.original.sum;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnsHelper.accessor(ColumnId.PaidSum, {
    id: ColumnId.PaidSum,
    header: mapColumnIdToName[ColumnId.PaidSum],
    cell: ({ row }) => {
      const { paidSum, sum } = row.original;

      return <BoldSumCellByCompare renderedSum={paidSum} comparedSum={sum} />;
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnsHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnsHelper.accessor(ColumnId.UpdatedAt, {
    id: ColumnId.UpdatedAt,
    header: mapColumnIdToName[ColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetRewardsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
