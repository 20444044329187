import { FC } from 'react';
import { Falsy, MediaItemRewardTariffTypeName } from 'commonTypes';
import { KeyValueRowProps } from 'ui/KeyValueRow';
import { CopyModalRow } from 'ui/CopyModalRow';
import { DataCard } from 'commonComponents/DataCard';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { getCountryISOtoDisplayName } from 'src/utils/getCountryISOtoDisplayName';
import Privilege from 'commonComponents/Privilege';
import { UserPrivilege } from 'generatedUserPrivilege';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { useMediaItemReferralLinkModal } from './hooks/useAffiliateReferralLinkItemModal';
import { EditMediaItemReferralLinkModal } from './components/EditMediaItemReferralLinkModal/EditMediaItemReferralLinkModal';
import { AffiliateMediaItemsSteps } from '../AffiliatePromoCodeItem/const';

interface Props {
  companyId: string;
  affiliateId: string;
  referralLinkId: string;
}

export const AffiliateReferralLinkItemDetails: FC<Props> = ({
  companyId,
  affiliateId,
  referralLinkId,
}) => {
  const { updateModalStep } = useUpdateModalStep<AffiliateMediaItemsSteps>();

  const { referralLinkMediaItem, step } = useMediaItemReferralLinkModal({
    referralLinkId,
    affiliateId,
    companyId,
  });

  if (!referralLinkMediaItem) {
    return null;
  }

  const {
    name,
    createdAt,
    mediaItemRewardTariff,
    mediaCampaign,
    referralLink,
    updatedAt,
  } = referralLinkMediaItem;

  if (step === AffiliateMediaItemsSteps.EditReferralLink) {
    return (
      <EditMediaItemReferralLinkModal
        referralLinkId={referralLinkId}
        referralLinkMediaItem={referralLinkMediaItem}
      />
    );
  }

  const isCpaTariff =
    mediaItemRewardTariff.__typename === MediaItemRewardTariffTypeName.Cpa;

  const rewardSum = isCpaTariff && mediaItemRewardTariff.rewardTariff.rewardSum;
  const baselineSum =
    isCpaTariff && mediaItemRewardTariff.rewardTariff.baselineSum;

  const rows: Array<KeyValueRowProps | Falsy> = [
    {
      title: 'Название',
      value: name,
    },
    {
      title: 'Название медиакампании',
      value: mediaCampaign?.name,
    },
    {
      title: 'URL медиакампании',
      value: mediaCampaign?.url,
    },
    {
      title: 'Партнёрский тариф',
      value: mediaItemRewardTariff.rewardTariff.title,
    },
    isCpaTariff && {
      title: 'Резервный тариф',
      value: mediaItemRewardTariff.fallbackRewardTariff?.title,
    },
    isCpaTariff && {
      title: 'Геолокация',
      value: getCountryISOtoDisplayName(
        mediaItemRewardTariff.rewardTariff.countries,
      ),
    },
    baselineSum && {
      title: 'Baseline',
      value: formatSumWithCurrency(baselineSum.amount, baselineSum.currency),
    },
    rewardSum && {
      title: 'Вознаграждение',
      value: formatSumWithCurrency(rewardSum.amount, rewardSum.currency),
    },
    {
      title: 'Рефссылка',
      value: (
        <CopyModalRow value={referralLink} toastText="Рефссылка скопирована" />
      ),
    },
    {
      title: 'Создана',
      value: formatDate(createdAt, DateFormat.DefaultDateOld),
    },
    {
      title: 'Обновлена',
      value: formatDate(updatedAt, DateFormat.DefaultDateOld),
    },
  ];

  return (
    <DataCard
      rows={rows}
      customFooter={
        <Privilege privileges={[UserPrivilege.UpdateReferralLinkMediaItem]}>
          <Button
            size={ButtonSize.Large}
            onClick={() =>
              updateModalStep(AffiliateMediaItemsSteps.EditReferralLink)
            }
            theme={ButtonTheme.Process}
          >
            Редактировать
          </Button>
        </Privilege>
      }
    />
  );
};
