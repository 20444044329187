import { useEffect } from 'react';
import { SingleSelectFilter } from 'ui/Filters';
import { FilterComponentProps } from 'ui/Filters/types';
import { useGetPlayersQuery } from './hooks/useGetPlayersQuery';
import { getPlayersFilterOptions } from './helpers';

export const PlayerFilter = <T extends string>({
  setFilter,
  filterId,
  setIsOpened,
}: FilterComponentProps<T>) => {
  const {
    data,
    loading,
    error,
    fetchMore,
    hasNextPage,
    loadPlayersWithThrottle,
  } = useGetPlayersQuery();

  useEffect(() => {
    loadPlayersWithThrottle();
  }, [loadPlayersWithThrottle]);

  const options = getPlayersFilterOptions({ players: data?.players });

  return (
    <SingleSelectFilter
      options={options}
      setFilter={setFilter}
      filterId={filterId}
      setIsOpened={setIsOpened}
      fetchMoreConfig={{
        fetchMore: () => {
          if (hasNextPage) {
            fetchMore();
          }
        },
        hasNextPage,
      }}
      loading={loading}
      error={error}
      onSearchInList={loadPlayersWithThrottle}
    />
  );
};
