import { useContext } from 'react';
import { BlockContext } from 'ui/Block/store';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import BlockTable from 'ui/Block/BlockTable';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { ColumnDef } from '@tanstack/react-table';
import { nbsp } from 'src/const';
import { useGetCompanyLoginHistoryByTableSize } from './hooks/useGetCompanyLoginHistoryByTableSize';

interface Props<T extends object> {
  companyId: string;
  columns: Array<ColumnDef<T>>;
  isFullScreenTable?: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
}

export const CompanyLoginHistoryTable = <T extends { __typename: string }>({
  companyId,
  columns,
  isFullScreenTable,
  shouldReturnToPrevPage,
  routeToCustomPage,
}: Props<T>) => {
  const { state } = useContext(BlockContext);

  const {
    loadCompanyLoginHistoryList,
    data,
    loading,
    error,
    hasNextPage,
    refetch,
    fetchMoreCallback,
    companyLoginHistoryList,
  } = useGetCompanyLoginHistoryByTableSize<T>({ companyId, isFullScreenTable });

  useBlockComponentState({
    loadData: loadCompanyLoginHistoryList,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable,
  });

  return (
    <Block
      emptyText={`Нет историй входа в${nbsp}систему`}
      title="Логины"
      id="companyLoginHistoryList"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          hidden={!state.isOpened}
          disabled={state.isLoading}
          onClick={() => refetch()}
        />
      }
      isEmpty={isListEmpty(companyLoginHistoryList)}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {hasList(companyLoginHistoryList) && (
        <BlockTable
          columns={columns}
          data={companyLoginHistoryList}
          isFullScreenTable={isFullScreenTable}
          routeToCustomPage={routeToCustomPage}
        />
      )}
    </Block>
  );
};
