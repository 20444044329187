import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdateReferralLinkMediaItemVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateReferralLinkMediaItemInput;
}>;


export type UpdateReferralLinkMediaItem = { __typename: 'Mutation', updateReferralLinkMediaItem: { __typename: 'UpdateReferralLinkMediaItemPayload', referralLinkMediaItem: { __typename: 'ReferralLinkMediaItem', id: string, name: string, mediaCampaign: { __typename: 'MediaCampaign', id: string, name: string, url: string }, mediaItemRewardTariff: { __typename: 'MediaItemCpaRewardTariff', rewardTariff: { __typename: 'CpaRewardTariff', id: string, title: string } } | { __typename: 'MediaItemRevShareRewardTariff', rewardTariff: { __typename: 'RevShareRewardTariff', id: string, title: string } } } } };


export const UpdateReferralLinkMediaItemDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateReferralLinkMediaItem"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateReferralLinkMediaItemInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateReferralLinkMediaItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referralLinkMediaItem"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"mediaItemRewardTariff"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rewardTariff"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ReferralLinkMediaItem"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mediaCampaign"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type UpdateReferralLinkMediaItemMutationFn = Apollo.MutationFunction<UpdateReferralLinkMediaItem, UpdateReferralLinkMediaItemVariables>;

/**
 * __useUpdateReferralLinkMediaItem__
 *
 * To run a mutation, you first call `useUpdateReferralLinkMediaItem` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferralLinkMediaItem` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferralLinkMediaItem, { data, loading, error }] = useUpdateReferralLinkMediaItem({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReferralLinkMediaItem(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReferralLinkMediaItem, UpdateReferralLinkMediaItemVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateReferralLinkMediaItem, UpdateReferralLinkMediaItemVariables>(UpdateReferralLinkMediaItemDocument, options);
      }
export type UpdateReferralLinkMediaItemHookResult = ReturnType<typeof useUpdateReferralLinkMediaItem>;
export type UpdateReferralLinkMediaItemMutationResult = Apollo.MutationResult<UpdateReferralLinkMediaItem>;
export type UpdateReferralLinkMediaItemMutationOptions = Apollo.BaseMutationOptions<UpdateReferralLinkMediaItem, UpdateReferralLinkMediaItemVariables>;