import { useCallback, useMemo } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useGetPartnerLoginHistoryBlockLazyQuery } from '../../../queries/generated/GetPartnerLoginHistoryBlock';
import { useGetPartnerLoginHistoryLazyQuery } from '../../../queries/generated/GetPartnerLoginHistory';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

interface Params {
  partnerId: string;
  isFullScreenTable?: boolean;
}

export const useGetPartnerLoginHistoryByTableSize = <T>({
  partnerId,
  isFullScreenTable,
}: Params) => {
  const countPerPage = isFullScreenTable
    ? getCountByWindowHeight(cellHeight)
    : COUNT_PER_PAGE;

  const currentUseGetPartnerLoginHistory = isFullScreenTable
    ? useGetPartnerLoginHistoryLazyQuery
    : useGetPartnerLoginHistoryBlockLazyQuery;

  const [
    loadPartnerLoginHistoryList,
    { data, error, loading, fetchMore, refetch },
  ] = currentUseGetPartnerLoginHistory({
    variables: {
      id: partnerId,
      first: countPerPage,
    },
  });

  const partnerLoginHistory = data?.partner.loginHistory;
  const endCursor = partnerLoginHistory?.pageInfo.endCursor;
  const hasNextPage = partnerLoginHistory?.pageInfo.hasNextPage;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  const partnerLoginHistoryList = useMemo(
    () =>
      partnerLoginHistory?.edges.map(({ node }) => node) as unknown as Array<T>,
    [partnerLoginHistory?.edges],
  );

  return {
    loadPartnerLoginHistoryList,
    data,
    loading,
    error,
    hasNextPage,
    refetch,
    fetchMoreCallback,
    partnerLoginHistoryList,
  };
};
