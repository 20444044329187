import { DateFormat, formatDate } from 'src/utils/formatDate';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { CompanyMediaCampaignBasicData } from '../../../queries/generated/CompanyMediaCampaignBasicData';
import { columnsIds, ColumnId, mapColumnIdToName } from '../const';

const columnHelper = createColumnHelper<CompanyMediaCampaignBasicData>();

const columnsSource: ColumnsSource<CompanyMediaCampaignBasicData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
  }),
  columnHelper.accessor(ColumnId.Title, {
    id: ColumnId.Title,
    header: mapColumnIdToName[ColumnId.Title],
  }),
  columnHelper.accessor(ColumnId.Url, {
    id: ColumnId.Url,
    header: mapColumnIdToName[ColumnId.Url],
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetCompanyMediaCampaignsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
