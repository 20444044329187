import NowrapCell from 'ui/Table/components/NowrapCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import { generatePath } from 'react-router';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { Route } from 'src/router/routes.const';
import { sportsBetStatusMap, sportsBetTypeMap } from 'src/const';
import { formatSumAmount } from 'utils/accounting';
import { ColumnsSource } from 'ui/Table/types';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { TableCellAlign } from 'ui/Table/const';
import { getNumberSign } from 'utils/getNumberSign';
import { ColumnId, mapColumnIdToName, columnsIds } from '../const';
import { SportsBetData } from '../../../queries/generated/SportsBetData';
import { getStatusIcon } from '../../../helpers';
import { getCoefficients } from '../helpers';

const columnHelper = createColumnHelper<SportsBetData>();

const columnsSource: ColumnsSource<SportsBetData> = [
  columnHelper.accessor(ColumnId.ExternalId, {
    id: ColumnId.ExternalId,
    header: mapColumnIdToName[ColumnId.ExternalId],
  }),
  columnHelper.accessor(ColumnId.Player, {
    id: ColumnId.Player,
    header: mapColumnIdToName[ColumnId.Player],
    cell: ({ row }) => {
      const { id, name, email, phone } = row.original.player;
      const playerUrl = generatePath(Route.PLAYER, { id });

      return (
        <MultilineCellWithLink
          isOpenInNewWindow
          title={name}
          subTitle={email || phone}
          route={playerUrl}
        />
      );
    },
  }),
  columnHelper.accessor(ColumnId.Type, {
    id: ColumnId.Type,
    header: mapColumnIdToName[ColumnId.Type],
    cell: ({ row }) => sportsBetTypeMap[row.original.type],
  }),
  columnHelper.accessor(ColumnId.Coefficient, {
    id: ColumnId.Coefficient,
    header: mapColumnIdToName[ColumnId.Coefficient],
    cell: ({ row }) => getCoefficients(row.original.coefficients),
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.BetSum, {
    id: ColumnId.BetSum,
    header: mapColumnIdToName[ColumnId.BetSum],
    cell: ({ row }) => formatSumAmount(row.original.betSum.amount),
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.ResultSum, {
    id: ColumnId.ResultSum,
    header: mapColumnIdToName[ColumnId.ResultSum],
    cell: ({ row }) => {
      const { amount } = row.original.resultSum;

      return `${getNumberSign(amount)}${formatSumAmount(amount)}`;
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.Currency, {
    id: ColumnId.Currency,
    header: mapColumnIdToName[ColumnId.Currency],
  }),
  columnHelper.accessor(ColumnId.Status, {
    id: ColumnId.Status,
    header: mapColumnIdToName[ColumnId.Status],
    cell: ({ row }) => {
      const { status } = row.original;
      const title = sportsBetStatusMap[status];
      const iconConfig = getStatusIcon(status);

      return MultilineCellWithIcon({ title, iconConfig });
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDateWithSeconds)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(ColumnId.UpdatedAt, {
    id: ColumnId.UpdatedAt,
    header: mapColumnIdToName[ColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.FullDateWithSeconds)}
      </NowrapCell>
    ),
  }),
];

export const useGetSportsBetsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
