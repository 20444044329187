import { CardIcon } from 'ui/Card/CardIcon';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { ActiveFilter } from './components/ActiveFilter';
import styles from './Filters.module.scss';
import { PreviewFilter } from './components/PreviewFilter';
import { FilterConfig } from './types';
import { ResetButton } from './components/ResetButton';
import { useGetSelectedFilters } from './hooks/useGetSelectedFilters';

interface Props<T> {
  filterConfigs: Array<FilterConfig<T>>;
  isDisabled: boolean;
  containerClassName?: string;
}

export const Filters = <T extends string>({
  filterConfigs,
  isDisabled,
  containerClassName,
}: Props<T>) => {
  const {
    selectedFilters,
    resetAllFilters,
    hasSelectedFilter,
    removeFilter,
    setFilter,
    getFilter,
  } = useGetSelectedFilters({ filterConfigs });

  return (
    <div className={classnames(styles.container, containerClassName)}>
      <div className={styles.availableFilters}>
        <CardIcon className={styles.icon} config={{ icon: faFilter }} />

        {hasSelectedFilter && (
          <ResetButton isDisabled={isDisabled} onClick={resetAllFilters} />
        )}

        {filterConfigs.map((filterConfig) => (
          <PreviewFilter
            key={filterConfig.filterId}
            filterConfig={filterConfig}
            setFilter={setFilter}
            getFilter={getFilter}
            isDisabled={isDisabled}
          />
        ))}
      </div>
      {hasSelectedFilter && (
        <div className={styles.selectedFilters}>
          {selectedFilters.map((selectedFilter) => {
            if (!selectedFilter.values.length) {
              return null;
            }

            return (
              <ActiveFilter
                isDisabled={isDisabled}
                key={selectedFilter.filterId}
                selectedFilter={selectedFilter}
                removeFilter={removeFilter}
                setFilter={setFilter}
                getFilter={getFilter}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
