import { FilterConfig } from 'ui/Filters/types';
import { CompanyApplicationStatus } from 'generatedGraphql';
import { companyApplicationStatusMap } from 'src/const';
import { CompanyApplicationFilterMember } from '../const';
import { CompanyApplicationsStatusFilter } from '../components/CompanyApplicationsStatusFilter';

export const getFiltersConfig = (): Array<
  FilterConfig<CompanyApplicationFilterMember>
> => [
  {
    title: 'Статус',
    filterId: CompanyApplicationFilterMember.Status,
    getSelectedFilterValues: (values) =>
      values.map(
        (value) =>
          companyApplicationStatusMap[value as CompanyApplicationStatus],
      ),
    FilterComponent: CompanyApplicationsStatusFilter,
  },
];
