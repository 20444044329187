import { FC, useContext } from 'react';
import Block from 'ui/Block';
import SearchInput from 'ui/SearchInput';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { PageTypeStorage } from 'utils/storage';
import { nbsp } from 'src/const';
import { LoginHistoryTable } from './components/LoginHistoryTable';
import { useGetLoginHistory } from './hooks/useGetLoginHistory';

const Component: FC = withBlockProvider(() => {
  const { state } = useContext(BlockContext);

  const {
    loginHistory,
    loading,
    loadLoginHistory,
    error,
    data,
    fetchMoreCallback,
    refetch,
    hasNextPage,
    handleSearch,
  } = useGetLoginHistory();

  useBlockComponentState({
    loadData: loadLoginHistory,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return (
    <>
      <SearchInput
        name="loginHistorySearch"
        placeholder={`Найти игрока, партнёра, компанию по${nbsp}IP`}
        onSubmit={handleSearch}
      />
      <Block
        title="Логины"
        id="login-history"
        headerContent={
          <ActionButton
            actionType={ButtonType.Update}
            disabled={state.isLoading}
            onClick={() => refetch()}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText="Логинов нет"
        isEmpty={isListEmpty(loginHistory)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(loginHistory) && (
          <LoginHistoryTable loginHistory={loginHistory} />
        )}
      </Block>
    </>
  );
});

export const LoginHistory = () => (
  <SearchContextProvider pageType={PageTypeStorage.LoginHistory}>
    <Component />
  </SearchContextProvider>
);
