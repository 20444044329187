import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { useGetGameActionsColumns } from './hooks/useGetGameActionsColumns';
import { GameActionData } from '../../queries/generated/GameActionData';

interface Props {
  gameActions?: Array<GameActionData>;
}

export const GameActionsTable: FC<Props> = ({ gameActions }) => {
  const { columns } = useGetGameActionsColumns();

  return <BlockTable columns={columns} data={gameActions} isFullScreenTable />;
};
