import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useSearchContext } from 'src/contexts/SearchContext';
import { useGetMediaItemsPromoCodeLazyQuery } from 'queries/generated/GetMediaItemsPromoCode';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetMediaItemsPromoCode = () => {
  const { searchValue } = useSearchContext();

  const [variables, setVariables] = useState({
    first: getCountByWindowHeight(cellHeight, 0),
  });

  useEffect(() => {
    setVariables((oldVariables) => ({
      ...oldVariables,
      search: searchValue,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loadPromoCodes, { data, loading, error, fetchMore, refetch }] =
    useGetMediaItemsPromoCodeLazyQuery();

  const loadPromoCodesCallback = useCallback(
    () => loadPromoCodes({ variables }),
    [loadPromoCodes, variables],
  );

  useBlockComponentState({
    loadData: loadPromoCodesCallback,
    loading,
    error,
  });

  const mediaItemsPromoCode = useMemo(
    () => data?.promoCodeMediaItems?.edges.map(({ node }) => node),
    [data?.promoCodeMediaItems?.edges],
  );

  const endCursor = data?.promoCodeMediaItems?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.promoCodeMediaItems?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  return {
    refetchMediaItemsPromoCode: refetch,
    mediaItemsPromoCode,
    loadPromoCodes: () =>
      loadPromoCodes({
        variables: { ...variables, search: searchValue },
      }),
  };
};
