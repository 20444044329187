import { useEffect, useState } from 'react';
import { InputDate } from 'ui/Input/InpurDate/InputDate';
import { InputTime } from 'ui/Input/InputTime/InputTime';
import { DateTimeValues } from 'ui/DatePicker/types';
import { isValidDate } from 'ui/Filters/components/DateFilter/components/DateTimeInputs/helpers/isValidDate';
import { DateFormat, formatDate } from 'utils/formatDate';
import { parseDateFromString } from 'utils/parseDateFromString';
import { getNextDay } from 'utils/getNextDay';
import styles from './DateTimeInputs.module.scss';

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  startTime: string | null;
  endTime: string | null;
  onChange: (date: DateTimeValues) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
}
export const DateTimeInputs = ({
  startDate,
  endDate,
  onChange,
  startTime,
  endTime,
  minDate,
  maxDate,
}: Props) => {
  const [timeFrom, setTimeFrom] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<string>('');
  const [timeTo, setTimeTo] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');

  useEffect(() => {
    const nextDateFrom = startDate
      ? formatDate(startDate, DateFormat.DefaultDate, false)
      : '';

    setDateFrom(nextDateFrom);

    const nextDateTo = endDate
      ? formatDate(endDate, DateFormat.DefaultDate, false)
      : '';

    setDateTo(nextDateTo);

    setTimeFrom(startTime || '');

    setTimeTo(endTime || '');
  }, [startDate, endDate, startTime, endTime]);

  return (
    <div className={styles.dateTimeWrapper}>
      <InputDate
        value={dateFrom}
        onChange={(date) => {
          setDateFrom(date);

          if (!date || isValidDate(date)) {
            onChange({
              dates: [date ? parseDateFromString(date) : null, endDate],
              times: [timeFrom, timeTo],
            });
          }
        }}
        minDate={minDate}
        maxDate={maxDate}
        placeholder="От"
        autoFocus
      />
      <InputTime
        value={timeFrom}
        onChange={(time) => {
          setTimeFrom(time);
          const nextStartDate = parseDateFromString(dateFrom);
          const nextEndDate = parseDateFromString(dateTo);

          onChange({
            dates: [nextStartDate, nextEndDate],
            times: [time, timeTo],
          });
        }}
        placeholder="Время (от)"
        disabled={!isValidDate(dateFrom)}
      />
      <InputDate
        value={dateTo}
        minDate={startDate ? getNextDay(startDate) : minDate}
        maxDate={maxDate}
        onChange={(date) => {
          setDateTo(date);

          if (!date || isValidDate(date)) {
            onChange({
              dates: [startDate, parseDateFromString(date)],
              times: [timeFrom, timeTo],
            });
          }
        }}
        placeholder="До"
      />
      <InputTime
        value={timeTo}
        onChange={(time) => {
          setTimeTo(time);
          const nextStartDate = parseDateFromString(dateFrom);
          const nextEndDate = parseDateFromString(dateTo);

          onChange({
            dates: [nextStartDate, nextEndDate],
            times: [timeFrom, time],
          });
        }}
        placeholder="Время (до)"
        disabled={!isValidDate(dateTo)}
      />
    </div>
  );
};
