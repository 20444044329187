import { LoginHistoryTypeName } from 'commonTypes';
import { LoginHistoryData } from '../../../queries/generated/LoginHistoryData';

export const getEmailByTypeName = (actor: LoginHistoryData['actor']) => {
  switch (actor.__typename) {
    case LoginHistoryTypeName.Company:
      return actor.companyEmail;
    case LoginHistoryTypeName.Partner:
      return actor.partnerEmail;
    case LoginHistoryTypeName.Player:
      return actor.playerEmail;
    default:
      return 'Unknown';
  }
};
