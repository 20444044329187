import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { SumData } from '../../../../queries/generated/SumData';
import { SportsBetData } from './SportsBetData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetSportsBetInfoVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetSportsBetInfo = { __typename: 'Query', sportsBet: { __typename: 'SportsBet', combination: number, coefficients: Array<number>, createdAt: string, externalId: string, id: string, status: SchemaTypes.SportsBetStatus, type: SchemaTypes.SportsBetType, updatedAt: string, possibleWinSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, betSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, player: { __typename: 'Player', email?: string | null, id: string, name: string, phone?: string | null }, resultSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } };


export const GetSportsBetInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSportsBetInfo"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sportsBet"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"combination"}},{"kind":"Field","name":{"kind":"Name","value":"possibleWinSum"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SumData"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SportsBetData"}}]}}]}},...SumData.definitions,...SportsBetData.definitions]} as unknown as DocumentNode;

/**
 * __useGetSportsBetInfo__
 *
 * To run a query within a React component, call `useGetSportsBetInfo` and pass it any options that fit your needs.
 * When your component renders, `useGetSportsBetInfo` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSportsBetInfo({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSportsBetInfo(baseOptions: ApolloReactHooks.QueryHookOptions<GetSportsBetInfo, GetSportsBetInfoVariables> & ({ variables: GetSportsBetInfoVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSportsBetInfo, GetSportsBetInfoVariables>(GetSportsBetInfoDocument, options);
      }
export function useGetSportsBetInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSportsBetInfo, GetSportsBetInfoVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSportsBetInfo, GetSportsBetInfoVariables>(GetSportsBetInfoDocument, options);
        }
export function useGetSportsBetInfoSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetSportsBetInfo, GetSportsBetInfoVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetSportsBetInfo, GetSportsBetInfoVariables>(GetSportsBetInfoDocument, options);
        }
export type GetSportsBetInfoHookResult = ReturnType<typeof useGetSportsBetInfo>;
export type GetSportsBetInfoLazyQueryHookResult = ReturnType<typeof useGetSportsBetInfoLazyQuery>;
export type GetSportsBetInfoSuspenseQueryHookResult = ReturnType<typeof useGetSportsBetInfoSuspenseQuery>;
export type GetSportsBetInfoQueryResult = Apollo.QueryResult<GetSportsBetInfo, GetSportsBetInfoVariables>;