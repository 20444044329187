import { useAuth, UserPrivilege } from 'commonComponents/Auth';
import { TabFieldInterface } from 'ui/Tabs';
import { MediaItemTabs } from '../const';

export const useGetMediaItemsTabs = (): Array<
  TabFieldInterface<MediaItemTabs>
> => {
  const auth = useAuth();

  return [
    {
      id: MediaItemTabs.ReferralLink,
      name: <span>Ссылки</span>,
      isVisible: auth.privileges.isSomeGranted({
        privileges: [UserPrivilege.ViewMediaItem],
      }),
    },
    {
      id: MediaItemTabs.PromoCode,
      name: <span>Промокоды</span>,
      isVisible: auth.privileges.isSomeGranted({
        privileges: [UserPrivilege.ViewPromoCodeMediaItem],
      }),
    },
    {
      id: MediaItemTabs.PromoCodeActivation,
      name: <span>Активации промокодов</span>,
      isVisible: auth.privileges.isSomeGranted({
        privileges: [UserPrivilege.ViewPromoCodeMediaItemActivation],
      }),
    },
  ];
};
