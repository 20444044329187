import errorToast from 'ui/Toast/ErrorToast';
import { nbsp } from 'src/const';
import { useApolloClient } from '@apollo/client';
import { getProviderGamesDynamicQuery } from '../helpers/getProviderGamesDynamicQuery';
import { GetProviderGameForFilter } from '../queries/generated/GetProviderGameForFilter';

type ProviderGamesData = Record<
  string,
  Omit<
    GetProviderGameForFilter['providerGames']['edges'][number]['node'],
    'provider' | 'system'
  >
>;

export const useGetSelectedProviderGameFilterValues = () => {
  const client = useApolloClient();

  const getSelectedProviderGameFilterValues = async (
    values: Array<string>,
  ): Promise<Array<string>> => {
    if (values.length === 0) return [];

    const query = getProviderGamesDynamicQuery(values);

    try {
      const { data }: { data: ProviderGamesData } = await client.query({
        query,
      });

      if (!data) return [];

      return Object.values(data)
        .map((node) => node.name || node.id)
        .filter((name): name is string => name !== undefined);
    } catch (error) {
      errorToast({
        header: `При${nbsp}загрузке игр произошла ошибка`,
        text: (error as Error).message,
        toastId: 'get-provider-game-for-filter-error',
      });

      return values;
    }
  };

  return { getSelectedProviderGameFilterValues };
};
