import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyLoginHistoryTable } from '../../components/CompanyLoginHistoryBlock/components/CompanyLoginHistoryTable';
import { CompanyPageContainer } from '../../components/CompanyPageContainer';
import { useGetCompanyLoginHistoryColumns } from '../../components/CompanyLoginHistoryBlock/components/CompanyLoginHistoryTable/hooks/useGetCompanyLoginHistoryColumns';

export const CompanyLoginHistory: FC = () => {
  const { columns } = useGetCompanyLoginHistoryColumns(true);
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <CompanyPageContainer companyId={id}>
      <CompanyLoginHistoryTable
        companyId={id}
        columns={columns}
        shouldReturnToPrevPage
        isFullScreenTable
      />
    </CompanyPageContainer>
  );
};
