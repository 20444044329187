import { nbsp } from 'src/const';

export const getSelectedFilterValues = (values: Array<string>) => {
  const [startDate, endDate] = values;

  if (startDate && !endDate) {
    return [`От${nbsp}${startDate}`];
  }

  if (!startDate && endDate) {
    return [`До${nbsp}${endDate}`];
  }

  return [`${startDate} - ${endDate}`];
};
