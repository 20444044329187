import { useCallback, useEffect, useMemo, useState } from 'react';
import { getCountByWindowHeight, Heights } from 'utils/getCountByWindowHeight';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useSearchContext } from 'src/contexts/SearchContext';
import { CompanyApplicationStatus } from 'commonTypes';
import { useSearchParams } from 'react-router-dom';
import {
  GetCompanyApplicationsVariables,
  useGetCompanyApplicationsLazyQuery,
} from '../queries/generated/GetCompanyApplications';
import { CompanyApplicationData } from '../queries/generated/CompanyApplicationData';
import { CompanyApplicationFilterMember } from '../const';

const cellHeight = Heights.defaultCellHeight;

export const useCompanyApplications = () => {
  const [searchParams] = useSearchParams();

  const statuses = searchParams.getAll(
    CompanyApplicationFilterMember.Status,
  ) as Array<CompanyApplicationStatus>;

  const { searchValue } = useSearchContext();

  const baseVariables: GetCompanyApplicationsVariables = {
    first: getCountByWindowHeight(cellHeight, 0),
  };

  const [variables, setVariables] = useState(baseVariables);

  useEffect(() => {
    setVariables({
      ...baseVariables,
      filter: { status: statuses.length ? statuses : undefined },
      search: searchValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(statuses)]);

  const [
    loadCompanyApplications,
    { data, loading, error, fetchMore, refetch },
  ] = useGetCompanyApplicationsLazyQuery();

  const loadCompanyApplicationsCallback = useCallback(
    () => loadCompanyApplications({ variables }),
    [loadCompanyApplications, variables],
  );

  useBlockComponentState({
    loadData: loadCompanyApplicationsCallback,
    loading,
    error,
  });

  const companyApplications: Array<CompanyApplicationData> | undefined =
    useMemo(
      () => data?.companyApplications?.edges.map(({ node }) => node),
      [data?.companyApplications?.edges],
    );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: data?.companyApplications?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [data?.companyApplications?.pageInfo.endCursor, fetchMore],
  );

  return {
    companyApplications,
    loadCompanyApplications: () =>
      loadCompanyApplications({
        variables: { ...variables, search: searchValue },
      }),
    data,
    loading,
    fetchMoreCallback,
    error,
    refetch,
  };
};
