import { TabFieldInterface } from 'ui/Tabs';
import { RewardTariffType } from 'commonTypes';
import { useAuth, UserPrivilege } from 'commonComponents/Auth';
import { rewardTariffMap } from 'src/const';

export const useGetStatisticTabs = (): Array<
  TabFieldInterface<RewardTariffType>
> => {
  const auth = useAuth();

  return [
    {
      id: RewardTariffType.RevShare,
      name: rewardTariffMap[RewardTariffType.RevShare],
      isVisible: auth.privileges.areEveryGranted({
        privileges: [UserPrivilege.ViewRevShareGeneralReport],
      }),
    },
    {
      id: RewardTariffType.Cpa,
      name: rewardTariffMap[RewardTariffType.Cpa],
      isVisible: auth.privileges.areEveryGranted({
        privileges: [UserPrivilege.ViewCpaGeneralReport],
      }),
    },
  ];
};
