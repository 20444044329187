import { FC, useContext } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { ModalContext } from 'ui/Modal';
import { ContactForCommunicationType } from 'commonTypes';
import { DateFormat, formatDate } from 'utils/formatDate';
import { usePartnerApplicationModal } from './hooks/usePartnerApplicationModal';
import {
  getPartnerApplicationStatusIcon,
  getPartnerApplicationStatus,
} from '../../helpers';
import { DeclinePartnerApplicationModal } from '../DeclinePartnerApplicationModal';
import { PartnerApplicationModalSteps } from '../../const';
import { CreateReferralLink } from '../CreateReferralLink';
import { PartnerApplicationFooter } from './components/PartnerApplicationFooter';
import { contactForCommunicationTypeMap, nbsp } from '../../../../const';

interface Props {
  partnerApplicationId: string;
}

export const PartnerApplicationModal: FC<Props> = ({
  partnerApplicationId,
}) => {
  const { partnerApplication, referralLinkData, setReferralLinkData } =
    usePartnerApplicationModal({
      partnerApplicationId,
    });

  const { state } = useContext(ModalContext);

  if (!partnerApplication) {
    return null;
  }

  const {
    status,
    partnerName,
    partnerEmail,
    declineReason,
    admin,
    createdAt,
    updatedAt,
    contactForCommunication,
    trafficSource,
  } = partnerApplication;

  const [statusText, , subTitle] = getPartnerApplicationStatus(
    status,
    admin,
    declineReason,
  );

  if (state.step === PartnerApplicationModalSteps.DeclineStep) {
    return (
      <DeclinePartnerApplicationModal
        partnerName={partnerName}
        partnerApplicationId={partnerApplicationId}
      />
    );
  }

  if (state.step === PartnerApplicationModalSteps.CreateReferralLink) {
    return (
      <CreateReferralLink
        partnerName={partnerName}
        partnerApplicationId={partnerApplicationId}
        setReferralLinkData={setReferralLinkData}
      />
    );
  }

  if (state.step === PartnerApplicationModalSteps.UpdateReferralLink) {
    return (
      <CreateReferralLink
        partnerName={partnerName}
        partnerApplicationId={partnerApplicationId}
        setReferralLinkData={setReferralLinkData}
        referralLinkData={referralLinkData}
      />
    );
  }

  return (
    <DataCard
      statusConfig={
        status && {
          status: statusText,
          iconConfig: getPartnerApplicationStatusIcon(status),
          text: subTitle,
        }
      }
      rows={[
        {
          title: 'Имя пользователя',
          value: partnerName,
        },
        {
          title: 'Почта партнёра',
          value: partnerEmail,
        },
        {
          title: `Метод${nbsp}для${nbsp}связи`,
          value:
            contactForCommunicationTypeMap[
              contactForCommunication.type as ContactForCommunicationType
            ],
        },
        {
          title: 'Контакт',
          value: contactForCommunication.value,
        },
        {
          title: `Источник${nbsp}трафика`,
          value: trafficSource,
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.FullDate),
        },
        {
          title: 'Обновлён',
          value: formatDate(updatedAt, DateFormat.FullDate),
        },
      ]}
      customFooter={
        <PartnerApplicationFooter
          partnerApplicationStatus={status}
          partnerApplicationId={partnerApplicationId}
          referralLinkData={referralLinkData}
        />
      }
    />
  );
};
