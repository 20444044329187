import { useCallback, useRef } from 'react';
import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { throttle } from 'lodash-es';
import { nbsp } from 'src/const';
import { useGetPlayersForFilterLazyQuery } from '../queries/generated/GetPlayersForFilter';

const COUNT_PER_PAGE = 10;

export const useGetPlayersQuery = () => {
  const handleError = (error: ApolloError) => {
    errorToast({
      header: `При${nbsp}загрузке игроков произошла ошибка`,
      text: error.message,
      toastId: 'get-players-for-filter-error',
    });
  };

  const [loadPlayers, { data, loading, fetchMore: fetchMorePlayers, error }] =
    useGetPlayersForFilterLazyQuery({
      onError: handleError,
    });

  const hasNextPage = data?.players.pageInfo.hasNextPage;
  const endCursor = data?.players?.pageInfo.endCursor;

  const fetchMore = useCallback(
    () =>
      fetchMorePlayers({
        variables: {
          first: COUNT_PER_PAGE,
          after: endCursor,
        },
      }),
    [fetchMorePlayers, endCursor],
  );

  const loadPlayersWithThrottle = useRef(
    throttle(async (searchText = '') => {
      await loadPlayers({
        variables: {
          search: searchText,
          first: COUNT_PER_PAGE,
        },
      });
    }, 1000),
  ).current;

  return {
    loadPlayersWithThrottle,
    data,
    loading,
    hasNextPage,
    fetchMore,
    error,
  };
};
