import { Form, Formik } from 'formik';
import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import Input from 'ui/Input';
import { Tooltip } from 'ui/Tooltip';
import Error from 'ui/Error';
import FormRow from 'ui/FormRow';
import { MediaCampaignDropdown } from 'commonComponents/MediaCampaignDropdown';
import { CpaTariffDropdown } from 'commonComponents/CpaTariffDropdown';
import { RevShareTariffDropdown } from 'commonComponents/RevShareTariffDropdown';
import { MediaItemRewardTariffTypeName, RewardTariffType } from 'commonTypes';
import { useModalManageData } from 'ui/Modal';
import { FieldName, mapFieldNameToTitle } from './const';
import { useEditMediaItemReferralLinkModal } from './hooks/useEditMediaItemReferralLinkModal';
import { validate } from './validation';
import {
  MediaItemRewardTariffType,
  UpdateReferralLinkMediaItemValues,
} from './types';

interface Props {
  mediaItemReferralLinkId: string;
  name: string;
  mediaCampaign: { id: string; name: string };
  mediaItemRewardTariff: MediaItemRewardTariffType;
}

export const EditMediaItemReferralLinkModal: FC<Props> = ({
  mediaItemReferralLinkId,
  name,
  mediaItemRewardTariff,
  mediaCampaign,
}) => {
  const isCpaTariff =
    mediaItemRewardTariff.__typename === MediaItemRewardTariffTypeName.Cpa;

  const rewardTariffId = mediaItemRewardTariff.rewardTariff.id;

  const initialValues: UpdateReferralLinkMediaItemValues = {
    name,
    id: mediaItemReferralLinkId,
    mediaCampaignId: mediaCampaign.id,
    cpaRewardTariffId: isCpaTariff ? rewardTariffId : '',
    fallbackTariffId: isCpaTariff
      ? mediaItemRewardTariff.fallbackRewardTariff?.id
      : '',
    revShareRewardTariffId: !isCpaTariff ? rewardTariffId : '',
    typeRewardTariff: isCpaTariff
      ? RewardTariffType.Cpa
      : RewardTariffType.RevShare,
  };

  const { onEdit, loading, error, data } = useEditMediaItemReferralLinkModal({
    mediaItemReferralLinkId,
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'edit-media-item-error' },
    hasData: !!data,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onEdit}
      validate={validate}
      validateOnChange={false}
    >
      {({ isValid, dirty }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <FormRow isWide>
              <Input
                name={FieldName.Name}
                label={mapFieldNameToTitle[FieldName.Name]}
                placeholder="Название"
              />
            </FormRow>
            <FormRow isWide>
              <MediaCampaignDropdown
                name={FieldName.MediaCampaignId}
                placeholder={mediaCampaign.name}
                label={mapFieldNameToTitle[FieldName.MediaCampaignId]}
              />
            </FormRow>

            {isCpaTariff ? (
              <>
                <FormRow isWide>
                  <CpaTariffDropdown
                    name={FieldName.CPARewardTariffId}
                    label={mapFieldNameToTitle[FieldName.CPARewardTariffId]}
                    placeholder={mediaItemRewardTariff.rewardTariff.title}
                  />
                </FormRow>
                <FormRow isWide>
                  <RevShareTariffDropdown
                    name={FieldName.FallbackTariffId}
                    label={mapFieldNameToTitle[FieldName.FallbackTariffId]}
                    placeholder={
                      mediaItemRewardTariff.fallbackRewardTariff?.title
                    }
                  />
                </FormRow>
              </>
            ) : (
              <FormRow isWide>
                <RevShareTariffDropdown
                  name={FieldName.RevShareRewardTariffId}
                  label={mapFieldNameToTitle[FieldName.RevShareRewardTariffId]}
                  placeholder={mediaItemRewardTariff.rewardTariff.title}
                />
              </FormRow>
            )}
          </FormRowsWrapper>
          <Tooltip
            tooltipContent={
              !isValid || !dirty ? 'Заполните все поля' : undefined
            }
            placement="right"
          >
            <Button
              size={ButtonSize.Large}
              theme={ButtonTheme.Process}
              type="submit"
              isLoading={loading}
              disabled={!isValid || !dirty}
            >
              Сохранить
            </Button>
          </Tooltip>
        </Form>
      )}
    </Formik>
  );
};
