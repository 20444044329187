import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useGetReferralLinkMediaItemByCodeLazyQuery } from 'src/queries/generated/GetReferralLinkMediaItemByCode';
import { FieldName } from '../../../const';
import { CreatePromoCodeValues } from '../../../types';

export const useGetReferralLinkMediaItemByCode = () => {
  const { setFieldValue } = useFormikContext<CreatePromoCodeValues>();

  const [getReferralLinkMediaItemByCodeQuery, { loading }] =
    useGetReferralLinkMediaItemByCodeLazyQuery();

  const getReferralLinkByCode = useCallback(
    (referralCode: string) => {
      if (!referralCode) {
        return;
      }

      getReferralLinkMediaItemByCodeQuery({
        variables: {
          code: referralCode,
        },
        onCompleted: (data) => {
          setFieldValue(
            FieldName.ReferralLinkId,
            data.referralLinkMediaItemByCode.id,
            true,
          );
          setFieldValue(
            FieldName.ReferralLinkUrl,
            data.referralLinkMediaItemByCode.referralLink,
          );
          setFieldValue(
            FieldName.MediaCampaignName,
            data.referralLinkMediaItemByCode.mediaCampaign.name,
          );
          setFieldValue(
            FieldName.MediaCampaignUrl,
            data.referralLinkMediaItemByCode.mediaCampaign.url,
          );
        },
        onError: () => {
          setFieldValue(FieldName.ReferralCodeId, undefined, true);

          toast.error('Такого реферального кода нет');
        },
        fetchPolicy: 'no-cache',
      });
    },
    [getReferralLinkMediaItemByCodeQuery, setFieldValue],
  );

  return {
    getReferralLinkByCode,
    getReferralLinkByCodeLoading: loading,
  };
};
