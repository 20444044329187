import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CardIconColor } from 'ui/Card';
import { SportsBetStatus } from 'generatedGraphql';
import {
  faBan,
  faCircleCheck,
  faCoin,
  faEmptySet,
  faExclamationCircle,
  faHourglassEmpty,
  faSpinner,
} from '@fortawesome/pro-solid-svg-icons';
import { CardIconConfig } from 'ui/Card/CardIcon';

export const getStatusIcon = (value: string): CardIconConfig => {
  let icon: IconProp;
  let iconColor;

  switch (value) {
    case SportsBetStatus.Open:
      icon = faSpinner;
      iconColor = CardIconColor.BLUE;
      break;
    case SportsBetStatus.Pending:
    case SportsBetStatus.WaitingForRegulator:
    case SportsBetStatus.Waiting:
      icon = faHourglassEmpty;
      iconColor = CardIconColor.ORANGE;
      break;
    case SportsBetStatus.Win:
      icon = faCircleCheck;
      iconColor = CardIconColor.GREEN;
      break;
    case SportsBetStatus.Rejected:
      icon = faBan;
      iconColor = CardIconColor.RED;
      break;
    case SportsBetStatus.Lost:
      icon = faExclamationCircle;
      iconColor = CardIconColor.RED;
      break;
    case SportsBetStatus.Void:
      icon = faEmptySet;
      iconColor = CardIconColor.GREY;
      break;
    case SportsBetStatus.Cashout:
    case SportsBetStatus.PartialCashout:
      icon = faCoin;
      iconColor = CardIconColor.BLUE;
      break;

    default:
      icon = faCircleCheck;
      iconColor = CardIconColor.GREEN;
      break;
  }

  return { icon, iconColor };
};
