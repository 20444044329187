import { createColumnHelper } from '@tanstack/react-table';
import NowrapCell from 'ui/Table/components/NowrapCell';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import {
  blockColumnsIds,
  pageColumnsIds,
  mapColumnIdToName,
  ColumnId,
} from '../const';
import { PartnerLoginHistoryData } from '../../../queries/generated/PartnerLoginHistoryData';

const columnHelper = createColumnHelper<PartnerLoginHistoryData>();

const columnsSource: ColumnsSource<PartnerLoginHistoryData> = [
  columnHelper.accessor(ColumnId.IPAddress, {
    id: ColumnId.IPAddress,
    header: mapColumnIdToName[ColumnId.IPAddress],
    cell: ({ row }) => MayBeNullCell(row.original.ipAddress),
  }),
  columnHelper.accessor(ColumnId.Asn, {
    id: ColumnId.Asn,
    header: mapColumnIdToName[ColumnId.Asn],
    cell: ({ row }) => MayBeNullCell(row.original.asn),
  }),
  columnHelper.accessor(ColumnId.Country, {
    id: ColumnId.Country,
    header: mapColumnIdToName[ColumnId.Country],
    cell: ({ row }) => MayBeNullCell(row.original.country),
  }),
  columnHelper.accessor(ColumnId.City, {
    id: ColumnId.City,
    header: mapColumnIdToName[ColumnId.City],
    cell: ({ row }) => MayBeNullCell(row.original.city),
  }),
  columnHelper.accessor(ColumnId.UserAgent, {
    id: ColumnId.UserAgent,
    header: mapColumnIdToName[ColumnId.UserAgent],
    cell: ({ row }) => MayBeNullCell(row.original.userAgent),
  }),
  columnHelper.accessor(ColumnId.LoginAt, {
    id: ColumnId.LoginAt,
    header: mapColumnIdToName[ColumnId.LoginAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.loginAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetPartnerLoginHistoryColumns = (
  isFullScreenTable: boolean,
) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource,
  );

  return {
    columns,
  };
};
