import { FilterConfig } from 'ui/Filters/types';
import {
  PlayerFilter,
  useGetSelectedPlayerFilterValues,
} from 'commonComponents/PlayerFilter';
import { getSelectedFilterValues as getSelectedFilterValuesDatePicker } from 'ui/Filters/components/DateFilter/helpers/getSelectedFilterValues';
import { GameActionsFilter } from '../const';
import { GameActionsCurrencyFilter } from '../components/GameActionsCurrencyFilter';
import {
  ProviderGameFilter,
  useGetSelectedProviderGameFilterValues,
} from '../components/ProviderGameFilter';
import { GameActionsDateFilter } from '../components/GameActionsDateFilter';

export const useGetFiltersConfig = (): Array<
  FilterConfig<GameActionsFilter>
> => {
  const { getSelectedPlayerFilterValues } = useGetSelectedPlayerFilterValues();
  const { getSelectedProviderGameFilterValues } =
    useGetSelectedProviderGameFilterValues();

  return [
    {
      title: 'Игрок',
      filterId: GameActionsFilter.Player,
      getSelectedFilterValues: async (values) =>
        getSelectedPlayerFilterValues(values),
      FilterComponent: PlayerFilter,
    },
    {
      title: 'Валюта',
      filterId: GameActionsFilter.Currency,
      getSelectedFilterValues: (values: Array<string>) => values,
      FilterComponent: GameActionsCurrencyFilter,
    },
    {
      title: 'Игра',
      filterId: GameActionsFilter.ProviderGame,
      getSelectedFilterValues: async (values) =>
        getSelectedProviderGameFilterValues(values),
      FilterComponent: ProviderGameFilter,
    },
    {
      title: 'Дата',
      filterId: GameActionsFilter.Date,
      getSelectedFilterValues: getSelectedFilterValuesDatePicker,
      FilterComponent: GameActionsDateFilter,
    },
  ];
};
