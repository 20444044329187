import { KeyValueRowProps } from 'ui/KeyValueRow';

export const getCoefficients = (
  coefficients: Array<number>,
): Array<KeyValueRowProps> => {
  if (!coefficients.length) {
    return [];
  }

  return coefficients.map((coefficient, index) => {
    const numberOfCoefficient = index + 1;

    return {
      title: `Коэффициент №${numberOfCoefficient}`,
      value: coefficient,
    };
  });
};
