export const isValidDate = (dateStr = ''): boolean => {
  const regex = /^(\d{2})\.(\d{2})\.(\d{4})$/;

  const match = dateStr.match(regex);

  if (!match) return false;

  const day = parseInt(match[1], 10);
  const month = parseInt(match[2], 10);
  const year = parseInt(match[3], 10);

  const date = new Date(year, month - 1, day);

  const validDay = date.getDate() === day;
  const validMonth = date.getMonth() === month - 1;
  const validYear = date.getFullYear() === year;

  return validDay && validMonth && validYear;
};
