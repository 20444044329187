import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { ColumnsSource } from 'ui/Table/types';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCellAlign } from 'ui/Table/const';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { formatSumWithCurrency } from 'src/utils/accounting';
import {
  blockColumnsIds,
  ColumnId,
  freespinStatusMap,
  mapColumnIdToName,
  pageColumnsIds,
} from '../const';
import { getFreespinStatusIconConfig, getFreespinTypeData } from '../utils';
import { FreespinData } from '../components/FreespinsTable/queries/generated/FreespinData';

const columnHelper = createColumnHelper<FreespinData>();

const columnsSource: ColumnsSource<FreespinData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
  }),
  columnHelper.accessor(ColumnId.Type, {
    id: ColumnId.Type,
    header: mapColumnIdToName[ColumnId.Type],
    cell: ({ row }) => {
      const [title, iconConfig, subTitle] = getFreespinTypeData(row.original);

      return MultilineCellWithIcon({ title, iconConfig, subTitle });
    },
  }),
  columnHelper.accessor(ColumnId.Game, {
    id: ColumnId.Game,
    header: mapColumnIdToName[ColumnId.Game],
    cell: ({ row }) => {
      const { name, id } = row.original.game;

      return `${name} #${id}`;
    },
  }),
  columnHelper.accessor(ColumnId.Count, {
    id: ColumnId.Count,
    header: mapColumnIdToName[ColumnId.Count],
    cell: ({ row }) => `${row.original.count} FS`,
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.Currency, {
    id: ColumnId.Currency,
    header: mapColumnIdToName[ColumnId.Currency],
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.BonusWager, {
    id: ColumnId.BonusWager,
    header: mapColumnIdToName[ColumnId.BonusWager],
    cell: ({ row }) => MayBeNullCell(row.original.bonusWager),
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.MinAccountBalance, {
    id: ColumnId.MinAccountBalance,
    header: mapColumnIdToName[ColumnId.MinAccountBalance],
    cell: ({ row }) => {
      const { minAccountBalance } = row.original;

      return MayBeNullCell(
        minAccountBalance &&
          formatSumWithCurrency(
            minAccountBalance.amount,
            minAccountBalance.currency,
          ),
      );
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.Status, {
    id: ColumnId.Status,
    header: mapColumnIdToName[ColumnId.Status],
    cell: ({ row }) => {
      const { status } = row.original;

      return MultilineCellWithIcon({
        title: freespinStatusMap[status],
        iconConfig: getFreespinStatusIconConfig(status),
      });
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),

  columnHelper.accessor(ColumnId.UpdatedAt, {
    id: ColumnId.UpdatedAt,
    header: mapColumnIdToName[ColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetFreespinsColumns = (isFullScreenTable: boolean) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource,
  );

  return {
    columns,
  };
};
