import * as SchemaTypes from '../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type CreatePartnerPromoCodeMediaItemVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreatePartnerPromoCodeMediaItemInput;
}>;


export type CreatePartnerPromoCodeMediaItem = { __typename: 'Mutation', createPartnerPromoCodeMediaItem: { __typename: 'CreatePartnerPromoCodeMediaItemPayload', promoCodeMediaItem: { __typename: 'PromoCodeMediaItem', createdAt: string, id: string, updatedAt: string, usageType: SchemaTypes.PromoCodeUsageType, mediaCampaign: { __typename: 'MediaCampaign', id: string }, promoCode: { __typename: 'PromoCode', id: string }, referralLink?: { __typename: 'ReferralLinkMediaItem', id: string } | null } } };


export const CreatePartnerPromoCodeMediaItemDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatePartnerPromoCodeMediaItem"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreatePartnerPromoCodeMediaItemInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPartnerPromoCodeMediaItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"promoCodeMediaItem"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"mediaCampaign"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"promoCode"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"referralLink"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"usageType"}}]}}]}}]}}]} as unknown as DocumentNode;
export type CreatePartnerPromoCodeMediaItemMutationFn = Apollo.MutationFunction<CreatePartnerPromoCodeMediaItem, CreatePartnerPromoCodeMediaItemVariables>;

/**
 * __useCreatePartnerPromoCodeMediaItem__
 *
 * To run a mutation, you first call `useCreatePartnerPromoCodeMediaItem` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerPromoCodeMediaItem` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerPromoCodeMediaItem, { data, loading, error }] = useCreatePartnerPromoCodeMediaItem({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePartnerPromoCodeMediaItem(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePartnerPromoCodeMediaItem, CreatePartnerPromoCodeMediaItemVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePartnerPromoCodeMediaItem, CreatePartnerPromoCodeMediaItemVariables>(CreatePartnerPromoCodeMediaItemDocument, options);
      }
export type CreatePartnerPromoCodeMediaItemHookResult = ReturnType<typeof useCreatePartnerPromoCodeMediaItem>;
export type CreatePartnerPromoCodeMediaItemMutationResult = Apollo.MutationResult<CreatePartnerPromoCodeMediaItem>;
export type CreatePartnerPromoCodeMediaItemMutationOptions = Apollo.BaseMutationOptions<CreatePartnerPromoCodeMediaItem, CreatePartnerPromoCodeMediaItemVariables>;