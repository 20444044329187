import { LoginHistoryTypeName } from 'commonTypes';

export enum ColumnId {
  IPAddress = 'ipAddress',
  Actor = 'actor',
  ActorType = 'actor.id',
  Asn = 'asn',
  Country = 'country',
  City = 'city',
  UserAgent = 'userAgent',
  Locale = 'locale',
  LoginAt = 'loginAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.IPAddress]: 'IP-адрес',
  [ColumnId.Actor]: 'Пользователь',
  [ColumnId.ActorType]: 'Тип пользователя',
  [ColumnId.Asn]: 'ASN',
  [ColumnId.Country]: 'Страна',
  [ColumnId.City]: 'Город',
  [ColumnId.UserAgent]: 'Браузер, устройство',
  [ColumnId.Locale]: 'Язык',
  [ColumnId.LoginAt]: 'Создан',
};

export const columnsIds = [
  ColumnId.IPAddress,
  ColumnId.Actor,
  ColumnId.ActorType,
  ColumnId.Asn,
  ColumnId.Country,
  ColumnId.City,
  ColumnId.UserAgent,
  ColumnId.Locale,
  ColumnId.LoginAt,
];

export const mapLoginHistoryActorTypeToText: {
  [key in LoginHistoryTypeName]: string;
} = {
  [LoginHistoryTypeName.Company]: 'Компания',
  [LoginHistoryTypeName.Partner]: 'Партнёр',
  [LoginHistoryTypeName.Player]: 'Игрок',
};
